import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import * as Yup from "yup";

import {selectAllKits, selectNewSample} from "../../../../slices/SampleFunctionalSlice";
import {selectCurrentKitId} from "../../../../slices/SampleSlice/selectors";
import {getCurrentUser} from "../../../../slices/UserSlice";
import {TYPE_FORMS} from "../../../SamplesList/SamplesList";
import {KIT_SAMPLE_TAB_INITIAL_VALUES} from "../../constants";

const useCreateKitSamplesTab = () => {
  const typeForm = TYPE_FORMS.CREATE_KIT_SAMPLES;
  const {t} = useTranslation();

  const newSample = useSelector(selectNewSample);
  const sampleImgs = useSelector(state => state.samples.modalData[typeForm]?.sampleImages) ?? [];
  const savedCreateSample = useSelector(state => state.samples.modalData[typeForm]);
  const allKits = useSelector(selectAllKits);
  const currentUser = useSelector(getCurrentUser);
  const currentKitId = useSelector(selectCurrentKitId);

  const [currentSampleId, setCurrentSampleId] = useState(null);

  useEffect(() => {
    setCurrentSampleId(newSample?.sampleId);
  }, [newSample?.sampleId]);

  const currentKit = allKits?.find(kit => kit.kitId === currentKitId);
  const kitImages = currentKit?.images ?? [];

  const validationSchema = Yup.object().shape({
    kitSku: Yup.string(),
    setDescArea: Yup.string().required(t("enter name")),

    factoryId: Yup.number(t("Оберіть фабрику зі списку")).required(t("Оберіть фабрику зі списку")).nullable(),
    groupId: Yup.number().required(t("select gr")).nullable(),
    materialId: Yup.number().required(t("select mat")).nullable(),
    name: Yup.string().required(t("enter name")),
  });

  const initialValues = {
    ...KIT_SAMPLE_TAB_INITIAL_VALUES,
    kitSku: currentKit?.skuNumber ?? "",
    setDescArea: currentKit?.name ?? "",
    ...savedCreateSample,
    ...(newSample || {}),
    brandManager: `${currentUser?.lastName ?? ""} ${currentUser?.firstName ?? ""}`,
  };

  return {
    validationSchema,
    initialValues,
    typeForm,
    newSample,
    sampleImgs,
    currentKit,
    kitImages,
    currentSampleId,
    setCurrentSampleId,
  };
};

export default useCreateKitSamplesTab;

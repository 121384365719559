import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Form, Formik} from "formik";
import * as Yup from "yup";

import useContextMenu, {ContextMenu, ContextMenuOption} from "../../../hook/useContextMenu";
import AddIcon from "../../../images/icons/8201370_add_plus_button_ui_new_icon.png";
import EditIcon from "../../../images/icons/8725775_edit_icon.png";
import DeleteIcon from "../../../images/icons/8726424_trash_alt_icon.png";
import DublicateIcon from "../../../images/icons/11030095_copy_icon.png";
import LinkIcon from "../../../images/icons/link.png";
import ViewIcon from "../../../images/icons/openEye.png";
import {selectSamplesLoading} from "../../../slices/SampleFunctionalSlice";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../utilities/Buttons/ImageBtn";
import Divider from "../../../utilities/Divider/Divider";
import ReactTable from "../../conteiners/ReactTable";
import {TextInput} from "../../Inputs";
import ActionWithChosen from "../../KitsList/ActionWithChosen/ActionWithChosen";
import ModalForm from "../../Modal/FormModal";
import SamplePreview from "../../SamplesList/previewModal/SamplePreview";

import useNoKitSampleBlock from "./useNoKitSampleBlock";

const NoKitSampleBlock = ({
  setFormType,
  setShowModal,
  setTabValue,
  setTabs,
  setTabProps,
  setTabLabel,
  currentSample,
  setCurrentSample,
}) => {
  const {t} = useTranslation();

  const {
    instanceTableData,
    lowerTableColumns,
    onSampleSelect,
    onRowDoubleClick,
    pagg,
    initialValues,
    onCreateSample,
    currUser,
    sampleControlBtnsSize,
    onEditSample,
    onViewSample,
    onDublicateSample,
    onInstanceDelete,
    samplePrewiewModalShow,
    setSamplePrewiewModalShow,
    onTransferSampleToKit,
    currentSampleId,
    onModalClose,
  } = useNoKitSampleBlock({
    setFormType,
    setShowModal,
    setTabValue,
    setTabs,
    setTabProps,
    setTabLabel,
    currentSample,
    setCurrentSample,
  });

  const areBtnsDisabled = !currentSampleId || currUser?.role?.roleId === 3;
  const sampleLoading = useSelector(selectSamplesLoading);

  const {menuVisible, handleContextMenu, menuPosition} = useContextMenu();

  return (
    <div className="fGrow fShrink fcCol jcsb overflowAuto" style={{position: "relative"}}>
      <ReactTable
        defaultData={instanceTableData}
        columns={lowerTableColumns}
        onSelect={onSampleSelect}
        current={currentSampleId}
        className={"scrollX fGrow fShrink"}
        tableClassName="tableCenterText"
        style={{
          marginBottom: "10px",
          maxHeight: "unset",
          height: "79%",
        }}
        onRowDoubleClick={onRowDoubleClick}
        handleContextMenu={handleContextMenu}
        selectOnRightBtn={true}
        onPaginate={pagg}
        paginatable={true}
        loading={sampleLoading}
      />

      <div className="fdr jcsb mt aic mb">
        <ContextMenu menuVisible={menuVisible} menuPosition={menuPosition}>
          <ContextMenuOption
            icon={AddIcon}
            onClick={onCreateSample}
            disabled={currUser?.role?.roleId === 3}
            text="add sample btn"
          />
          <ContextMenuOption
            icon={EditIcon}
            text={"edit sample btn"}
            onClick={onEditSample}
            disabled={areBtnsDisabled}
          />
          <ContextMenuOption icon={ViewIcon} text="view sample btn" onClick={onViewSample} disabled={areBtnsDisabled} />
          <ContextMenuOption
            icon={DublicateIcon}
            text="dubl sample btn"
            onClick={onDublicateSample}
            disabled={areBtnsDisabled}
          />
          <ContextMenuOption
            icon={LinkIcon}
            text="transfer sample btn"
            onClick={onTransferSampleToKit}
            disabled={areBtnsDisabled}
          />
          <Divider />
          <ContextMenuOption
            icon={DeleteIcon}
            text="del sample btn"
            onClick={onInstanceDelete}
            disabled={areBtnsDisabled}
          />
        </ContextMenu>
        {/* <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({})}
          validateOnChange={true}
          validateOnBlur={true}
          enableReinitialize
          onSubmit={() => {}}
        >
          <Form className="inv">
            <TextInput label={t("inv date")} name="invDate" readOnly labelStyles={{width: "12em"}} />
            <FunctionButton name="inv" />
          </Form>
        </Formik> */}
        <Formik initialValues={{}} validateOnChange={true} validateOnBlur={true} enableReinitialize>
          <Form>
            <ActionWithChosen />
          </Form>
        </Formik>
        <div className="fc gap aic">
          <div className="middle_buttons">
            <ImageButton
              src={AddIcon}
              tooltipMessage="add sample btn"
              onClick={() => {
                onCreateSample();
              }}
              disabled={currUser?.role?.roleId === 3}
              size={sampleControlBtnsSize}
            />
            <ImageButton
              src={EditIcon}
              tooltipMessage={`${t("edit sample btn")} (${t("Only one sample can be edited at the same time")})`}
              onClick={() => {
                onEditSample();
              }}
              disabled={areBtnsDisabled}
              size={sampleControlBtnsSize}
            />
            <ImageButton
              src={ViewIcon}
              tooltipMessage="view sample btn"
              className="upper_btn_item"
              onClick={onViewSample}
              disabled={areBtnsDisabled}
              size={sampleControlBtnsSize}
            />
            <ImageButton
              src={DublicateIcon}
              tooltipMessage="dubl sample btn"
              onClick={() => {
                onDublicateSample();
              }}
              disabled={areBtnsDisabled}
              size={sampleControlBtnsSize}
            />
            <ImageButton
              src={LinkIcon}
              tooltipMessage="transfer sample btn"
              onClick={onTransferSampleToKit}
              disabled={areBtnsDisabled}
              size={sampleControlBtnsSize}
            />
            <ImageButton
              src={DeleteIcon}
              tooltipMessage="del sample btn"
              onClick={() => {
                onInstanceDelete();
              }}
              disabled={areBtnsDisabled}
              size={sampleControlBtnsSize}
            />
          </div>
        </div>
      </div>
      <ModalForm
        closable={true}
        active={samplePrewiewModalShow}
        Component={SamplePreview}
        setModalActive={setSamplePrewiewModalShow}
        label={"SKU №" + currentSample?.skuNumber || ""}
        sample={currentSample}
        onModalClose={onModalClose}
      />
    </div>
  );
};

export default NoKitSampleBlock;

import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2";

import {fetchData} from "../../../hook/axios.hook";
import useColumns from "../../../hook/useColumns";
import {
  deleteKit,
  deselectKit,
  deselectSample,
  getAllKits,
  selectAllKits,
  selectAllSamples,
} from "../../../slices/SampleFunctionalSlice";
import {setCurrentKitId} from "../../../slices/SampleSlice/SampleSlice";
import {selectCurrentKitId} from "../../../slices/SampleSlice/selectors";
import {addPageTab, getCurrentUser, selectPageTabs, setCurentTabIndex, setPageTabs} from "../../../slices/UserSlice";
import useSwal from "../../../utilities/hooks/useSwal";
import useToaster from "../../../utilities/hooks/useToaster";
import {makeFilterManager} from "../../../utilities/makeFilterManager/makeFilterManager";
import ControlButtons from "../../common/controlButtons/ControlButtons";
import ReactTable from "../../conteiners/ReactTable";
import {KIT_SAMPLES_FILTERS_STORAGE_KEY} from "../../Filters/utils/constants";
import makeKitsFilters from "../../Filters/utils/makeKitsFilters";
import ModalForm from "../../Modal/FormModal";
import {SampleListUpperColumns} from "../../SamplesList/constants/columns";
import kitTableDataService from "../../SamplesList/constants/kitTableDataService";
import KitPreview from "../../SamplesList/previewModal/KitPreview";
import {TYPE_FORMS} from "../../SamplesList/SamplesList";
import {KIT_TYPE_FORMS} from "../const";
import ContextMenuButtons from "../ContextMenuButtons";

const KitTable = ({setCurrentSample, menuVisible, menuPosition, handleContextMenu, contextMenuType}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const Toaster = useToaster();
  const SWAL = useSwal();

  const [filteredKits, setFilteredKits] = useState(null);
  const [currentKit, setCurrentKit] = useState(null);
  const [kitPreviewModalShow, setKitPreviewModalShow] = useState(false);

  const tabs = useSelector(selectPageTabs);
  const currUser = useSelector(getCurrentUser);
  const allKits = useSelector(selectAllKits);
  const currentKitId = useSelector(selectCurrentKitId);
  const allSamples = useSelector(selectAllSamples);

  const SampleFilterManager = makeFilterManager(KIT_SAMPLES_FILTERS_STORAGE_KEY);
  const filters = useMemo(() => SampleFilterManager.getFilters() ?? {}, []);
  const kitFilters = makeKitsFilters(filters);
  const filterKitId = filters.kitId;

  const upperTableColumns = useColumns(SampleListUpperColumns);
  const kitTableData = kitTableDataService(filteredKits ?? allKits);

  const isOwn = currentKit?.brandManager?.userId === currUser?.userId;
  const areChangeKitBtnsDisabled = !currentKitId;

  const initialValues = useMemo(() => {
    return {
      brandManager: currentKit?.brandManager
        ? currentKit?.brandManager?.firstName + " " + currentKit?.brandManager?.lastName
        : "",
      kitSku: currentKit?.skuNumber,
      setDescArea: currentKit?.name,
    };
  }, [currentKit]);

  useEffect(() => {
    dispatch(getAllKits(kitFilters));
  }, []);

  useEffect(() => {
    if (filterKitId) {
      setFilteredKits(
        [...allKits].sort((a, b) => {
          if (a.kitId === filterKitId) return -1;
          if (b.kitId === filterKitId) return 1;
          return 0;
        }),
      );
    }
  }, [allKits.length]);

  useEffect(() => {
    setCurrentKit(allKits?.find(kit => kit.kitId === currentKitId) ?? null);
  }, [filters?.kitId, allKits?.length]);

  useEffect(() => {
    if (!currentKitId) {
      setCurrentKit(null);
    }
  }, [currentKitId]);

  const onCreateKit = async () => {
    dispatch(deselectKit());
    dispatch(deselectSample());
    setCurrentKit(null);

    const existIndex = tabs.findIndex(tab => tab.formType === KIT_TYPE_FORMS.CREATE_KIT);
    if (existIndex === -1) {
      dispatch(
        addPageTab({
          label: t("create kit"),
          controlled: true,
          askOnClose: true,
          formType: KIT_TYPE_FORMS.CREATE_KIT,
          props: {
            formInitialValues: {
              kitSku: "",
              setDescArea: "",
              brandManager: currUser?.firstName + " " + currUser?.lastName,
            },
          },
        }),
      );
    } else {
      dispatch(setCurentTabIndex(existIndex + 1));
    }
  };

  const editKitFunc = ({isKitEditOpen}) => {
    if (currentKit?.statusId !== 1) {
      Swal.fire({
        title: "",
        text: t("forbidd edit kit"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
      return;
    } else if (currentKit?.statusId === 1 && isOwn) {
      if (!isKitEditOpen)
        dispatch(
          addPageTab({
            label: `${t("edit k")} №${currentKit?.skuNumber ?? ""}`,
            controlled: true,
            askOnClose: true,
            formType: KIT_TYPE_FORMS.UPDATE_KIT,
            props: {
              kit: {...currentKit, samples: allSamples},
              formInitialValues: initialValues,
            },
          }),
        );
      else {
        let editTabIndex = null;
        dispatch(
          setPageTabs(
            tabs.map((tab, i) => {
              if (tab.formType === KIT_TYPE_FORMS.UPDATE_KIT) {
                editTabIndex = i;
                return {
                  ...tab,
                  label: `${t("edit k")} №${currentKit?.skuNumber ?? ""}`,
                  props: {
                    kit: {...currentKit, samples: allSamples},
                    formInitialValues: initialValues,
                  },
                };
              }
              return tab;
            }),
          ),
        );
        dispatch(setCurentTabIndex(editTabIndex + 1));
      }
    } else {
      Swal.fire({
        title: "",
        text: t("forbid edit not own"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
    }
  };

  const onEditKit = () => {
    const exist = tabs.find(tab => tab.formType === KIT_TYPE_FORMS.UPDATE_KIT);
    if (exist && exist.props.kitId === currentKitId) {
      Toaster.error("The kit has already been opened for editing");
      return;
    } else if (exist) {
      SWAL.confirm({
        title: "You can open only one kit form editting",
        confirmFunc: () => {
          editKitFunc({isKitEditOpen: true});
        },
      });
    } else {
      console.log("dsds");
      editKitFunc({isKitEditOpen: false});
    }
  };

  const onDeleteKit = () => {
    if (currentKit?.statusId !== 1) {
      Swal.fire({
        title: "",
        text: t("forbidd kit"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
      return;
    } else if (!isOwn) {
      Swal.fire({
        title: "",
        text: t("forbid not own kit"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
      return;
    } else if (currentKit?.statusId === 1 && isOwn) {
      fetchData(`/kits/get/${currentKitId}`, "get").then(resp => {
        if (resp.samples?.length > 0) {
          Swal.fire({
            title: "",
            text: t("contains ins"),
            icon: "info",
            confirmButtonText: t("Ok"),

            customClass: {
              popup: "zindex",
              container: "zindex",
              htmlContainer: "zindex",
            },
          });
          return;
        }
      });
      Swal.fire({
        title: "",
        text: t("del kit"),
        icon: "info",
        confirmButtonText: t("Continue"),
        showCancelButton: true,
        cancelButtonText: t("Cancel"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      }).then(answ => {
        if (answ.isConfirmed) {
          dispatch(deleteKit(currentKit.kitId)).then(resp => {
            if (resp.meta.requestStatus === "fulfilled") {
              Toaster.success("Kit deleted");
              dispatch(setCurrentKitId(null));
              setCurrentKit(null);
              const samplesFilters = SampleFilterManager.getFilters() ?? {};
              const isOnlyKitIdFilter = Object.keys(samplesFilters).length === 1 && !!samplesFilters?.kitId;
              isOnlyKitIdFilter
                ? SampleFilterManager.clearFilters()
                : SampleFilterManager.setFilters({...samplesFilters, kitId: null});
              const filteredtabs =
                tabs?.filter(tab => {
                  return tab.props?.kit?.kitId !== currentKit.kitId && tab.props?.kitId !== currentKit.kitId;
                }) || tabs;
              dispatch(setPageTabs(filteredtabs));
            }
          });
        }
      });
    }
  };

  const onDublicateKit = () => {
    if (!isOwn) {
      Swal.fire({
        title: "",
        text: t("forbit to dubl kit"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
    } else {
      // Swal.fire({
      //   title: "",
      //   text: t("kit dubl"),
      //   icon: "info",
      //   confirmButtonText: t("Continue"),
      //   showCancelButton: true,
      //   cancelButtonText: t("Cancel"),

      //   customClass: {
      //     popup: "zindex",
      //     container: "zindex",
      //     htmlContainer: "zindex",
      //   },
      // }).then(answ => {
      //   if (answ.isConfirmed) {
      // dispatch(dublicateKit(currentKit.kitId)).then(resp => {
      //   if (resp.meta.requestStatus === "fulfilled") {
      //     Toaster.success("Kit dublicated");
      dispatch(
        addPageTab({
          label: `${t("dubl kit btn")} №${currentKit?.skuNumber ?? ""}`,
          controlled: true,
          askOnClose: true,
          formType: TYPE_FORMS.DUBLICATE_KIT,
          props: {
            baseKit: {...currentKit, samples: allSamples},
            formInitialValues: initialValues,
          },
        }),
      );
      //   }
      // });
      // Swal.fire({
      //   title: "",
      //   text: "Внесіть зміни до kita та збережіть",
      //   icon: "info",
      //   confirmButtonText: t("Ok"),
      //   showCancelButton: false,
      // });
      //   }
      // });
    }
  };

  const onKitSelect = id => {
    setCurrentSample(null);
    const filters = SampleFilterManager.getFilters();
    if ((currentKit && id === currentKit.kitId) || id === currentKitId) {
      dispatch(setCurrentKitId(null));

      setCurrentKit(null);

      SampleFilterManager.setFilters({...filters, kitId: null});
      return;
    }

    dispatch(setCurrentKitId(id));
    const currKit = allKits?.find(item => item.kitId === id);
    SampleFilterManager.setFilters({...filters, kitId: id});
    setCurrentKit(currKit);
  };

  const onViewKit = () => {
    const viewKitTabIndex = tabs.findIndex(tab => tab.formType === KIT_TYPE_FORMS.VIEW_KIT);

    if (viewKitTabIndex > -1) {
      dispatch(
        setPageTabs(
          tabs.map(tab => {
            if (tab.formType === KIT_TYPE_FORMS.VIEW_KIT)
              return {
                ...tab,
                label: `${t("view k")} №${currentKit?.skuNumber ?? ""}`,
                props: {
                  kitId: currentKit.kitId,
                },
              };
            return tab;
          }),
        ),
      );
      dispatch(setCurentTabIndex(viewKitTabIndex + 1));
    } else {
      dispatch(
        addPageTab({
          label: `${t("view k")} №${currentKit?.skuNumber ?? ""}`,
          // controlled: true,
          formType: KIT_TYPE_FORMS.VIEW_KIT,
          props: {kitId: currentKit.kitId},
        }),
      );
    }
  };

  const onRowDoubleClick = row => {
    fetchData(`/kits/get/${row.id}`, "get").then(res => {
      if (res) {
        setCurrentKit(res);
        setKitPreviewModalShow(true);
      }
    });
  };

  const buttonsSettings = {
    add: {onClick: onCreateKit, tolltip: "add kit btn", disabled: false},
    edit: {
      onClick: onEditKit,
      tolltip: `edit kit btn`,
      disabled: areChangeKitBtnsDisabled,
    },
    view: {onClick: onViewKit, tolltip: "view kit btn", disabled: areChangeKitBtnsDisabled},
    dublicate: {onClick: onDublicateKit, tolltip: "dubl kit btn", disabled: areChangeKitBtnsDisabled},
    del: {onClick: onDeleteKit, tolltip: "del kit btn", disabled: areChangeKitBtnsDisabled},
  };

  return (
    <div className="upper_part_s" style={{marginBottom: "20px", height: "35%"}}>
      <ReactTable
        defaultData={kitTableData}
        columns={upperTableColumns}
        onSelect={onKitSelect}
        current={currentKit?.kitId || currentKitId || null}
        className={"scrollX"}
        onRowDoubleClick={onRowDoubleClick}
        tableClassName="tableCenterText"
        selectOnRightBtn={true}
        handleContextMenu={handleContextMenu}
      />

      {contextMenuType === "KIT" && (
        <ContextMenuButtons settings={buttonsSettings} menuVisible={menuVisible} menuPosition={menuPosition} />
      )}
      <div className="fcCol" style={{marginLeft: "1em"}}>
        <div className="tac">{t("Kit")}</div>
        <ControlButtons settings={buttonsSettings} className={"upper_buttons"} />
      </div>
      <ModalForm
        closable={true}
        active={kitPreviewModalShow}
        Component={KitPreview}
        setModalActive={setKitPreviewModalShow}
        label={t("sett") + " № " + currentKit?.skuNumber || ""}
        kit={currentKit}
        className="uni_modal"
      />
    </div>
  );
};

export default KitTable;

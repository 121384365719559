import {MODE} from "../gitIgnore/appMode";

// import {MODE} from "../gitIgnore/appMode";
const baseUrls = {
  DEV: "https://api.ba3a-dev-sample.technogroup.com.pl/",
  // TEST: "https://api.ba3a-test.technogroup.com.pl",
  PROD: "https://api.ba3a-sample.technogroup.com.pl/",
  // old: "https://api.osvitabezmezh.com.ua",
};

export const apiUrl = baseUrls[MODE];

export const KIT_SAMPLE_TAB_INITIAL_VALUES = Object.freeze({
  kitSku: "",
  setDescArea: "",
  factoryId: "",
  skuNumber: "",
  groupId: "",
  subgroupId: "",
  materialId: "",
  categoryId: "",
  textureId: "",
  collection: "",
  color: "",
  name: "",
  state: "",
  sampleImages: [],
  sampleQrCodeUrl: "",
});

export function formSampleValues(item) {
  return {
    factoryId: item?.factoryId || "",
    skuNumber: item?.skuNumber || "",
    groupId: item?.groupId || "",
    subgroupId: item?.subgroupId || "",
    materialId: item?.materialId || "",
    categoryId: item?.categoryId || "",
    textureId: item?.textureId || "",
    collection: item?.collection || "",
    color: item?.color || "",
    name: item?.name || "",
    state: item?.state || "",
  };
}

export function formSampleTextInputValues(item) {
  return {
    skuNumber: item?.skuNumber || "",
    collection: item?.collection || "",
    color: item?.color || "",
    name: item?.name || "",
    state: item?.state || "",
    groupId: item?.group?.groupName || "",
    subgroupId: item?.subgroup?.subgroupName || "",
    materialId: item?.material?.materialName || "",
    categoryId: item?.category?.categoryName || "",
    textureId: item?.texture?.textureName || "",
    factoryId: item?.factory?.factoryName || "",
  };
}

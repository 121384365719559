import React from "react";
import {useReactToPrint} from "react-to-print";
import translit from "ua-en-translit";

import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import ImgBox from "../../Inputs/fileSelect/ImgBox/ImgBox";
import FilePreview from "../../Inputs/fileSelect/preview/FilePreview";

const QR = ({instance, images, addMethod, deleteMethod, readOnly = false}) => {
  const componentRef = React.useRef();

  const handlePrintQR = useReactToPrint({
    content: () => componentRef.current,
  });

  const addImg = arrFiles => {
    var formdata = new FormData();
    var i = 0,
      file,
      filename;

    for (; i < arrFiles.length; i++) {
      file = arrFiles[i];
      filename = translit(file.name);
      formdata.append("image", file, filename);
    }

    addMethod(formdata);
  };

  function onDell(imgUrl) {
    const img = images?.find(item => item.imageUrl === imgUrl);
    deleteMethod(img?.imageId);
  }
  return (
    <div className="qr_whole">
      <div className="qr">
        <div className="temp" ref={componentRef}>
          {instance?.qrCodeUrl && <img src={instance.qrCodeUrl} alt="qr" width="100%" height="100%" />}
        </div>
        <FunctionButton name="print qr" disabled={!instance?.qrCodeUrl} onClick={handlePrintQR} />
      </div>

      <div className="imgBoxS image_wrap color" style={{height: "15em", width: "15em"}}>
        <ImgBox imgs={images?.map(item => item.imageUrl) || []} width={"15em"} height={"15em"} />

        {!readOnly && (
          <FilePreview
            imgs={images?.map(item => item.imageUrl) || []}
            accept={"image/*"}
            addFunc={file => {
              addImg(file);
            }}
            delFunc={file => {
              onDell(file);
            }}
            cropNeed={true}
            disable={!instance}
          />
        )}
      </div>
    </div>
  );
};

export default QR;

import useColumns from "../../../hook/useColumns";

import {SamplesTransferListTableColumns} from "./constants";

const useSamplesTransferList = () => {
  const tableColumns = useColumns(SamplesTransferListTableColumns);
  const tableData = [];

  function onExecuteActionBtnClick() {}

  return {tableColumns, tableData, onExecuteActionBtnClick};
};

export default useSamplesTransferList;

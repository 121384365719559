// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReactTable_reactTableRow__ubuLY {
  cursor: pointer;
}
.ReactTable_reactTableRow__ubuLY:hover, .ReactTable_reactTableRow__ubuLY:active {
  background-color: rgba(191, 205, 226, 0.2941176471);
}

.ReactTable_sortableHeader__-mkLt {
  position: relative;
  cursor: pointer;
}
.ReactTable_sortableHeader__-mkLt::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 6px;
  height: 6px;
  background-image: linear-gradient(to bottom right, transparent 50%, #426ba6 0);
}`, "",{"version":3,"sources":["webpack://./src/Components/conteiners/ReactTable.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACE;EAGE,mDAAA;AADJ;;AAKA;EACE,kBAAA;EACA,eAAA;AAFF;AAIE;EACE,WAAA;EACA,cAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,UAAA;EACA,WAAA;EACA,8EAAA;AAFJ","sourcesContent":[".reactTableRow {\n  cursor: pointer;\n\n  &:hover,\n  // &:focus,\n  &:active {\n    background-color: #bfcde24b;\n  }\n}\n\n.sortableHeader {\n  position: relative;\n  cursor: pointer;\n\n  &::after {\n    content: \"\";\n    display: block;\n    position: absolute;\n    bottom: 0;\n    right: 0;\n    width: 6px;\n    height: 6px;\n    background-image: linear-gradient(to bottom right, transparent 50%, #426ba6 0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reactTableRow": `ReactTable_reactTableRow__ubuLY`,
	"sortableHeader": `ReactTable_sortableHeader__-mkLt`
};
export default ___CSS_LOADER_EXPORT___;

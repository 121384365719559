import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {setSample} from "../../slices/SampleFunctionalSlice";
import {setCurrentSampleId} from "../../slices/SampleSlice/SampleSlice";
import {getAllUsers, getCurrentUser, getUsers} from "../../slices/UserSlice";
import useDynamicPageContainer from "../DynamicPageContainer/useDynamicPageContainer";

const useSamplesListNew = ({setTabs, setTabValue}) => {
  const dispatch = useDispatch();

  const currUser = useSelector(getCurrentUser);

  const [currentSample, setCurrentSample] = useState(null);

  const users = useSelector(getUsers);

  useEffect(() => {
    if (!users?.length) {
      dispatch(getAllUsers());
    }
  }, []);

  useEffect(() => {
    setTabs(prev => {
      if (prev.length === 1) {
        dispatch(setSample(null));
      }
      return prev;
    });
  }, []);

  const {formType, setFormType, setTabProps, setTabLabel} = useDynamicPageContainer({setTabs, setTabValue});

  function onFilterAdditional() {
    dispatch(setCurrentSampleId(null));
  }
  function onFilterDelAdditional() {
    dispatch(setCurrentSampleId(null));
  }

  return {
    currUser,
    currentSample,
    setCurrentSample,
    setTabLabel,
    setTabProps,
    formType,
    setFormType,
    onFilterAdditional,
    onFilterDelAdditional,
  };
};

export default useSamplesListNew;

import {useTranslation} from "react-i18next";
import {Divider} from "@mui/material";
import {Form, Formik} from "formik";

import {addNewSampleImages, deleteSampleImage} from "../../../../slices/SampleFunctionalSlice";
import {TextInput} from "../../../Inputs";
import QRSampleModalPart from "../../../SamplesList/SampleUniModal/SampleUniModalParts/QRSampleModalPart";
import SampleTabPart from "../../SampleTabPart/SampleTabPart";
import useUpdateTab from "../useUpdateTab";

import useCreateSampleTab from "./useCreateSampleTab";

const CreateSampleTabBase = ({setTabs}) => {
  const {t} = useTranslation();

  const {newSample, sampleImgs, typeForm} = useCreateSampleTab();

  useUpdateTab({typeForm});

  return (
    <div className="h100">
      <div className="h100" style={{paddingTop: "1em"}}>
        <Form className="fcCol h100">
          <div className="fc jcsb aibl">
            <div className="mb">{t("inst")}</div>
            <TextInput label={t("BM")} width="17em" name="brandManager" disabled />
          </div>
          <Divider />

          <div className="w">
            <SampleTabPart typeForm={typeForm} setTabs={setTabs} />
            <QRSampleModalPart
              typeForm={typeForm}
              isCreate={true}
              sample={newSample}
              images={sampleImgs}
              addMethod={addNewSampleImages}
              deleteMethod={deleteSampleImage}
              qrCodeUrl={newSample?.qrCodeUrl}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

const CreateSampleTab = ({setTabs}) => {
  const {validationSchema, initialValues} = useCreateSampleTab();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
    >
      <CreateSampleTabBase setTabs={setTabs} />
    </Formik>
  );
};

export default CreateSampleTab;

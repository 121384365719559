import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {fetchList} from "../hook/axios.hook";

const initialState = {
  officesCatalog: [],
  rolesCatalog: [],
  countriesCatalog: [],
  factoriesCatalog: [],
  statusCatalog: [],
};

export const fetchOfficesCatalog = createAsyncThunk("catalogs/fetchOfficesCatalog", async () => {
  const responce = fetchList("/offices/getAll", "get");

  return responce;
});
export const fetchRolesCatalog = createAsyncThunk("catalogs/fetchRolesCatalog ", async () => {
  const responce = fetchList("/roles/getAll", "get");

  return responce;
});
export const fetchCountriesCatalog = createAsyncThunk("catalogs/fetchCountriesCatalog", async () => {
  const responce = fetchList("/countries/getAll", "get");

  return responce;
});

export const fetchFactoriesCatalog = createAsyncThunk("catalogs/fetchFactoriesCatalog", async () => {
  const responce = fetchList("/factories/getAll", "get");
  return responce;
});

export const fetchStatusCatalog = createAsyncThunk("catalogs/fetchStatusCatalog", async () =>
  fetchList("/samples/statuses/getAll", "get"),
);

const catalogSlice = createSlice({
  name: "catalogs",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder

      .addCase(fetchOfficesCatalog.fulfilled, (state, {payload}) => {
        state.officesCatalog = payload;
      })

      .addCase(fetchRolesCatalog.fulfilled, (state, {payload}) => {
        state.rolesCatalog = payload;
      })

      .addCase(fetchCountriesCatalog.fulfilled, (state, {payload}) => {
        state.countriesCatalog = payload;
      })

      .addCase(fetchFactoriesCatalog.fulfilled, (state, {payload}) => {
        state.factoriesCatalog = payload;
      })
      .addCase(fetchStatusCatalog.fulfilled, (state, {payload}) => {
        state.statusCatalog = payload;
      })
      .addDefaultCase(() => {});
  },
});

const {actions, reducer} = catalogSlice;

export default reducer;

export const getOfficesCatalog = state => state.catalogs.officesCatalog;
export const getRolesCatalog = state => state.catalogs.rolesCatalog;
export const getCountriesCatalog = state => state.catalogs.countriesCatalog;
export const getFactoriesCatalog = state => state.catalogs.factoriesCatalog;
export const getStatusCatalog = state => state.catalogs.statusCatalog;

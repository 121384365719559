import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

export const TOAST_TYPES = Object.freeze({
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
  DEFAULT: "default",
});

const useToaster = () => {
  const {t} = useTranslation();
  const returnValue = {};

  Object.keys(TOAST_TYPES).forEach(typeKey => {
    returnValue[TOAST_TYPES[typeKey]] = message => toast[TOAST_TYPES[typeKey]](t(message), {autoClose: 2000});
  });

  return returnValue;
};

export default useToaster;

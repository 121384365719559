import {createSelector} from "@reduxjs/toolkit";

import {SAMPLES_STATE_NAME} from "./SampleSlice";

export const selectAreSamplesLoading = state => state[SAMPLES_STATE_NAME].samplesLoading;

//groups
export const selectGroups = state => state[SAMPLES_STATE_NAME].groups;
export const selectCurrentGroupId = state => state[SAMPLES_STATE_NAME].current.currentGroupId;
export const selectCurrentGroup = createSelector(
  [selectGroups, selectCurrentGroupId],
  (groups, currentGroupId) => groups?.find(group => group.groupId === currentGroupId) ?? null,
);
//subgroups
export const selectCurrentGroupSubGroups = state => state[SAMPLES_STATE_NAME].current.currentGroupSubGroups;
export const selectCurrentSubGroupId = state => state[SAMPLES_STATE_NAME].current.currentSubGroupId;
export const selectCurrentSubGroup = createSelector(
  [selectCurrentGroupSubGroups, selectCurrentSubGroupId],
  (subGroups, currentSubGroupId) => subGroups?.find(subGroup => subGroup.subgroupId === currentSubGroupId) ?? null,
);

//materials
export const selectMaterials = state => state[SAMPLES_STATE_NAME].materials;
export const selectCurrentMaterialId = state => state[SAMPLES_STATE_NAME].current.currentMaterialId;
export const selectCurrentMaterial = createSelector(
  [selectMaterials, selectCurrentMaterialId],
  (materials, currentMaterialId) => materials?.find(material => material.materialId === currentMaterialId) ?? null,
);
//categories
export const selectCurrentMaterialCategories = state => state[SAMPLES_STATE_NAME].current.currentMaterialCategories;
export const selectCurrentCategoryId = state => state[SAMPLES_STATE_NAME].current.currentCategoryId;
export const selectCurrentCategory = createSelector(
  [selectCurrentMaterialCategories, selectCurrentCategoryId],
  (categories, currentCategoryId) => categories?.find(category => category.categoryId === currentCategoryId) ?? null,
);

//textures
export const selectTextures = state => state[SAMPLES_STATE_NAME].textures;
export const selectCurrentTextureId = state => state[SAMPLES_STATE_NAME].current.currentTextureId;
export const selectCurrentTexture = createSelector(
  [selectTextures, selectCurrentTextureId],
  (textures, currentTextureId) => textures?.find(texture => texture.textureId === currentTextureId) ?? null,
);

//samples
export const selectAllSamples = state => state[SAMPLES_STATE_NAME].allSamples;

//kits
export const selectAllKits = state => state[SAMPLES_STATE_NAME].allKits;
export const selectCurrentKitId = state => state[SAMPLES_STATE_NAME].current.currentKitId;

export const selectCurrentSampleId = state => state[SAMPLES_STATE_NAME].current.currentSampleId;

//modalData
export const selectCreateInstanceModalData = state => state[SAMPLES_STATE_NAME].CREATE_INSTANCE;
export const selectUpdateInstanceModalData = state => state[SAMPLES_STATE_NAME].UPDATE_INSTANCE;
export const selectCreateKitModalData = state => state[SAMPLES_STATE_NAME].CREATE_KIT;
export const selectUpdateKitModalData = state => state[SAMPLES_STATE_NAME].UPDATE_KIT;

import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2";

import {selectSample, updateSample} from "../../../slices/SampleFunctionalSlice";
import {getCurrentUser, getInfo} from "../../../slices/UserSlice";
import {withSampleMod} from "../constants/SampleHOC";

const SampleUpdateModal = ({typeForm}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const sample = useSelector(selectSample);
  const currUser = useSelector(getCurrentUser);

  const onSetClick = data => {};
  const onInstanceClick = (data, setValues) => {
    Swal.fire({
      title: "",
      text: t("if upd"),
      icon: "info",
      confirmButtonText: t("Continue"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),

      customClass: {
        popup: "zindex",
        container: "zindex",
        htmlContainer: "zindex",
      },
    }).then(answ => {
      if (answ.isConfirmed) {
        dispatch(updateSample({sampleId: sample.sampleId, data})).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            getInfo(t("ins updated"));
          }
        });
      }
    });
  };

  const onSetCancel = (data, setValues, resetForm) => {};

  const onInstanceCancel = (data, setValues, resetForm) => {
    Swal.fire({
      title: "",
      text: t("go back"),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("No"),

      customClass: {
        popup: "zindex",
        container: "zindex",
        htmlContainer: "zindex",
      },
    }).then(answ => {
      if (answ.isConfirmed) {
        setValues({
          ...data,
          factoryId: 2,
          // brandManager: currUser?.lastName + " " + currUser?.firstName,
        });
      }
    });
  };

  const SampleForm = withSampleMod(
    t("renew"),
    t("renew"),
    onSetClick,
    onInstanceClick,
    onSetCancel,
    onInstanceCancel,
    false,
  );
  return <SampleForm typeForm={typeForm} />;
};

export default SampleUpdateModal;

import {createSlice} from "@reduxjs/toolkit";

import {KIT_SAMPLES_FILTERS_STORAGE_KEY} from "../../Components/Filters/utils/constants";
import {SAMPLES_FILTERS_STORAGE_KEY} from "../../Components/SamplesList/SampleParts/SampleFilter/utils/constants";
import {TYPE_FORMS} from "../../Components/SamplesList/SamplesList";
import {
  addNewKit,
  addNewKitImages,
  addNewSample,
  addNewSampleImages,
  deleteKitImage,
  deleteSampleImage,
  dublicateKit,
  getKit,
} from "../SampleFunctionalSlice";

import {
  addCategory,
  addGroup,
  addMaterial,
  addSubGroup,
  addTexture,
  deleteCategory,
  deleteGroup,
  deleteMaterial,
  deleteSubGroup,
  deleteTexture,
  fetchAllCategories,
  fetchAllGroups,
  fetchAllMaterials,
  fetchAllSubGroups,
  fetchAllTextures,
  fetchCurrentTableSample,
  updateCategory,
  updateGroup,
  updateMaterial,
  updateSubGroup,
  updateTexture,
} from "./operations";

export const SAMPLES_STATE_NAME = "samples";

const parsedfilters = JSON.parse(sessionStorage.getItem(KIT_SAMPLES_FILTERS_STORAGE_KEY));

const initialState = {
  samplesLoading: false,
  error: [],
  groups: null,
  materials: null,
  textures: null,
  current: {
    currentGroupId: null,
    currentSubGroupId: null,
    currentGroupSubGroups: null,
    currentMaterialId: null,
    currentMaterialCategories: null,
    currentCategoryId: null,
    currentTextureId: null,
    currentKitId: parsedfilters?.kitId || null,
    currentSampleId: null,
  },
  modalData: {
    CREATE_KIT: {},
    UPDATE_KIT: {},
    CREATE_KIT_SAMPLES: {},
    EDIT_KIT_SAMPLES: {},
    CREATE_INSTANCE: {},
    UPDATE_INSTANCE: {},
    CREATE_SAMPLE: {},
    EDIT_SAMPLE: {},
    TRANSFER_SAMPLE_TO_KIT: {},
    DUBLICATE_SAMPLE: {},
  },
};

const SampleSlice = createSlice({
  name: SAMPLES_STATE_NAME,
  initialState,
  reducers: {
    setCurrentGroupId: (state, {payload}) => {
      state.current.currentGroupId = payload;
      state.current.currentGroupSubGroups = null;
    },
    setCurrentSubGroupId: (state, {payload}) => {
      state.current.currentSubGroupId = payload;
    },
    setCurrentMaterialId: (state, {payload}) => {
      state.current.currentMaterialId = payload;
    },
    setCurrentCategoryId: (state, {payload}) => {
      state.current.currentCategoryId = payload;
    },
    setCurrentTextureId: (state, {payload}) => {
      state.current.currentTextureId = payload;
    },
    setCurrentKitId: (state, {payload}) => {
      state.current.currentKitId = payload;
    },
    setCurrentSampleId: (state, {payload}) => {
      state.current.currentSampleId = payload;
    },
    clearCurrent: state => {
      state.current = {
        currentGroupId: null,
        currentSubGroupId: null,
        currentGroupSubGroups: null,
        currentMaterialId: null,
        currentMaterialCategories: null,
        currentCategoryId: null,
        currentTextureId: null,
      };
    },
    updateModalData: (state, {payload: {typeForm, formData}}) => {
      state.modalData[typeForm] = {...state.modalData[typeForm], ...(formData ?? {})};
    },
    setModalData: (state, {payload: {typeForm, formData}}) => {
      state.modalData[typeForm] = formData;
    },
    setNoNewKit: (state, {payload: {typeForm}}) => {
      state.modalData[typeForm] = {...state.modalData[typeForm], isNewKitAdded: false, kitQrCodeUrl: "", kitImages: []};
    },
    setNoNewSample: (state, {payload: {typeForm}}) => {
      state.modalData[typeForm] = {
        ...state.modalData[typeForm],
        isNewSampleAdded: false,
        sampleQrCodeUrl: "",
        sampleImages: [],
      };
    },
    clearCurrentTableSample: (state, {payload}) => {
      const typeForm = payload.typeForm;
      if (typeForm) {
        state.modalData[typeForm].sample = null;
        state.modalData[typeForm].sampleQrCodeUrl = null;
        state.modalData[typeForm].sampleImages = null;
        state.modalData[typeForm].isNewSampleAdded = false;
      }
    },
    setCurrentTableSample: (state, {payload: {typeForm, sample}}) => {
      if (typeForm) {
        state.modalData[typeForm].sample = sample;
      }
    },
    setCurrentTableSampleImages: (state, {payload: {typeForm, images}}) => {
      if (typeForm) {
        if (!state.modalData[typeForm].sample) {
          state.modalData[typeForm].sample = {};
        }
        state.modalData[typeForm].sample.images = images;
      }
    },
  },
  extraReducers: builder => {
    builder
      //*** Sample Groups ***
      //get
      .addCase(fetchAllGroups.fulfilled, (state, {payload}) => {
        state.groups = payload;
      })
      //post
      .addCase(addGroup.fulfilled, (state, {payload}) => {
        state.groups = [...state.groups, payload];
        state.current.currentGroupId = payload.groupId;
      })
      //patch
      .addCase(updateGroup.fulfilled, (state, {payload}) => {
        state.groups = state.groups.map(group => {
          if (group.groupId === payload.groupId) return payload;
          return group;
        });
      })
      //delete
      .addCase(deleteGroup.fulfilled, (state, {meta}) => {
        const groupId = meta.arg;
        state.groups = state.groups.filter(group => group.groupId !== groupId);
        state.current.currentGroupId = null;
        state.current.currentSubGroupId = null;
        state.current.currentGroupSubGroups = null;
      })

      //* Sample Subgroups *
      //get
      .addCase(fetchAllSubGroups.fulfilled, (state, {payload, meta}) => {
        const groupId = meta.arg;
        state.current.currentGroupId = groupId;
        state.current.currentGroupSubGroups = payload;
      })
      //post
      .addCase(addSubGroup.fulfilled, (state, {payload}) => {
        state.current.currentGroupSubGroups = [...state.current.currentGroupSubGroups, payload];
        // state.current.currentSubGroupId = payload.subgroupId;
      })
      //patch
      .addCase(updateSubGroup.fulfilled, (state, {payload}) => {
        state.current.currentGroupSubGroups = state.current.currentGroupSubGroups.map(subGroup => {
          if (subGroup.subgroupId === payload.subgroupId) return payload;
          return subGroup;
        });
      })
      //delete
      .addCase(deleteSubGroup.fulfilled, (state, {meta}) => {
        const subgroupId = meta.arg;
        state.current.currentGroupSubGroups = state.current.currentGroupSubGroups.filter(
          subGroup => subGroup.subgroupId !== subgroupId,
        );
        state.current.currentSubGroupId = null;
      })
      //*** Materials ***
      //get
      .addCase(fetchAllMaterials.fulfilled, (state, {payload}) => {
        state.materials = payload;
      })
      //post
      .addCase(addMaterial.fulfilled, (state, {payload}) => {
        state.materials = [...state.materials, payload];
        state.current.currentMaterialId = payload.materialId;
      })
      //patch
      .addCase(updateMaterial.fulfilled, (state, {payload}) => {
        state.materials = state.materials.map(material => {
          if (material.materialId === payload.materialId) return payload;
          return material;
        });
      })
      //delete
      .addCase(deleteMaterial.fulfilled, (state, {meta}) => {
        const materialId = meta.arg;
        state.materials = state.materials.filter(material => material.materialId !== materialId);
        state.current.currentCategoryId = null;
        state.current.currentMaterialId = null;
        state.current.currentMaterialCategories = null;
      })
      //* Categories *
      //get
      .addCase(fetchAllCategories.fulfilled, (state, {payload, meta}) => {
        const materialId = meta.arg;
        state.current.currentMaterialId = materialId;
        state.current.currentMaterialCategories = payload;
      })
      //post
      .addCase(addCategory.fulfilled, (state, {payload}) => {
        state.current.currentMaterialCategories = [...state.current.currentMaterialCategories, payload];
        // state.current.currentCategoryId = payload.categoryId;
      })
      //patch
      .addCase(updateCategory.fulfilled, (state, {payload}) => {
        state.current.currentMaterialCategories = state.current.currentMaterialCategories.map(category => {
          if (category.categoryId === payload.categoryId) return payload;
          return category;
        });
      })
      //delete
      .addCase(deleteCategory.fulfilled, (state, {meta}) => {
        const categoryId = meta.arg;
        state.current.currentMaterialCategories = state.current.currentMaterialCategories.filter(
          category => category.categoryId !== categoryId,
        );
        state.current.currentCategoryId = null;
      })
      //*** Textures ***
      //get
      .addCase(fetchAllTextures.fulfilled, (state, {payload}) => {
        state.textures = payload;
      })
      //post
      .addCase(addTexture.fulfilled, (state, {payload}) => {
        state.textures = [...state.textures, payload];
        // state.current.currentTextureId = payload.textureId;
      })
      //patch
      .addCase(updateTexture.fulfilled, (state, {payload}) => {
        state.textures = state.textures.map(texture => {
          if (texture.textureId === payload.textureId) return payload;
          return texture;
        });
      })
      //delete
      .addCase(deleteTexture.fulfilled, (state, {meta}) => {
        const textureId = meta.arg;
        state.textures = state.textures.filter(texture => texture.textureId !== textureId);
        state.current.currentTextureId = null;
      })

      .addCase(addNewKitImages.fulfilled, (state, {payload, meta}) => {
        const typeForm = meta.arg.typeForm;
        if (typeForm) {
          state.modalData[typeForm].kitImages = payload;
        }
      })
      // .addCase(deleteKitImage.fulfilled, (state, {payload, meta}) => {
      //   const typeForm = meta.arg.typeForm;
      //   // if (typeForm === TYPE_FORMS.CREATE_KIT || typeForm === TYPE_FORMS.CREATE_INSTANCE) {
      //   state.modalData[typeForm].kitImages = payload;
      //   // }
      //   // else {
      //   //   state.modalData[typeForm].kit.images = payload;
      //   // }
      // })
      .addCase(addNewSampleImages.fulfilled, (state, {payload, meta}) => {
        const typeForm = meta.arg.typeForm;
        if (typeForm) {
          state.modalData[typeForm].sampleImages = payload;
        }
      })
      .addCase(deleteSampleImage.fulfilled, (state, {payload, meta}) => {
        const typeForm = meta.arg.typeForm;
        if (
          typeForm === TYPE_FORMS.CREATE_KIT ||
          typeForm === TYPE_FORMS.CREATE_INSTANCE ||
          typeForm === TYPE_FORMS.CREATE_SAMPLE
        ) {
          state.modalData[typeForm].sampleImages = payload;
        }
        if (typeForm === TYPE_FORMS.UPDATE_KIT) {
          state.modalData[typeForm].sample.images = payload;
        }
        // else {
        //   state.modalData[typeForm].sample.images = payload;
        // }
      })
      .addCase(fetchCurrentTableSample.fulfilled, (state, {payload, meta}) => {
        const typeForm = meta.arg.typeForm;
        if (typeForm) {
          state.modalData[typeForm].sample = payload;
          state.modalData[typeForm].sampleImages = payload.images;
        }
      })
      .addCase(addNewKit.fulfilled, (state, {payload, meta}) => {
        const typeForm = meta.arg.typeForm;
        if (typeForm) {
          state.modalData[typeForm].kitQrCodeUrl = payload.qrCodeUrl;
          state.modalData[typeForm].isNewKitAdded = true;
          state.current.currentKitId = payload.kitId;
        }
      })
      .addCase(addNewSample.fulfilled, (state, {payload, meta}) => {
        const typeForm = meta.arg.typeForm;
        if (typeForm) {
          state.modalData[typeForm].sampleQrCodeUrl = payload.qrCodeUrl;
          state.modalData[typeForm].isNewSampleAdded = true;
        }
      })
      .addCase(dublicateKit.fulfilled, (state, {payload}) => {
        state.current.currentKitId = payload.kitId;
      })
      .addCase(getKit.fulfilled, (state, {payload, meta}) => {
        const typeForm = meta.arg.typeForm;
        if (typeForm) {
          state.modalData[typeForm].kitImages = payload.images;
        }
      })
      // .addCase(updateKit.fulfilled, (state, {payload, meta}) => {})
      // .addCase(updateSample.fulfilled, (state, {payload, meta}) => {})

      //matchers
      .addMatcher(
        action => action.type.endsWith("/fulfilled"),
        state => handleFulfilled(state),
      )
      .addMatcher(
        action => action.type.endsWith("/pending"),
        state => handlePending(state),
      )
      .addMatcher(
        action => action.type.endsWith("/rejected"),
        state => handleRejected(state),
      );
  },
});

//matcher functions
function handleFulfilled(state) {
  // state.samplesLoading = false;
  state.error = [];
}
function handlePending(state) {
  // state.samplesLoading = true;
  state.error = [];
}
function handleRejected(state, error) {
  state.samplesLoading = false;
  state.error = error;
}

//actions, reducer
const {actions, reducer} = SampleSlice;
export const {
  setCurrentGroupId,
  setCurrentMaterialId,
  setCurrentSubGroupId,
  setCurrentCategoryId,
  setCurrentTextureId,
  clearCurrent,
  setCurrentKitId,
  setModalData,
  updateModalData,
  setNoNewKit,
  setNoNewSample,
  clearCurrentTableSample,
  setCurrentTableSample,
  setCurrentTableSampleImages,
  setCurrentSampleId,
} = actions;
export default reducer;

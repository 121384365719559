import PropTypes from "prop-types";

import {useDebouncedCallback} from "../../hook/debounced";
import QuestionItem from "../../utilities/QuestionItem";

import useInputsTooltip from "./hook/InputsTooltipService";

const ControlledTextInput = ({
  label,
  width = "67%",
  className = "",
  generalClassName = "",
  inputClassName = "",
  question = null,
  error = null,
  func = null,
  autolabel,
  labelWrap,
  labelStyles,
  setFieldValue,
  fieldValue,
  disabled = false,
  placeholder = "",
  hasTitle = false,
  ...props
}) => {
  const {tooltipShown, onMouseEnter, onMouseLeave, setTooltipShown} = useInputsTooltip({value: fieldValue});

  const funcDebounce = useDebouncedCallback(value => {
    if (func !== null) func(value);
  }, 1000);

  const onChange = e => {
    setFieldValue(e.target.value);
    if (e.target.value.length > 0) {
      setTooltipShown(false);
    }
    if (func !== null) {
      funcDebounce(e.target.value);
    }
  };

  return (
    <div className={`project_input ${generalClassName} ${labelWrap ? "" : "labelWrap"}`} style={props.style}>
      {label && (
        <label htmlFor={props.name} style={labelStyles} className={autolabel ? "autolabel" : ""}>
          {/* { typeof label === "string" ? t(label) : label }  */}
          {label}
        </label>
      )}
      <div className="" style={{width: width, position: "relative"}}>
        <div className={className}>
          <input
            value={fieldValue || ""}
            className={inputClassName}
            disabled={disabled}
            autoComplete="off"
            placeholder={placeholder}
            onMouseEnter={question ? onMouseEnter : null}
            onMouseLeave={question ? onMouseLeave : null}
            onChange={onChange}
            title={hasTitle && fieldValue ? fieldValue : null}
          />
        </div>
        {error ? <div className="error">{error}</div> : null}
      </div>

      {question ? <QuestionItem title={question} tooltipShown={tooltipShown} /> : null}
    </div>
  );
};

ControlledTextInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.string,
  className: PropTypes.string,
  question: PropTypes.string,
  func: PropTypes.func,
  autolabel: PropTypes.bool,
  labelStyles: PropTypes.object,
  setFieldValue: PropTypes.func,
  fieldValue: PropTypes.string,
  generalClassName: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  hasTitle: PropTypes.bool,
  props: PropTypes.array,
};

export default ControlledTextInput;

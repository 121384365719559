import {useEffect} from "react";
import {useDispatch} from "react-redux";

import {deselectKit, deselectSample, setSample} from "../../slices/SampleFunctionalSlice";
import {setModalData} from "../../slices/SampleSlice/SampleSlice";
import {TYPE_FORMS} from "../SamplesList/SamplesList";
import {SAMPLE_UNI_MOD_INITIAL_VALUES} from "../SamplesList/SampleUniModal/SampleUniMod";

import DynamicPageContainer from "./DynamicPageContainer";

const withDynamicPageContainer = ({Component, title}) => {
  const WithDynamicPageContainer = () => {
    const dispatch = useDispatch();

    useEffect(() => {
      return () => {
        dispatch(setSample(null));
      };
    }, []);

    function onCloseTab(tab) {
      const typeForm = tab.props.typeForm;
      switch (typeForm) {
        case TYPE_FORMS.UPDATE_KIT:
          dispatch(deselectKit());
          break;
        case TYPE_FORMS.UPDATE_INSTANCE:
          dispatch(deselectSample());
          break;
        case TYPE_FORMS.EDIT_SAMPLE:
          dispatch(deselectSample());
          break;
      }
      setTimeout(() => {
        dispatch(setModalData({typeForm, formData: SAMPLE_UNI_MOD_INITIAL_VALUES}));
      }, []);
    }

    return <DynamicPageContainer title={title} Component={Component} onCloseTab={onCloseTab} />;
  };

  return WithDynamicPageContainer;
};

export default withDynamicPageContainer;

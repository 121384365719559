export default function makeKitsFilters(filters) {
  if (!filters) return {};

  const {
    brandManagerId = "",
    managerId = "",
    status = "",
    dateStart = "",
    dateEnd = "",
    kitSkuNumber = "",
    categoryId = "",
    collection = "",
    color = "",
    factoryId = "",
    groupId = "",
    materialId = "",
    name = "",
    officeId = "",
    skuNumber = "",
    subgroupId = "",
    textureId = "",
  } = filters;

  return {
    brandManagerId,
    managerId,
    status,
    dateStart,
    dateEnd,
    kitSkuNumber,

    categoryId,
    collection,
    color,
    factoryId,
    groupId,
    materialId,
    name,
    officeId,
    skuNumber,
    subgroupId,
    textureId,
  };
}

import KitForm, {
  KitCreateForm,
  KitDublicateForm,
  KitUpdateForm,
  KitViewForm,
} from "../KitsWithSamplesList/tabs/KitForm";
import SampleForm, {
  KitSampleCreateForm,
  KitSampleDublicateForm,
  KitSampleUpdateForm,
  KitSampleViewForm,
} from "../KitsWithSamplesList/tabs/SampleForm";
import {TYPE_FORMS} from "../SamplesList/SamplesList";

export const componentFactory = {
  // [TYPE_FORMS.CREATE_INSTANCE]: SampleCreateModal,
  // [TYPE_FORMS.UPDATE_INSTANCE]: SampleUpdateModal,
  [TYPE_FORMS.CREATE_KIT]: KitCreateForm, //KitSampleCreateModal,
  [TYPE_FORMS.UPDATE_KIT]: KitUpdateForm, //KitSampleUpdateModal,
  [TYPE_FORMS.VIEW_KIT]: KitViewForm, //KitForm, //ViewKitTab,
  [TYPE_FORMS.DUBLICATE_KIT]: KitDublicateForm,

  [TYPE_FORMS.VIEW_KIT_SAMPLE]: KitSampleViewForm, //SampleForm,
  [TYPE_FORMS.CREATE_KIT_SAMPLE]: KitSampleCreateForm,
  [TYPE_FORMS.UPDATE_KIT_SAMPLE]: KitSampleUpdateForm,
  [TYPE_FORMS.DUBLICATE_KIT_SAMPLE]: KitSampleDublicateForm,
  // [TYPE_FORMS.CREATE_SAMPLE]: CreateSampleTab,
  // [TYPE_FORMS.EDIT_SAMPLE]: EditSampleTab,
  // [TYPE_FORMS.VIEW_SAMPLE]: ViewSampleTab,
  // [TYPE_FORMS.TRANSFER_SAMPLE_TO_KIT]: TransferSampleToKitTab,
};

import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

import CreateKitSamplesTab from "../KitsList/tabs/CreateKitSamplesTab.js/CreateKitSamplesTab";
import CreateKitTab from "../KitsList/tabs/CreateKitTab/CreateKitTab";
import EditKitSamplesTab from "../KitsList/tabs/EditKitSamplesTab/EditKitSamplesTab";
import EditKitTab from "../KitsList/tabs/EditKitTab/EditKitTab";
import ViewKitSamplesTab from "../KitsList/tabs/ViewKitSamplesTab/ViewKitSamplesTab";
import KitSampleCreateModal from "../SamplesList/SampleModals/KitSampleCreateModal";
import KitSampleUpdateModal from "../SamplesList/SampleModals/KitSampleUpdateModal";
import SampleCreateModal from "../SamplesList/SampleModals/SampleCreateModal";
import SampleUpdateModal from "../SamplesList/SampleModals/SampleUpdateModal";
import ViewKitTab from "../SamplesList/SampleModals/ViewKitTab";
import ViewSampleTab from "../SamplesList/SampleModals/ViewSampleTab";
import {TYPE_FORMS} from "../SamplesList/SamplesList";
import CreateSampleTab from "../SamplesListNew/tabs/CreateSampleTab/CreateSampleTab";
import DublicateSampleTab from "../SamplesListNew/tabs/DublicateSampleTab/DublicateSampleTab";
import EditSampleTab from "../SamplesListNew/tabs/EditSampleTab/EditSampleTab";
import TransferSampleToKitTab from "../SamplesListNew/tabs/TransferSampleToKitTab/TransferSampleToKitTab";

export const componentFactoryS = {
  [TYPE_FORMS.CREATE_INSTANCE]: SampleCreateModal,
  [TYPE_FORMS.UPDATE_INSTANCE]: SampleUpdateModal,
  [TYPE_FORMS.CREATE_KIT]: KitSampleCreateModal,
  [TYPE_FORMS.UPDATE_KIT]: KitSampleUpdateModal,
  [TYPE_FORMS.VIEW_KIT]: ViewKitTab,
  [TYPE_FORMS.VIEW_INSTANCE]: ViewSampleTab,
  [TYPE_FORMS.CREATE_SAMPLE]: CreateSampleTab,
  [TYPE_FORMS.EDIT_SAMPLE]: EditSampleTab,
  [TYPE_FORMS.VIEW_SAMPLE]: ViewSampleTab,
  [TYPE_FORMS.TRANSFER_SAMPLE_TO_KIT]: TransferSampleToKitTab,
};

export const labelFactoryS = {
  [TYPE_FORMS.CREATE_INSTANCE]: "create s",
  [TYPE_FORMS.UPDATE_INSTANCE]: "edit s",
  [TYPE_FORMS.CREATE_KIT]: "create k",
  [TYPE_FORMS.UPDATE_KIT]: "edit k",
  [TYPE_FORMS.VIEW_KIT]: "view k",
  [TYPE_FORMS.VIEW_INSTANCE]: "view s",
  [TYPE_FORMS.CREATE_SAMPLE]: "create s",
  [TYPE_FORMS.EDIT_SAMPLE]: "edit s",
  [TYPE_FORMS.VIEW_SAMPLE]: "view s",
  [TYPE_FORMS.TRANSFER_SAMPLE_TO_KIT]: "transfer s",
};

const useDynamicPageContainer = ({
  setTabs,
  setTabValue,
  componentFactory = componentFactoryS,
  labelFactory = labelFactoryS,
}) => {
  const {t} = useTranslation();

  const [formType, setFormType] = useState(null);
  const [tabProps, setTabProps] = useState({});
  const [tabLabel, setTabLabel] = useState("");

  const tabComponent = useMemo(() => {
    switch (formType) {
      case TYPE_FORMS.CREATE_KIT:
        return CreateKitTab;
      case TYPE_FORMS.UPDATE_KIT:
        return EditKitTab;
      case TYPE_FORMS.VIEW_KIT:
        return ViewKitTab;
      case TYPE_FORMS.CREATE_KIT_SAMPLES:
        return CreateKitSamplesTab;
      case TYPE_FORMS.EDIT_KIT_SAMPLES:
        return EditKitSamplesTab;
      case TYPE_FORMS.VIEW_KIT_SAMPLES:
        return ViewKitSamplesTab;

      case TYPE_FORMS.CREATE_SAMPLE:
        return CreateSampleTab;
      case TYPE_FORMS.EDIT_SAMPLE:
        return EditSampleTab;
      case TYPE_FORMS.VIEW_SAMPLE:
        return ViewSampleTab;
      case TYPE_FORMS.TRANSFER_SAMPLE_TO_KIT:
        return TransferSampleToKitTab;
      case TYPE_FORMS.DUBLICATE_SAMPLE:
        return DublicateSampleTab;
      default:
        null;
    }
  }, [formType]);

  const label = useMemo(() => {
    switch (formType) {
      case TYPE_FORMS.CREATE_KIT:
        return "create k";
      case TYPE_FORMS.UPDATE_KIT:
        return "edit k";
      case TYPE_FORMS.VIEW_KIT:
        return "view k";
      case TYPE_FORMS.CREATE_KIT_SAMPLES:
        return "create k s";
      case TYPE_FORMS.EDIT_KIT_SAMPLES:
        return "edit k s";
      case TYPE_FORMS.VIEW_KIT_SAMPLES:
        return "view k s";

      case TYPE_FORMS.CREATE_SAMPLE:
        return "create s";
      case TYPE_FORMS.EDIT_SAMPLE:
        return "edit s";
      case TYPE_FORMS.VIEW_SAMPLE:
        return "view s";
      case TYPE_FORMS.TRANSFER_SAMPLE_TO_KIT:
        return "transfer s";
    }
  }, [formType]);

  useEffect(() => {
    if (tabComponent) {
      setTabValue(prev => ++prev);
      setTabs(prev => {
        setTabValue(prev.length);
        return [
          ...prev,
          {
            Component: tabComponent,
            label: tabLabel ? t(tabLabel) : t(label),
            props: {setTabs, typeForm: formType, ...tabProps},
          },
        ];
      });
    }
  }, [tabComponent, label]);

  return {formType, setFormType, setTabProps, setTabLabel};
};

export default useDynamicPageContainer;

import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Divider} from "@mui/material";
import {Form, Formik} from "formik";

import {fetchData} from "../../../hook/axios.hook";
import ReadOnlyFields from "../../../utilities/ReadOnlyFields";
import {TextAreaInput, TextInput} from "../../Inputs";
import {formSampleTextInputValues} from "../../KitsList/constants";
import QRSampleModalPart from "../SampleUniModal/SampleUniModalParts/QRSampleModalPart";

const ViewSampleTab = ({sampleId}) => {
  const {t} = useTranslation();

  const [sample, setSample] = useState({});

  useEffect(() => {
    fetchData(`/samples/get/${sampleId}`, "get").then(resp => setSample(resp));
  }, [sampleId]);

  const initialValues = useMemo(() => {
    const {brandManager} = sample;
    return {
      ...formSampleTextInputValues(sample),
      brandManager: `${brandManager?.lastName ?? ""} ${brandManager?.firstName ?? ""}`,
    };
  }, [sample]);

  return (
    <div className="fcCol hw100 aic">
      <div className="g1 jcc mt">
        <Formik initialValues={initialValues} enableReinitialize onSubmit={() => {}}>
          <Form style={{paddingTop: "0.5em"}}>
            <div className="fc jcsb aibl">
              <div className="mb">{t("inst")}</div>
              <TextInput label={t("BM")} width="17em" name="brandManager" disabled />
            </div>
            <Divider />
            <div className="w">
              <ReadOnlyFields>
                <div className="part_width">
                  <div className="lower_selects">
                    <TextInput label="SKU №" name="skuNumber" labelStyles={{width: "4em"}} />
                    <TextInput label={t("Factory")} name="factoryId" generalClassName="lower_selects_item" />
                    <TextInput label={t("Smp g")} name="groupId" generalClassName="lower_selects_item" />
                    <TextInput label={t("sgr")} name="subgroupId" generalClassName="lower_selects_item" />
                    <TextInput label={t("material")} name="materialId" generalClassName="lower_selects_item" />
                    <TextInput label={t("categ")} name="categoryId" generalClassName="lower_selects_item" />
                    <TextInput label={t("texture")} name="textureId" generalClassName="lower_selects_item" />
                  </div>
                  <div className="fdr jcsb" style={{width: "100.5%"}}>
                    <TextAreaInput name="collection" placeholder={t("col") + ": "} width="50%" height="3em" />
                    <TextAreaInput name="color" placeholder={t("Color") + ": "} width="50%" height="3em" />
                  </div>
                  <TextAreaInput name="name" placeholder={t("samp nom") + ": " + t("1000")} width="101%" height="3em" />
                  <TextAreaInput
                    name="state"
                    placeholder={t("samp note") + ": " + t("1000")}
                    width="101%"
                    height="3em"
                  />
                </div>
              </ReadOnlyFields>
              <QRSampleModalPart readOnly={true} sample={sample} images={sample.images} />
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ViewSampleTab;

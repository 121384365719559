import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

// import uploadImg from "./Upload.png";
import cloud from "../../../../images/icons/download.png";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import ToolTip from "../../../../utilities/ToolTip";
import Modal from "../../../Modal/Modal";
import Spinner from "../../../spinner/Spinner";
import Crop from "../cropper/Crop";

import FileInput from "./fileInp";
import PdfPreviewItem from "./PdfPreviewItem";

const FilePreview = ({imgs, addFunc, delFunc, disable, accept, cropNeed = false, loading = false}) => {
  const {t} = useTranslation();

  const [modal, setModal] = useState(false);

  const [newCroppedImg, setNewCroppedImg] = useState(null);
  const [newImg, setnewImg] = useState(null);
  const [cropper, setCropper] = useState(false);

  const setNotCropped = files => {
    if (files) {
      setCropper(true);
      setnewImg(files);
    }
  };

  const demoAddF = fileList => {
    addFunc(fileList);
  };

  useEffect(() => {
    if (newCroppedImg) demoAddF(newCroppedImg);
  }, [newCroppedImg]);

  const handleDragOver = e => {
    e.preventDefault();
  };

  const handleDrop = e => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);
    cropNeed ? setNotCropped(droppedFiles) : addFunc(droppedFiles);
  };

  const files = useMemo(() => {
    return renderBoxes(imgs, delFunc);
  }, [imgs]);
  return (
    <>
      <ImageButton
        src={cloud}
        alt="upload"
        className="btn__upload pos"
        width={1.9}
        height={1.8}
        onClick={() => {
          setModal(true);
        }}
        disabled={disable}
      />

      <Modal active={modal} setActive={setModal}>
        {cropper && newImg ? (
          <div className="cropper_container" style={{width: "100%", height: "auto"}}>
            <Crop imgList={newImg} setOpenCrop={setCropper} setFile={setNewCroppedImg} />
          </div>
        ) : (
          <div className="imgPreview">
            <div className="previewBox" style={{position: "relative"}} onDragOver={handleDragOver} onDrop={handleDrop}>
              {loading ? (
                <Spinner />
              ) : (
                <>
                  {files}
                  <div className="previewBoxItem img-adding">
                    <ToolTip title={t("Add document")}>
                      <FileInput accept={accept} name="fileInput" addFunc={cropNeed ? setNotCropped : addFunc} />
                    </ToolTip>
                  </div>
                </>
              )}
            </div>

            <div className="imgPreview__buttons">
              <FunctionButton
                name={t("Close")}
                onClick={() => {
                  setModal(false);
                }}
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

function renderBoxes(fileList, delFunc) {
  return fileList.map((file, i) => {
    return (
      <div key={i}>
        <PdfPreviewItem url={file} delFunc={delFunc} />
      </div>
    );
  });
}

FilePreview.propTypes = {
  imgs: PropTypes.array,
  addFunc: PropTypes.func,
  delFunc: PropTypes.func,
  disable: PropTypes.bool,
  accept: PropTypes.string,
  cropNeed: PropTypes.bool,
  loading: PropTypes.bool,
};

export default FilePreview;

import {useCallback, useEffect, useRef, useState} from "react";
import {flexRender, getCoreRowModel, getSortedRowModel, useReactTable} from "@tanstack/react-table";
import {debounce} from "lodash";
import PropTypes from "prop-types";

import ArrowDown from "../../images/icons/arrowDown.png";
import ArrowUp from "../../images/icons/arrowUp.png";
import Spinner from "../spinner/Spinner";

import ClickableTr from "./ClickableTr";

import styles from "./ReactTable.module.scss";

const ReactTable = ({
  defaultData,
  defaultValue,
  columns,
  onSelect = () => {},
  current,
  className = "",
  onRowDoubleClick = null,
  tableClassName = "",
  loading = false,
  selectable = true,
  paginatable = false,
  onPaginate = () => {},
  handleContextMenu = null,
  selectOnRightBtn = false,
  ...props
}) => {
  const data = defaultData ?? [];

  //*** Table sorting ***/
  const SORTING_STATES = Object.freeze({ASC: "asc", DESC: "desc"});
  const [sorting, setSorting] = useState([]);
  //*** Table sorting ***/

  const [selectedRow, setSelectedRow] = useState(null);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: selectable,
    state: {sorting},
    onSortingChange: setSorting,
  });

  const tableBodyRef = useRef(null);

  const debouncedPagination = debounce(onPaginate, 1000);

  const handleScroll = () => {
    const {scrollTop, clientHeight, scrollHeight} = tableBodyRef.current;

    if (scrollTop + clientHeight > scrollHeight - 5) {
      debouncedPagination();
    }
  };

  const handleRowClick = row => {
    if (onSelect) onSelect(row.original.id);

    if (selectedRow?.original?.id === row.original.id) {
      setSelectedRow(null);
    } else {
      setSelectedRow(row);
    }
  };

  const handleRowDoubleClick = row => {
    onRowDoubleClick(row.original);
  };

  useEffect(() => {
    if (!current) setSelectedRow(null);
  }, [current]);

  useEffect(() => {
    if (defaultValue) {
      var selected = table.getRowModel().rows.find(row => row.original.id === defaultValue);
      if (selected) setSelectedRow(selected);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (current) {
      var selected = table.getRowModel().rows.find(row => row.original.id === current);
      if (selected) setSelectedRow(selected);
    }
  }, [data]);

  const tHeadRender = useCallback(() => {
    return table.getHeaderGroups().map(headerGroup => (
      <tr key={headerGroup.id}>
        {headerGroup.headers.map(header => (
          <th
            key={header.id}
            className={`${header.column.columnDef.isSortable ? styles.sortableHeader : ""}`}
            style={{
              position: "relative",
              width: header.getSize(),
            }}
            title={flexRender(header.column.columnDef.header, header.getContext())}
            onClick={header.column.columnDef.isSortable ? header.column.getToggleSortingHandler() : () => {}}
          >
            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
            {header.column.getIsSorted() === SORTING_STATES.ASC && (
              <img src={ArrowUp} width={10} height={10} style={{marginLeft: "5px"}} />
            )}
            {header.column.getIsSorted() === SORTING_STATES.DESC && (
              <img src={ArrowDown} width={10} height={10} style={{marginLeft: "5px"}} />
            )}
          </th>
        ))}
      </tr>
    ));
  });

  const tbodyRender = () => {
    return table.getRowModel().rows.map(row => {
      return (
        <ClickableTr
          key={row.original.id}
          onClick={() => {
            if (selectable) {
              handleRowClick(row);
            }
          }}
          onDoubleClick={() => {
            if (onRowDoubleClick) handleRowDoubleClick(row);
          }}
          onContextMenu={e => {
            if (selectable && selectOnRightBtn && selectedRow?.original?.id !== row.original.id) {
              handleRowClick(row);
            }

            if (handleContextMenu) {
              handleContextMenu(e, row.original.id);
            }
          }}
          className={styles.reactTableRow}
        >
          {row.getVisibleCells().map(cell => {
            return (
              <td
                key={cell.id}
                title={cell.getValue()}
                style={{
                  cursor: selectable ? "pointer" : "default",
                  minWidth: cell.column.minWidth,
                  backgroundColor: row?.original?.id === selectedRow?.original?.id ? "#567db8" : "transparent",
                  color: selectable && row?.original?.id === current && "white",
                }}
                onDoubleClick={e => {
                  e.preventDefault();
                }}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </ClickableTr>
      );
    });
  };

  return (
    <>
      {loading && <Spinner />}
      <div
        className={`project_list ${className}`}
        style={{overflowY: "auto", ...props.style}}
        onScroll={paginatable ? handleScroll : () => {}}
        ref={tableBodyRef}
      >
        {/* <Spinner style={{position: "sticky", top: "50%", left: "50%", transform: " translate(-50%, -50%)"}} /> */}
        <table className={`project_table ${tableClassName}`}>
          <thead>{tHeadRender()}</thead>
          <tbody>{tbodyRender()}</tbody>
        </table>
      </div>
    </>
  );
};

ReactTable.propTypes = {
  defaultData: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
  // current: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.oneOf([null])]),
  className: PropTypes.string,
  onRowDoubleClick: PropTypes.func,
  props: PropTypes.array,
};

export default ReactTable;

import {useFormikContext} from "formik";

import useColumns from "../../../hook/useColumns";

import {KitsTransferListLowerTableColumns, KitsTransferListUpperTableColumns} from "./constants";

const useKitsTransferList = () => {
  const {values} = useFormikContext();
  console.log("values :", values);
  const actionOptions = [];

  const upperTableColumns = useColumns(KitsTransferListUpperTableColumns);
  const upperTableData = [];

  const lowerTableColumns = useColumns(KitsTransferListLowerTableColumns);
  const lowerTableData = [];

  function onExecuteActionBtnClick() {}

  return {upperTableColumns, upperTableData, lowerTableColumns, lowerTableData, actionOptions, onExecuteActionBtnClick};
};

export default useKitsTransferList;

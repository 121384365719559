import {useTranslation} from "react-i18next";

const useColumns = (columns = []) => {
  const {t} = useTranslation();
  const translatedColumns = columns.map(column => ({...column}));

  translatedColumns.forEach(
    column => (column.header = typeof column.header === "string" ? t(column.header) : column.header),
  );
  return translatedColumns;
};

export default useColumns;

import {useRef} from "react";

import plusImg from "./imgplus.png";

import "../../../../Styles/Buttons.scss";

const FileInput = props => {
  const {accept, addFunc} = props;
  const fileref = useRef();

  const getFileArray = fileList => {
    return Array.from(fileList);
  };

  const handleFileChange = event => {
    const arrFiles = getFileArray(event.target.files);
    const file = arrFiles.length ? arrFiles[0] : undefined;
    if (!file) return;

    addFunc(arrFiles);

    // if (Array.isArray(values)) {
    //   arrayHelper.replace(0, { file })
    // } else {
    //     arrFiles.forEach((file) => {
    //         // console.log(file)
    //         arrayHelper.push({ file })
    //     })
    // }
  };

  return (
    <div>
      <div className="btn__upload">
        <label
          onClick={e => {
            e.stopPropagation();
            fileref.current.click();
          }}
        >
          <img src={plusImg} alt="" width="80px" height="80px" draggable="false" />
        </label>

        <input
          ref={fileref}
          // {...props}
          name={`fileInput`}
          type={`file`}
          accept={accept}
          multiple={true}
          onChange={event => {
            handleFileChange(event, event.values);
          }}
          style={{display: "none"}}
        />
      </div>
    </div>
  );
};

export default FileInput;

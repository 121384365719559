import {dateFormat} from "../../../utilities/dateFormat";

const mngRegistrTableDataService = users =>
  users?.map(({userId, firstName, lastName, email, createdAt, role, status}, i) => {
    return {
      id: userId,
      number: i + 1,
      name: firstName,
      lastName: lastName,
      eMail: email,
      role: role?.roleName,
      regDate: dateFormat(createdAt),
      status: status.type.name,
      statusTerm: status?.validTo ? dateFormat(status?.validTo) : "",
    };
  }) || [];

export default mngRegistrTableDataService;

import {useEffect, useState} from "react";
import Cropper from "react-easy-crop";
import {useTranslation} from "react-i18next";
import {Box, DialogActions, DialogContent, Slider, Typography} from "@mui/material";
import PropTypes from "prop-types";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton.js";

import getCroppedImg from "./cropImg.js";

const Crop = ({
  imgList,
  // photoURL,
  setOpenCrop,
  // setPhotoURL,
  setFile,
  // imageName
}) => {
  const {t} = useTranslation();

  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const [currentImg, setCurrentImg] = useState(null);
  const [imgNumber, setImgNumber] = useState(0);
  const [croppedImagesList, setCroppedImgList] = useState([]);

  useEffect(() => {
    if (croppedImagesList.length > 0) {
      if (imgNumber < imgList.length - 1) {
        setImgNumber(num => (num = num + 1));
      } else {
        setFile(croppedImagesList);
        setOpenCrop(false);
      }
    }
  }, [croppedImagesList]);

  const cropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  useEffect(() => {
    if (imgList[imgNumber]) {
      // setCurrentImg(file[0].name)
      const reader = new FileReader();
      reader.readAsDataURL(imgList[imgNumber]);
      reader.onload = () => {
        setCurrentImg(reader.result);
      };
    }
  }, [imgList, imgNumber]);

  const cropImage = async () => {
    try {
      const {file, url} = await getCroppedImg(imgList[imgNumber].name, currentImg, croppedAreaPixels);
      setCroppedImgList(list => {
        return [...list, file];
      });
    } catch (error) {
      // алерт
      // console.log(error);
    }
  };
  return (
    <>
      <DialogContent
        dividers
        sx={{
          background: "#ffffff",
          position: "relative",
          height: 500,
          width: "auto",
          minWidth: {sm: 600},
        }}
      >
        <Cropper
          image={currentImg}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={4 / 4}
          // cropSize={{width: 250, height: 250}}
          restrictPosition={false}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropChange={setCrop}
          onCropComplete={cropComplete}
          minZoom={0.4}
          zoomSpeed={0.1}
          objectFit={"vertical-cover"}
        />
      </DialogContent>
      <DialogActions sx={{flexDirection: "column", mx: 3, my: 2}}>
        <Box sx={{width: "100%", mb: 1, dispaly: "flex"}}>
          <Box>
            <Typography>Zoom: {zoomPercent(zoom)}</Typography>
            <Slider
              valueLabelDisplay="auto"
              valueLabelFormat={zoomPercent}
              min={0.4}
              max={3}
              step={0.1}
              value={zoom}
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </Box>
          {/* <Box>
            <Typography>Rotation: {rotation + "°"}</Typography>
            <Slider
              valueLabelDisplay="auto"
              min={0}
              max={360}
              value={rotation}
              onChange={(e, rotation) => setRotation(rotation)}
            />
          </Box> */}
        </Box>
        <div className="cropper_btns">
          <FunctionButton name={t("Cancel")} onClick={() => setOpenCrop(false)} />
          <FunctionButton name={t("Save")} onClick={cropImage} />
        </div>
      </DialogActions>
    </>
  );
};

Crop.propTypes = {
  imgList: PropTypes.array,
  setOpenCrop: PropTypes.func,
  setFile: PropTypes.func,
};

export default Crop;

const zoomPercent = value => {
  return `${Math.round(value * 100)}%`;
};

import {t} from "i18next";

import {NumberInput, TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";

import {deadlinesGridTemplate} from "./Deadlines";

const TermsActionItem = ({id, termName, placeholder}) => {
  const termsOptions = [
    {title: t("day"), value: "day"},
    {title: t("week"), value: "week"},
    {title: t("quarter"), value: "quarter"},
    {title: t("year"), value: "year"},
  ];

  return (
    <div className="dg w100 gap" style={{gridTemplate: deadlinesGridTemplate}}>
      <div className="bold">{t(termName)}</div>
      <NumberInput name={`${id}ActionNumber`} noFormat className="bordered" />
      <Select name={`${id}ActionTerm`} options={termsOptions} width="8em" />
      <TextInput
        name={`${id}ActionDescription`}
        placeholder={t(placeholder)}
        generalClassName="fGrow"
        inputClassName="fGrow"
        width="100%"
      />
    </div>
  );
};

export default TermsActionItem;

import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Form, Formik} from "formik";

import useColumns from "../../../hook/useColumns";
import {selectAllKits, selectAllSamples} from "../../../slices/SampleFunctionalSlice";
import {selectCurrentKitId} from "../../../slices/SampleSlice/selectors";
import ReadOnlyFields from "../../../utilities/ReadOnlyFields";
import ReactTable from "../../conteiners/ReactTable";
import {TextAreaInput, TextInput} from "../../Inputs";
import {SampleUniModTableColumns} from "../constants/columns";
import samplesForKitModalDataService from "../constants/samplesForKitModalDataService";
import QRSampleModalPart from "../SampleUniModal/SampleUniModalParts/QRSampleModalPart";

const ViewKitTab = ({kitId}) => {
  const {t} = useTranslation();
  const isCurrentKit = Boolean(useSelector(selectCurrentKitId));
  const allSamples = useSelector(selectAllSamples);

  const allKits = useSelector(selectAllKits);
  const kit = allKits?.find(kit => kit.kitId === kitId);

  const instanceListForKit = useMemo(
    () => samplesForKitModalDataService(isCurrentKit ? allSamples : kit?.samples),
    [kit?.samples?.length, isCurrentKit, allSamples?.length],
  );
  const uniTableColumns = useColumns(SampleUniModTableColumns);

  const initialValues = useMemo(() => {
    return {
      kitSku: kit?.skuNumber,
      setDescArea: kit?.name,
    };
  }, [kit]);

  return (
    <div className="fcCol hw100">
      <div className="w g1 jcc h100">
        <Formik initialValues={initialValues} enableReinitialize onSubmit={() => {}}>
          <Form className="fcCol h100" style={{paddingTop: "0.5em", height: "100%", width: "100%", overflow: "auto"}}>
            <ReadOnlyFields>
              <div className="fdr mt mb">
                <div className="flex_form part_width jcsb ">
                  <TextInput label="kitSKU № *" name="kitSku" labelStyles={{width: "4em"}} />

                  <TextAreaInput
                    label=""
                    name="setDescArea"
                    width="101%"
                    placeholder={t("samp nom") + "*" + ": " + t("1000")}
                  />
                </div>

                <QRSampleModalPart readOnly={true} kit={kit} images={kit?.images} />
              </div>
            </ReadOnlyFields>
            <div className="border border_gap"></div>

            {/* <ReactTable
              defaultData={instanceListForKit}
              columns={uniTableColumns}
              className={"scrollX fGrow mb"}
              style={{maxHeight: "unset"}}
              selectable={false}
            /> */}
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ViewKitTab;

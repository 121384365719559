import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";

import useColumns from "../../../hook/useColumns";
import ReadOnlyFields from "../../../utilities/ReadOnlyFields";
import ReactTable from "../../conteiners/ReactTable";
import {Select, TextAreaInput, TextInput} from "../../Inputs";
import {SampleUniModTableColumns} from "../constants/columns";
import samplesForKitModalDataService from "../constants/samplesForKitModalDataService";
import QRSampleModalPart from "../SampleUniModal/SampleUniModalParts/QRSampleModalPart";

const KitPreview = ({kit}) => {
  const {t} = useTranslation();

  const instanceListForKit = useMemo(() => samplesForKitModalDataService(kit?.samples), [kit]);
  const uniTableColumns = useColumns(SampleUniModTableColumns);

  const initialValues = useMemo(() => {
    return {
      kitSku: kit?.skuNumber,
      setDescArea: kit?.name,
    };
  }, [kit]);

  return (
    <>
      <div className="fc hw100 aic">
        <div className="w g1 jcc">
          <Formik initialValues={initialValues} enableReinitialize onSubmit={() => {}}>
            <Form style={{paddingTop: "0.5em", height: "100%", width: "100%"}}>
              <ReadOnlyFields>
                <div className="fdr">
                  <div className="flex_form part_width jcsb ">
                    <TextInput label="kitSKU № *" name="kitSku" labelStyles={{width: "4em"}} />

                    <TextAreaInput
                      label=""
                      name="setDescArea"
                      width="101%"
                      placeholder={t("samp nom") + "*" + ": " + t("1000")}
                    />
                  </div>

                  <QRSampleModalPart readOnly={true} kit={kit} images={kit?.images} qrCodeUrl={kit.qrCodeUrl} />
                </div>
              </ReadOnlyFields>
              <div className="border border_gap"></div>
              <div>
                <ReactTable
                  defaultData={instanceListForKit}
                  columns={uniTableColumns}
                  className={"scrollX"}
                  style={{height: "15em"}}
                  selectable={false}
                />
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
};

export default KitPreview;

export default function makeSamplesFilters(filters) {
  if (!filters) return {};
  const samplesFilters = {};

  Object.keys(filters).forEach(filterKey => {
    if (filterKey === "kitSkuNumber") return;

    samplesFilters[filterKey] = typeof filters[filterKey] === "string" ? filters[filterKey].trim() : filters[filterKey];
  });

  return samplesFilters;
}

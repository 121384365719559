import {buttonClasses} from "@mui/base/Button";
import {Tab as BaseTab, tabClasses} from "@mui/base/Tab";
import {TabPanel as BaseTabPanel} from "@mui/base/TabPanel";
import {TabsList as BaseTabsList} from "@mui/base/TabsList";
import {styled} from "@mui/system";

export const Tab = styled(BaseTab)`
  display: flex;
  justify-content: space-between;
  overflow-x: hidden;
  width: 100%;
  max-width: 20%;
  min-width: fit-content;
  border: 0;
  border-radius: 1px 25px 1px 0px;
  line-height: 1.5em;
  white-space: nowrap;
  font-size: 1.1em;
  font-weight: 400;
  background-color: #a3b9d9;
  padding: 0 0.9em 0 1.1em;

  &:focus {
    outline: null;
  }

  &.${tabClasses.selected} {
    background-color: #f5f5f5;
    opacity: 1;
    color: #567db8;
    border: 1px solid #a3b9d9;
    border-bottom: 0;
    overflow-x: visible;
  }

  &.${buttonClasses.disabled} {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const TabPanel = styled(BaseTabPanel)`
  min-width: 1012px;
`;

export const TabsList = styled(BaseTabsList)`
  position: absolute;
  display: flex;
  top: -1.7em;
  left: -6px;
  max-width: 100%;
  overflow-x: auto;
  font-size: 1.1em;
  font-weight: 400;
  padding: 0 0;
  line-height: 1.5em;
  border-left: 5px solid #a3b9d9;

  ::-webkit-scrollbar {
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #426ba6;
  }
  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
  }
`;

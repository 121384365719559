import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {Tabs} from "@mui/base/Tabs";
import PropTypes from "prop-types";

import useSwal from "../../utilities/hooks/useSwal";
import ToolTip from "../../utilities/ToolTip";

import {Tab, TabPanel, TabsList} from "./styledTabs";

const DynamicPageContainer = ({
  Component,
  title = "",
  closableTabs = true,
  tabClassName = "",
  onCloseTab = () => {},
  closeOnEsc = false,
}) => {
  const {t} = useTranslation();
  const SWAL = useSwal();

  const [tabValue, setTabValue] = useState(0);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    setTabs([{Component, label: t(title), props: {tabs, setTabs, setTabValue}}]);
  }, [Component]);

  useEffect(() => {
    if (closableTabs && closeOnEsc) {
      window.addEventListener("keydown", handleEscapeKey);
    }

    return () => {
      if (closableTabs && closeOnEsc) {
        window.removeEventListener("keydown", handleEscapeKey);
      }
    };
  }, [tabValue]);

  const tabsButtons = tabs.map((tab, index) => {
    return (
      <ToolTip key={index} title={index !== 0 && closableTabs && closeOnEsc ? 'Press "Esc" to close' : ""}>
        <Tab value={index} index={index} style={{cursor: "pointer"}}>
          {tab?.label ?? "No label"}
          {closableTabs && tabs.length > 1 && index !== 0 && (
            <span
              style={{marginLeft: "1em", cursor: "pointer"}}
              onClick={e => {
                e.preventDefault();
                onTabClose(index);
              }}
            >
              &#215;
            </span>
          )}
        </Tab>
      </ToolTip>
    );
  });

  const tabsRender = useMemo(
    () =>
      tabs.map((tab, index) => (
        <TabPanel key={index} value={index} className={`fcCol hmax100 fGrow ${index !== tabValue ? "hiddenEl" : ""}`}>
          <tab.Component setTabValue={setTabValue} {...tab.props} />
        </TabPanel>
      )),
    [tabs, tabValue],
  );

  function handleEscapeKey(event) {
    if (event.key === "Escape" && closableTabs && tabValue !== 0) {
      event.preventDefault();
      onTabClose(tabValue);
    }
  }

  function onClose({index, beingClosedTab}) {
    setTabs(prevTabs => {
      onCloseTab(beingClosedTab);
      if (index <= tabValue && tabValue !== 0) {
        setTabValue(tabValue - 1);
      }
      return prevTabs.filter((tab, tabIndex) => tabIndex !== index);
    });
  }

  function onTabClose(index) {
    const beingClosedTab = tabs.find((tab, tabIndex) => tabIndex === index);
    const wasTabDataChanged = beingClosedTab.props.wasTabDataChanged;

    if (wasTabDataChanged) {
      SWAL.confirm({
        title: "Close the tab?",
        text: "You have unsaved data",
        confirmFunc: () => {
          onClose({index, beingClosedTab});
        },
      });
    } else {
      onClose({index, beingClosedTab});
    }
  }

  function handleTabChange(event, newValue) {
    setTabValue(newValue);
  }

  return (
    <div className="limitedSize">
      <Tabs className="h100 fcCol fGrow" value={tabValue} onChange={handleTabChange}>
        <div>
          <TabsList>{tabsButtons}</TabsList>
        </div>
        <div className="page_container fcCol">
          <div className="overflow_form_modal hmax100 fGrow">
            <div className={`${tabClassName} tabmodal_form__child h100 hmax100 fcCol`}>{tabsRender}</div>
          </div>
        </div>
      </Tabs>
    </div>
  );
};

DynamicPageContainer.propTypes = {
  Component: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  closableTabs: PropTypes.bool,
  tabClassName: PropTypes.string,
  onCloseTab: PropTypes.func,
  closeOnEsc: PropTypes.bool,
};
export default DynamicPageContainer;

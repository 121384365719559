import {Form, Formik} from "formik";
import * as Yup from "yup";

import PageContainer from "../../../conteiners/PageContainer";
import GuideCard from "../../SampleParts/GuideCard";
import GuideSelectCard from "../../SampleParts/GuideSelectCard";

import useSampleGuideModal from "./useSampleGuideModal";

const SampleGuideModal = () => {
  const {
    initialValues,
    sGroupColumns,
    subGrColumns,
    matColumns,
    catColumns,
    texColumns,
    groupsTableData,
    subGroupsTableData,
    materialsTableData,
    categoriesTableData,
    onGroupsTableRowSelect,
    onSubGroupsTableRowSelect,
    onMaterialsTableRowSelect,
    onCategoriesTableRowSelect,
    texturesTableData,
    onTexturesTableRowSelect,
    onAddGroupBtnClick,
    onDeleteGroupBtnClick,
    onAddSubGroupBtnClick,
    onDeleteSubGroupBtnClick,
    onAddMaterialBtnClick,
    onDeleteMaterialBtnClick,
    onAddCategoryBtnClick,
    onDeleteCategoryBtnClick,
    onAddTextureBtnClick,
    onDeleteTextureBtnClick,
    currentGroupId,
    currentMaterialId,
    brandManagersColumns,
    brandManagersTableData,
    onBrandManagersTableRowSelect,
    brdManagerFactoriesColumns,
    brdManagerFactoriesTableData,
    onbrdManagerFactoriesTableRowSelect,
    onAddBrdManagerFactoriesBtnClick,
    onDeleteBrdManagerFactoriesBtnClick,
    factorySelectOptions,
    selectedFactoryId,
  } = useSampleGuideModal();

  return (
    <PageContainer title="Samples Guides">
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({})}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize
        onSubmit={() => {}}
      >
        {({values}) => (
          <Form className="fcCol" style={{height: "100%", paddingTop: "5px"}}>
            <div className="fdr fGrow" style={{height: "22em"}}>
              <div className="part_width fdr gap jcsb">
                <GuideCard
                  label="Smp g"
                  fieldName="group"
                  columns={sGroupColumns}
                  tableData={groupsTableData}
                  onTableRowSelect={onGroupsTableRowSelect}
                  onAddBtnClick={() => onAddGroupBtnClick(values)}
                  onDeleteBtnClick={onDeleteGroupBtnClick}
                  current={currentGroupId}
                />
                <GuideCard
                  label="sgr"
                  fieldName="subGroup"
                  columns={subGrColumns}
                  tableData={subGroupsTableData}
                  onTableRowSelect={onSubGroupsTableRowSelect}
                  onAddBtnClick={() => onAddSubGroupBtnClick(values)}
                  onDeleteBtnClick={onDeleteSubGroupBtnClick}
                  current={null}
                />
              </div>
              <div className="fdr gap jcsb" style={{width: "50%"}}>
                <GuideCard
                  label="material"
                  fieldName="material"
                  columns={matColumns}
                  tableData={materialsTableData}
                  onTableRowSelect={onMaterialsTableRowSelect}
                  onAddBtnClick={() => onAddMaterialBtnClick(values)}
                  onDeleteBtnClick={onDeleteMaterialBtnClick}
                  current={currentMaterialId}
                />
                <GuideCard
                  label="categ"
                  fieldName="category"
                  columns={catColumns}
                  tableData={categoriesTableData}
                  onTableRowSelect={onCategoriesTableRowSelect}
                  onAddBtnClick={() => onAddCategoryBtnClick(values)}
                  onDeleteBtnClick={onDeleteCategoryBtnClick}
                  current={null}
                />
              </div>
            </div>

            <div className="border border_gap"></div>
            <div className="fdr fGrow pb" style={{height: "22em"}}>
              <div className="part_width">
                <GuideCard
                  label="texture"
                  fieldName="texture"
                  columns={texColumns}
                  tableData={texturesTableData}
                  onTableRowSelect={onTexturesTableRowSelect}
                  onAddBtnClick={() => onAddTextureBtnClick(values)}
                  onDeleteBtnClick={onDeleteTextureBtnClick}
                  current={null}
                />
              </div>
              <div className="fdr gap jcsb" style={{width: "50%"}}>
                <GuideCard
                  label="Brand Manager"
                  fieldName="brandManager"
                  columns={brandManagersColumns}
                  tableData={brandManagersTableData}
                  onTableRowSelect={onBrandManagersTableRowSelect}
                  current={null}
                  noButtons
                />
                <GuideSelectCard
                  selectOptions={factorySelectOptions}
                  label="Factory"
                  fieldName="factoryId"
                  columns={brdManagerFactoriesColumns}
                  tableData={brdManagerFactoriesTableData}
                  onTableRowSelect={onbrdManagerFactoriesTableRowSelect}
                  onAddBtnClick={() => onAddBrdManagerFactoriesBtnClick(values)}
                  onDeleteBtnClick={() => onDeleteBrdManagerFactoriesBtnClick(values)}
                  current={selectedFactoryId}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </PageContainer>
  );
};

export default SampleGuideModal;

import {useEffect, useState} from "react";
import PropTypes from "prop-types";

import CloseBtn from "../../utilities/Buttons/CloseBtn";

const ModalForm = ({
  label,
  active,
  setModalActive,
  Component,
  onModalClose = () => {},
  closable = false,
  className = "",
  modalInnerWrapperClassName = "",
  typeForm,
  ...props
}) => {
  const [couldClose, setCouldClose] = useState(true);

  const handleEscapeKey = event => {
    if (event.key === "Escape" && closable) {
      setModalActive(false);
      if (closable) onModalClose();
    }
  };

  useEffect(() => {
    if (closable) {
      window.addEventListener("keydown", handleEscapeKey);
    }

    return () => {
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  const removeFocusFromButton = isActive => {
    if (isActive) {
      const openButton = document.activeElement;
      if (openButton && openButton.blur) {
        openButton.blur(); // remove focus on button when modal was closed by Escape
      }
    }
  };

  useEffect(() => {
    removeFocusFromButton(active);
  }, [active]);

  return (
    <>
      {active ? (
        <div className="modal-form__container ">
          <div className={`${className} modal_form`}>
            <label className="label modal_form_label">{label}</label>

            {closable && (
              <CloseBtn
                onClick={() => {
                  setModalActive(false);
                  if (closable) onModalClose();
                }}
              />
            )}

            <div
              className="overflow_form_modal"
              // style={{width: "100%", height: "100%", overflow: "auto", padding: "0 20px 20px 20px"}}
            >
              <div className={`${modalInnerWrapperClassName} modal_form__child`}>
                <Component
                  setModalActive={setModalActive}
                  active={active}
                  setCouldModalClose={setCouldClose}
                  onModalClose={onModalClose}
                  typeForm={typeForm}
                  {...props}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

ModalForm.propTypes = {
  label: PropTypes.string,
  active: PropTypes.bool,
  setModalActive: PropTypes.func,
  Component: PropTypes.func,
  onModalClose: PropTypes.func,
  closable: PropTypes.bool,
  modalInnerWrapperClassName: PropTypes.string,
  className: PropTypes.string,
  props: PropTypes.array,
};

export default ModalForm;

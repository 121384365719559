import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik, useFormikContext} from "formik";
import * as Yup from "yup";

import {
  addNewKitImages,
  addNewSampleImages,
  deleteKitImage,
  deleteSampleImage,
  selectAllKits,
  selectKit,
  selectKitImages,
  selectNewKit,
  selectNewSample,
  selectSample,
  selectSampleImages,
  // selectSampleImages,
} from "../../../slices/SampleFunctionalSlice";
import {SAMPLES_STATE_NAME, updateModalData} from "../../../slices/SampleSlice/SampleSlice";
import {TYPE_FORMS} from "../SamplesList";

import InstanceModalPart from "./SampleUniModalParts/InstanceModalPart";
import KitSampleTableModalPart from "./SampleUniModalParts/KitSampleTableModalPart";
import QRSampleModalPart from "./SampleUniModalParts/QRSampleModalPart";
import SetModalPart from "./SampleUniModalParts/SetModalPart";

import "./uniModal.scss";

const SampleUniModBase = ({children, complectChecked, typeForm}) => {
  const dispatch = useDispatch();
  const {values, setValues} = useFormikContext();
  const isCreate = typeForm.startsWith("CREATE");

  const [currentKitSample, setCurrentKitSample] = useState(null);

  const allKits = useSelector(selectAllKits);
  const sample = useSelector(selectSample);
  const newSample = useSelector(selectNewSample);
  const savedCreateSample = useSelector(state => state.samples.modalData[TYPE_FORMS.CREATE_INSTANCE]);

  // const chosenSample = useSelector(state => state.samples.modalData[typeForm].sample);
  const chosenTableSample = useSelector(state => state.samples.modalData[typeForm]?.sample);

  const savedEditTabSample = useSelector(state => state.samples.modalData[TYPE_FORMS.UPDATE_INSTANCE]);

  const kit = useSelector(selectKit);
  const savedCreateTabKit = useSelector(state => state.samples.modalData[TYPE_FORMS.CREATE_KIT]);
  const savedEditTabKit = useSelector(state => state.samples.modalData[TYPE_FORMS.UPDATE_KIT]);
  const newKit = useSelector(selectNewKit);
  const kitModalDataImages = useSelector(state => state.samples.modalData[typeForm]?.kitImages) ?? [];
  const kitInSampleEdit = allKits.find(kit => kit.kitId === sample?.kitId);
  const kitSampleEditImages = kitInSampleEdit?.images;
  const instanceCreateImgs = useSelector(state => state.samples.modalData[typeForm]?.sampleImages) ?? [];
  const instanceEditImgs = useSelector(selectSampleImages);

  // const kitImages = isCreate ? kitCreateImages : kitEditImages;
  const kitImages = typeForm === TYPE_FORMS.UPDATE_INSTANCE ? kitSampleEditImages : kitModalDataImages;
  const instanceImages = isCreate
    ? instanceCreateImgs
    : typeForm === TYPE_FORMS.UPDATE_KIT
    ? chosenTableSample?.images ?? []
    : instanceEditImgs;

  const initialValues = useSelector(state => state[SAMPLES_STATE_NAME].modalData[typeForm]) ?? {
    factoryId: "",
    kitSku: "",
    setDescArea: "",
    skuNumber: "",
    groupId: "",
    subgroupId: "",
    materialId: "",
    categoryId: "",
    textureId: "",
    collection: "",
    color: "",
    name: "",
    state: "",
  };

  const initial = useMemo(() => {
    switch (typeForm) {
      case "CREATE_INSTANCE":
        return {
          ...initialValues,
          ...savedCreateSample,
          ...(newSample || {}),
        };

      case "UPDATE_INSTANCE": {
        const isKit = sample?.kit
          ? {kitSku: sample?.kit?.skuNumber, setDescArea: sample?.kit?.name}
          : {kitSku: "", setDescArea: ""};

        return {
          ...isKit,
          ...(savedEditTabSample?.sampleId ? savedEditTabSample : sample ?? {}),
        };
      }

      case "CREATE_KIT": {
        // const initForUpdateKit = sample ? {...sample} : {...initialValues};
        // const initForCreateKit = kit
        //   ? {kitSku: kit?.skuNumber, setDescArea: kit?.name}
        //   : {kitSku: initialValues.kitSku ?? "", setDescArea: initialValues.setDescArea ?? ""};

        return {
          // ...initForUpdateKit,
          ...initialValues,
          kitSku: savedCreateTabKit?.kitSku || newKit?.skuNumber,
          setDescArea: savedCreateTabKit?.setDescArea || newKit?.name,
          factoryId: savedCreateTabKit?.factoryId || chosenTableSample?.factoryId || "",
          skuNumber: savedCreateTabKit?.skuNumber || chosenTableSample?.skuNumber || "",
          groupId: savedCreateTabKit?.groupId || chosenTableSample?.groupId || "",
          subgroupId: savedCreateTabKit?.subgroupId || chosenTableSample?.subgroupId || "",
          materialId: savedCreateTabKit?.materialId || chosenTableSample?.materialId || "",
          categoryId: savedCreateTabKit?.categoryId || chosenTableSample?.categoryId || "",
          textureId: savedCreateTabKit?.textureId || chosenTableSample?.textureId || "",
          collection: savedCreateTabKit?.collection || chosenTableSample?.collection || "",
          color: savedCreateTabKit?.color || chosenTableSample?.color || "",
          name: savedCreateTabKit?.name || chosenTableSample?.name || "",
          state: savedCreateTabKit?.state || chosenTableSample?.state || "",
          // ...initForCreateKit,
        };
      }
      case "UPDATE_KIT": {
        // const initForUpdateKit = chosenSample ? {...chosenSample} : {...initialValues};
        // console.log("initForUpdateKit :", initForUpdateKit);
        return {
          // ...(savedEditTabKit ? savedEditTabKit : {}),
          // kitSku: kit?.skuNumber,
          // setDescArea: kit?.name,

          kitSku: savedEditTabKit?.kitSku || kit?.skuNumber,
          setDescArea: savedEditTabKit?.setDescArea || kit?.name,

          factoryId: savedEditTabKit?.factoryId || chosenTableSample?.factoryId || values?.factoryId || "",
          skuNumber: savedEditTabKit?.skuNumber || chosenTableSample?.skuNumber || values?.skuNumber || "",
          groupId: savedEditTabKit?.groupId || chosenTableSample?.groupId || values?.groupId || "",
          subgroupId: savedEditTabKit?.subgroupId || chosenTableSample?.subgroupId || values?.subgroupId || "",
          materialId: savedEditTabKit?.materialId || chosenTableSample?.materialId || values?.materialId || "",
          categoryId: savedEditTabKit?.categoryId || chosenTableSample?.categoryId || values?.categoryId || "",
          textureId: savedEditTabKit?.textureId || chosenTableSample?.textureId || values?.textureId || "",
          collection: savedEditTabKit?.collection || chosenTableSample?.collection || values?.collection || "",
          color: savedEditTabKit?.color || chosenTableSample?.color || values?.color || "",
          name: savedEditTabKit?.name || chosenTableSample?.name || values?.name || "",
          state: savedEditTabKit?.state || chosenTableSample?.state || values?.state || "",
        };
      }
    }
  }, [typeForm, sample, initialValues, savedEditTabSample, chosenTableSample, savedCreateTabKit]);

  useEffect(() => {
    setValues(initial);
  }, [initial]);

  useEffect(() => {
    return () => {
      setValues(prev => {
        dispatch(
          updateModalData({
            typeForm,
            formData: prev,
          }),
        );
        return prev;
      });
    };
  }, []);

  return (
    <div className="h100">
      <div className="h100" style={{paddingTop: "1em"}}>
        <Form className="fcCol h100">
          <div className={`w ${complectChecked ? "" : "block_opacity"}`}>
            <SetModalPart utilits={children} complectChecked={complectChecked} typeForm={typeForm} />
            <QRSampleModalPart
              isCreate={isCreate}
              typeForm={typeForm}
              kit={isCreate ? newKit : typeForm === TYPE_FORMS.UPDATE_INSTANCE ? kitInSampleEdit : kit}
              images={kitImages}
              addMethod={addNewKitImages}
              deleteMethod={deleteKitImage}
            />
          </div>
          <div className="border border_gap"></div>

          <div className="w">
            <InstanceModalPart
              typeForm={typeForm}
              utilits={children}
              complectChecked={complectChecked}
              setCurrentKitSample={setCurrentKitSample}
            />
            <QRSampleModalPart
              typeForm={typeForm}
              isCreate={isCreate}
              sample={isCreate ? newSample : typeForm === TYPE_FORMS.UPDATE_KIT ? chosenTableSample : sample}
              images={instanceImages}
              addMethod={addNewSampleImages}
              deleteMethod={deleteSampleImage}
            />
          </div>
          <div className="border border_gap"></div>
          <KitSampleTableModalPart
            typeForm={typeForm}
            complectChecked={complectChecked}
            currentKitSample={currentKitSample}
            setCurrentKitSample={setCurrentKitSample}
          />
        </Form>
      </div>
    </div>
  );
};

export const SAMPLE_UNI_MOD_INITIAL_VALUES = Object.freeze({
  factoryId: "",
  kitSku: "",
  setDescArea: "",
  skuNumber: "",
  groupId: "",
  subgroupId: "",
  materialId: "",
  categoryId: "",
  textureId: "",
  collection: "",
  color: "",
  name: "",
  state: "",
  // kitImages: [],
  sampleImages: [],
  kitQrCodeUrl: "",
  sampleQrCodeUrl: "",
});

export const SAMPLE_UNI_MOD_INITIAL_INSTANCE_VALUES = Object.freeze({
  factoryId: "",
  skuNumber: "",
  groupId: "",
  subgroupId: "",
  materialId: "",
  categoryId: "",
  textureId: "",
  collection: "",
  color: "",
  name: "",
  state: "",
  sampleImages: [],
  sampleQrCodeUrl: "",
});

const SampleUniMod = ({children, complectChecked, typeForm}) => {
  const {t} = useTranslation();
  // const kit = useSelector(selectKit);
  const existingKit = useSelector(selectKit);
  const newKit = useSelector(selectNewKit);
  const kit = typeForm.startsWith("CREATE") ? newKit : existingKit;

  const validationSchema = Yup.object().shape({
    factoryId: Yup.number(t("Оберіть фабрику зі списку")).required(t("Оберіть фабрику зі списку")).nullable(),
    groupId: Yup.number().required(t("select gr")).nullable(),
    materialId: Yup.number().required(t("select mat")).nullable(),
    name: Yup.string().required(t("enter name")),
    kitSku: complectChecked && Yup.string(),
    setDescArea: complectChecked && Yup.string().required(t("enter name")),
  });

  return (
    <Formik
      initialValues={SAMPLE_UNI_MOD_INITIAL_VALUES}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={(values, {setFieldValue, setValues}) => {
        children[1].props.onClick(
          {
            ...values,
            kitId: !complectChecked ? null : kit.kitId,
            typeForm,
          },
          setFieldValue,
          setValues,
        );
      }}
    >
      <SampleUniModBase typeForm={typeForm} complectChecked={complectChecked}>
        {children}
      </SampleUniModBase>
    </Formik>
  );
};

export default SampleUniMod;

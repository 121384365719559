import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import Swal from "sweetalert2";

import useColumns from "../../../../hook/useColumns";
import useSelectOptions from "../../../../hook/useSelectOptions";
import {selectAllSamples} from "../../../../slices/SampleFunctionalSlice";
import {
  addCategory,
  addGroup,
  addMaterial,
  addSubGroup,
  addTexture,
  deleteCategory,
  deleteGroup,
  deleteMaterial,
  deleteSubGroup,
  deleteTexture,
  fetchAllCategories,
  fetchAllGroups,
  fetchAllMaterials,
  fetchAllSubGroups,
  fetchAllTextures,
  updateCategory,
  updateGroup,
  updateMaterial,
  updateSubGroup,
  updateTexture,
} from "../../../../slices/SampleSlice/operations";
import {
  setCurrentCategoryId,
  setCurrentGroupId,
  setCurrentMaterialId,
  setCurrentSubGroupId,
  setCurrentTextureId,
} from "../../../../slices/SampleSlice/SampleSlice";
import {
  selectCurrentCategory,
  selectCurrentCategoryId,
  selectCurrentGroup,
  selectCurrentGroupId,
  selectCurrentGroupSubGroups,
  selectCurrentMaterial,
  selectCurrentMaterialCategories,
  selectCurrentMaterialId,
  selectCurrentSubGroup,
  selectCurrentSubGroupId,
  selectCurrentTexture,
  selectCurrentTextureId,
  selectGroups,
  selectMaterials,
  selectTextures,
} from "../../../../slices/SampleSlice/selectors";
import {
  addFactoryToUser,
  cleanUserFactories,
  deleteFactoryFromUser,
  getFactoriesForUser,
  selectBrandManagers,
  selectUserFactories,
} from "../../../../slices/UserSlice";
import {
  BRAND_MANAGERS_COLUMNS,
  categoryColumns,
  FACTORIES_COLUMNS,
  materialColumns,
  sampleGroupColumns,
  subGroupColumns,
  textureColumns,
} from "../../constants/columns";

const useSampleGuideModal = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [selectedBrandManager, setSelectedBrandManager] = useState(null);
  const [selectedFactoryId, setSelectedFactoryId] = useState(null);

  const groups = useSelector(selectGroups);
  const currentGroupId = useSelector(selectCurrentGroupId);
  const currentGroup = useSelector(selectCurrentGroup);
  const subGroups = useSelector(selectCurrentGroupSubGroups);
  const currentSubGroupId = useSelector(selectCurrentSubGroupId);
  const currentSubGroup = useSelector(selectCurrentSubGroup);
  const materials = useSelector(selectMaterials);
  const currentMaterialId = useSelector(selectCurrentMaterialId);
  const currentMaterial = useSelector(selectCurrentMaterial);
  const categories = useSelector(selectCurrentMaterialCategories);
  const currentCategoryId = useSelector(selectCurrentCategoryId);
  const currentCategory = useSelector(selectCurrentCategory);
  const textures = useSelector(selectTextures);
  const currentTextureId = useSelector(selectCurrentTextureId);
  const currentTexture = useSelector(selectCurrentTexture);
  const allSamples = useSelector(selectAllSamples);
  const brandManagers = useSelector(selectBrandManagers);
  const userFactories = useSelector(selectUserFactories);

  const {factoriesOptions: factorySelectOptions} = useSelectOptions();
  const selectedFactoryOption = factorySelectOptions?.find(option => option.value === selectedFactoryId) ?? "";

  useEffect(() => {
    dispatch(fetchAllGroups());
    dispatch(fetchAllMaterials());
    dispatch(fetchAllTextures());
  }, []);

  useEffect(() => {
    if (!currentGroupId) return;

    dispatch(fetchAllSubGroups(currentGroupId));
  }, [currentGroupId]);

  useEffect(() => {
    if (!currentMaterialId) return;

    dispatch(fetchAllCategories(currentMaterialId));
  }, [currentMaterialId]);

  const initialValues = {
    group: currentGroup ? currentGroup.groupName : "",
    subGroup: currentSubGroup ? currentSubGroup.subgroupName : "",
    material: currentMaterial ? currentMaterial.materialName : "",
    category: currentCategory ? currentCategory.categoryName : "",
    texture: currentTexture ? currentTexture.textureName : "",
    brandManager: selectedBrandManager ? selectedBrandManager.lastName : "",
    factoryId: selectedFactoryId ? selectedFactoryOption.value : "",
  };

  const sGroupColumns = useColumns(sampleGroupColumns);
  const subGrColumns = useColumns(subGroupColumns);
  const matColumns = useColumns(materialColumns);
  const catColumns = useColumns(categoryColumns);
  const texColumns = useColumns(textureColumns);
  const brandManagersColumns = useColumns(BRAND_MANAGERS_COLUMNS);
  const brdManagerFactoriesColumns = useColumns(FACTORIES_COLUMNS);

  const groupsTableData = groups
    ? groups.map((group, index) => ({number: ++index, sampleGroup: group.groupName, id: group.groupId}))
    : [];
  const subGroupsTableData = subGroups
    ? subGroups.map((subGroup, index) => ({
        number: ++index,
        subGroup: subGroup.subgroupName,
        id: subGroup.subgroupId,
      }))
    : [];
  const materialsTableData = materials
    ? materials.map((material, index) => ({
        number: ++index,
        material: material.materialName,
        id: material.materialId,
      }))
    : [];
  const categoriesTableData = categories
    ? categories.map((category, index) => ({
        number: ++index,
        category: category.categoryName,
        id: category.categoryId,
      }))
    : [];
  const texturesTableData = textures
    ? textures.map((texture, index) => ({
        number: ++index,
        texture: texture.textureName,
        id: texture.textureId,
      }))
    : [];
  const brandManagersTableData = brandManagers
    ? brandManagers.map((brandManager, index) => ({
        number: ++index,
        brandManager: `${brandManager.lastName} ${brandManager.firstName}`,
        id: brandManager.userId,
      }))
    : [];
  const brdManagerFactoriesTableData = userFactories
    ? userFactories.map((factory, index) => ({
        number: ++index,
        factory: factory.factoryName,
        id: factory.factoryId,
      }))
    : [];

  function onGroupsTableRowSelect(groupId) {
    if (groupId === currentGroupId) {
      dispatch(setCurrentGroupId(null));
      dispatch(setCurrentSubGroupId(null));
      return;
    }
    dispatch(setCurrentGroupId(groupId));
  }

  function onSubGroupsTableRowSelect(subgroupId) {
    if (subgroupId === currentSubGroupId) {
      dispatch(setCurrentSubGroupId(null));
      return;
    }
    dispatch(setCurrentSubGroupId(subgroupId));
  }

  function onMaterialsTableRowSelect(materialId) {
    if (materialId === currentMaterialId) {
      dispatch(setCurrentMaterialId(null));
      dispatch(setCurrentCategoryId(null));
      return;
    }

    dispatch(setCurrentMaterialId(materialId));
  }

  function onCategoriesTableRowSelect(categoryId) {
    if (categoryId === currentCategoryId) {
      dispatch(setCurrentCategoryId(null));
      return;
    }

    dispatch(setCurrentCategoryId(categoryId));
  }

  function onTexturesTableRowSelect(textureId) {
    if (textureId === currentTextureId) {
      dispatch(setCurrentTextureId(null));
      return;
    }

    dispatch(setCurrentTextureId(textureId));
  }

  function onBrandManagersTableRowSelect(userId) {
    if (!brandManagers) return;

    setSelectedBrandManager(currentBrandManager => {
      if (currentBrandManager !== null && currentBrandManager.userId === userId) {
        dispatch(cleanUserFactories());
        setSelectedFactoryId(null);
        return null;
      }
      dispatch(getFactoriesForUser(userId));
      return brandManagers.find(brandManager => brandManager.userId === userId);
    });
  }
  function onbrdManagerFactoriesTableRowSelect(factoryId) {
    setSelectedFactoryId(currentFactoryId => {
      if (currentFactoryId !== null && currentFactoryId === factoryId) {
        return null;
      }
      return userFactories.find(factory => factory.factoryId === factoryId)?.factoryId ?? null;
    });
  }

  function onDeleteConfirm(deleteLogicFunction, text = "You won't be able to revert this!") {
    Swal.fire({
      title: t("Are you sure?"),
      text: t(text),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Yes, delete it!"),
      cancelButtonText: t("Cancel"),
    }).then(result => {
      if (result.isConfirmed) {
        deleteLogicFunction();
      }
    });
  }

  function onAddGroupBtnClick(values) {
    if (!values.group) {
      toast.error(t("You must enter a group name first"));
      return;
    }

    currentGroupId
      ? dispatch(
          updateGroup({
            groupId: currentGroupId,
            data: {
              groupName: values.group,
            },
          }),
        ).then(resp => {
          if (resp) {
            if (resp.meta.requestStatus === "fulfilled") {
              toast.success(t("Group updated successfully"));
            }
          }
        })
      : dispatch(
          addGroup({
            groupName: values.group,
          }),
        ).then(resp => {
          if (resp) {
            if (resp.meta.requestStatus === "fulfilled") {
              toast.success(t("Group added successfully"));
            }
          }
        });
  }

  function onDeleteGroupBtnClick() {
    if (!currentGroupId) {
      toast.error(t("You must select a group first!"));
      return;
    }

    onDeleteConfirm(() => {
      if (allSamples?.find(item => item?.groupId === currentGroup.groupId)) {
        Swal.fire({
          title: "",
          text: t("group in samp"),
          icon: "info",
          confirmButtonText: t("Ok"),

          customClass: {
            popup: "zindex",
            container: "zindex",
            htmlContainer: "zindex",
          },
        });
        return;
      } else if (subGroups?.find(item => item?.groupId === currentGroup.groupId)) {
        Swal.fire({
          title: "",
          text: t("group del"),
          icon: "info",
          confirmButtonText: t("Ok"),

          customClass: {
            popup: "zindex",
            container: "zindex",
            htmlContainer: "zindex",
          },
        });
        return;
      } else {
        return dispatch(deleteGroup(currentGroupId)).then(resp => {
          if (resp) {
            if (resp.meta.requestStatus === "fulfilled") {
              toast.success(t("Group deleted successfully"));
            }
          }
        });
      }
    });
  }

  function onAddSubGroupBtnClick(values) {
    if (!currentGroupId) {
      toast.error(t("You must select a group first!"));
      return;
    }
    if (!values.subGroup) {
      toast.error(t("You must enter a subGroup name first"));
      return;
    }

    currentSubGroupId
      ? dispatch(
          updateSubGroup({
            subgroupId: currentSubGroupId,
            data: {
              subgroupName: values.subGroup,
            },
          }),
        ).then(resp => {
          if (resp) {
            if (resp.meta.requestStatus === "fulfilled") {
              toast.success(t("Subgroup updated successfully"));
            }
          }
        })
      : dispatch(
          addSubGroup({
            groupId: currentGroupId,
            data: {subgroupName: values.subGroup},
          }),
        ).then(resp => {
          if (resp) {
            if (resp.meta.requestStatus === "fulfilled") {
              toast.success(t("Subgroup added successfully"));
            }
          }
        });
  }

  function onDeleteSubGroupBtnClick() {
    if (!currentGroupId || !currentSubGroupId) {
      toast.error(t("You must select a group and a subgroup first!"));
      return;
    }

    onDeleteConfirm(() => {
      if (allSamples?.find(item => item?.subgroupId === currentSubGroup.subgroupId)) {
        Swal.fire({
          title: "",
          text: t("subgroup in samp"),
          icon: "info",
          confirmButtonText: t("Ok"),

          customClass: {
            popup: "zindex",
            container: "zindex",
            htmlContainer: "zindex",
          },
        });
        return;
      } else {
        return dispatch(deleteSubGroup(currentSubGroupId)).then(resp => {
          if (resp) {
            if (resp.meta.requestStatus === "fulfilled") {
              toast.success(t("Subgroup deleted successfully"));
            }
          }
        });
      }
    });
  }

  function onAddMaterialBtnClick(values) {
    if (!values.material) {
      toast.error(t("You must enter a material name first"));
      return;
    }

    currentMaterialId
      ? dispatch(
          updateMaterial({
            materialId: currentMaterialId,
            data: {
              materialName: values.material,
            },
          }),
        ).then(resp => {
          if (resp) {
            if (resp.meta.requestStatus === "fulfilled") {
              toast.success(t("Material updated successfully"));
            }
          }
        })
      : dispatch(
          addMaterial({
            materialName: values.material,
          }),
        ).then(resp => {
          if (resp) {
            if (resp.meta.requestStatus === "fulfilled") {
              toast.success(t("Material added successfully"));
            }
          }
        });
  }

  function onDeleteMaterialBtnClick() {
    if (!currentMaterialId) {
      toast.error(t("You must select a material first!"));
      return;
    }

    onDeleteConfirm(() => {
      if (allSamples?.find(item => item?.materialId === currentMaterial.materialId)) {
        Swal.fire({
          title: "",
          text: t("material in samp"),
          icon: "info",
          confirmButtonText: t("Ok"),

          customClass: {
            popup: "zindex",
            container: "zindex",
            htmlContainer: "zindex",
          },
        });
        return;
      } else if (categories?.find(item => item?.materialId === currentMaterial.materialId)) {
        Swal.fire({
          title: "",
          text: t("material del"),
          icon: "info",
          confirmButtonText: t("Ok"),

          customClass: {
            popup: "zindex",
            container: "zindex",
            htmlContainer: "zindex",
          },
        });
        return;
      } else {
        return dispatch(deleteMaterial(currentMaterialId)).then(resp => {
          if (resp) {
            if (resp.meta.requestStatus === "fulfilled") {
              toast.success(t("Material deleted successfully"));
            }
          }
        });
      }
    });
  }

  function onAddCategoryBtnClick(values) {
    if (!currentMaterialId) {
      toast.error(t("You must select a material first!"));
      return;
    }
    if (!values.category) {
      toast.error(t("You must enter a category name first"));
      return;
    }

    currentCategoryId
      ? dispatch(
          updateCategory({
            categoryId: currentCategoryId,
            data: {
              categoryName: values.category,
            },
          }),
        ).then(resp => {
          if (resp) {
            if (resp.meta.requestStatus === "fulfilled") {
              toast.success(t("Category updated successfully"));
            }
          }
        })
      : dispatch(
          addCategory({
            materialId: currentMaterialId,
            data: {categoryName: values.category},
          }),
        ).then(resp => {
          if (resp) {
            if (resp.meta.requestStatus === "fulfilled") {
              toast.success(t("Category added successfully"));
            }
          }
        });
  }

  function onDeleteCategoryBtnClick() {
    if (!currentMaterialId || !currentCategoryId) {
      toast.error(t("You must select a material and a category first!"));
      return;
    }

    onDeleteConfirm(() => {
      if (allSamples?.find(item => item?.categoryId === currentCategory.categoryId)) {
        Swal.fire({
          title: "",
          text: t("category in samp"),
          icon: "info",
          confirmButtonText: t("Ok"),

          customClass: {
            popup: "zindex",
            container: "zindex",
            htmlContainer: "zindex",
          },
        });
        return;
      } else {
        return dispatch(deleteCategory(currentCategoryId)).then(resp => {
          if (resp) {
            if (resp.meta.requestStatus === "fulfilled") {
              toast.success(t("Category deleted successfully"));
            }
          }
        });
      }
    });
  }

  function onAddTextureBtnClick(values) {
    if (!values.texture) {
      toast.error(t("You must enter a texture name first"));
      return;
    }

    currentTextureId
      ? dispatch(
          updateTexture({
            textureId: currentTextureId,
            data: {
              textureName: values.texture,
            },
          }),
        ).then(resp => {
          if (resp) {
            if (resp.meta.requestStatus === "fulfilled") {
              toast.success(t("Texture updated successfully"));
            }
          }
        })
      : dispatch(
          addTexture({
            textureName: values.texture,
          }),
        ).then(resp => {
          if (resp) {
            if (resp.meta.requestStatus === "fulfilled") {
              toast.success(t("Texture added successfully"));
            }
          }
        });
  }
  function onDeleteTextureBtnClick() {
    if (!currentTextureId) {
      toast.error(t("You must select a texture first!"));
      return;
    }

    onDeleteConfirm(() => {
      if (allSamples?.find(item => item?.textureId === currentTexture.textureId)) {
        Swal.fire({
          title: "",
          text: t("texture in samp"),
          icon: "info",
          confirmButtonText: t("Ok"),

          customClass: {
            popup: "zindex",
            container: "zindex",
            htmlContainer: "zindex",
          },
        });
        return;
      } else {
        return dispatch(deleteTexture(currentTextureId)).then(resp => {
          if (resp) {
            if (resp.meta.requestStatus === "fulfilled") {
              toast.success(t("Texture deleted successfully"));
            }
          }
        });
      }
    });
  }

  function onAddBrdManagerFactoriesBtnClick(values) {
    if (!selectedBrandManager) {
      toast.error(t("You must select a Brand Manager first!"));
      return;
    }
    if (!values.factoryId) {
      toast.error(t("You must enter a factory name first"));
      return;
    }
    if (userFactories.find(factory => factory.factoryId === values.factoryId)) {
      toast.error(t("The factory has already been added"));
      return;
    }
    setSelectedFactoryId(values.factoryId);
    dispatch(addFactoryToUser({userId: selectedBrandManager.userId, factoryId: values.factoryId})).then(resp => {
      if (resp) {
        if (resp.meta.requestStatus === "fulfilled") {
          toast.success(t("Factory added to Brand Manager successfully"));
        }
      }
    });
  }
  function onDeleteBrdManagerFactoriesBtnClick(values) {
    if (!selectedFactoryId) {
      toast.error(t("You must select a factory first!"));
      return;
    }

    onDeleteConfirm(() => {
      setSelectedFactoryId(null);
      dispatch(deleteFactoryFromUser({userId: selectedBrandManager.userId, factoryId: values.factoryId})).then(resp => {
        if (resp) {
          if (resp.meta.requestStatus === "fulfilled") {
            toast.success(t("Factory deleted from Brand Manager successfully"));
          }
        }
      });
    }, "");
  }

  return {
    initialValues,
    sGroupColumns,
    subGrColumns,
    matColumns,
    catColumns,
    texColumns,
    groupsTableData,
    subGroupsTableData,
    materialsTableData,
    categoriesTableData,
    onGroupsTableRowSelect,
    onSubGroupsTableRowSelect,
    onMaterialsTableRowSelect,
    onCategoriesTableRowSelect,
    texturesTableData,
    onTexturesTableRowSelect,
    onAddGroupBtnClick,
    onDeleteGroupBtnClick,
    onAddSubGroupBtnClick,
    onDeleteSubGroupBtnClick,
    onAddMaterialBtnClick,
    onDeleteMaterialBtnClick,
    onAddCategoryBtnClick,
    onDeleteCategoryBtnClick,
    onAddTextureBtnClick,
    onDeleteTextureBtnClick,
    currentGroupId,
    currentSubGroupId,
    currentMaterialId,
    currentCategoryId,
    currentTextureId,
    brandManagersColumns,
    brandManagersTableData,
    onBrandManagersTableRowSelect,
    selectedBrandManager,
    brdManagerFactoriesColumns,
    brdManagerFactoriesTableData,
    onbrdManagerFactoriesTableRowSelect,
    onAddBrdManagerFactoriesBtnClick,
    onDeleteBrdManagerFactoriesBtnClick,
    factorySelectOptions,
    selectedFactoryId,
  };
};

export default useSampleGuideModal;

import React from "react";
import PropTypes from "prop-types";

import Spinner from "../spinner/Spinner";
import {t} from "i18next";

const PageContainer = ({title, children, loading, style, className = ""}) => {
  return (
    <div className="limitedSize">
      <p className="limitedSize_label">{t(title)}</p>
      <div className="overflow_container">
        <div className={`limitedSize__container ${className}`} style={style}>
          {loading ? <Spinner /> : children}
        </div>
      </div>
    </div>
  );
};
PageContainer.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

export default PageContainer;

import React from "react";

import {ContextMenu, ContextMenuOption} from "../../hook/useContextMenu";
import UnlinkIcon from "../../images/icons/unlink.png";
import Divider from "../../utilities/Divider/Divider";
import AddIcon from "../common/controlButtons/icons/8201370_add_plus_button_ui_new_icon.png";
import EditIcon from "../common/controlButtons/icons/8725775_edit_icon.png";
import DeleteIcon from "../common/controlButtons/icons/8726424_trash_alt_icon.png";
import DublicateIcon from "../common/controlButtons/icons/11030095_copy_icon.png";
import ViewIcon from "../common/controlButtons/icons/openEye.png";

const ContextMenuButtons = ({settings, menuVisible, menuPosition}) => {
  const {add, edit, view, dublicate, del} = settings;
  return (
    <ContextMenu menuVisible={menuVisible} menuPosition={menuPosition}>
      <ContextMenuOption icon={AddIcon} onClick={add.onClick} disabled={add.disabled} text={add.tolltip} />
      <ContextMenuOption icon={EditIcon} text={edit.tolltip} onClick={edit.onClick} disabled={edit.disabled} />
      <ContextMenuOption icon={ViewIcon} text={view.tolltip} onClick={view.onClick} disabled={view.disabled} />
      <ContextMenuOption
        icon={DublicateIcon}
        text={dublicate.tolltip}
        onClick={dublicate.onClick}
        disabled={dublicate.disabled}
      />
      {settings?.unlink && (
        <ContextMenuOption
          icon={UnlinkIcon}
          text={settings?.unlink.tolltip}
          onClick={settings.unlink.onClick}
          disabled={settings.unlink.disabled}
        />
      )}
      <Divider />
      <ContextMenuOption icon={DeleteIcon} text={del.tolltip} onClick={del.onClick} disabled={del.disabled} />
    </ContextMenu>
  );
};

export default ContextMenuButtons;

import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {Divider} from "@mui/material";
import {Formik} from "formik";

import {getAllKits} from "../../../../slices/SampleFunctionalSlice";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ToolTip from "../../../../utilities/ToolTip";
import ReactTable from "../../../conteiners/ReactTable";
import {TextInput} from "../../../Inputs";
import SampleFilter from "../../../SamplesList/SampleParts/SampleFilter/SampleFilter";

import useTransferSampleToKitTab, {TRANSFER_SAMPLE_TO_KIT_STORAGE_KEY} from "./useTransferSampleToKitTab";

const TransferSampleToKitTabBase = ({setTabs, setTabValue}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const {
    onCancel,
    onTransferSample,
    sampleTableData,
    sampleTableColumns,
    onPaginate,
    kitTableData,
    ktTableColumns,
    onKitSelect,
    currentKitId,
    filters,
    isSampleTransfered,
    chosenSampleId,
    isKitOwn,
  } = useTransferSampleToKitTab({setTabs, setTabValue});

  useEffect(() => {
    dispatch(getAllKits(filters));
  }, [Object.keys(filters ?? {}).length]);

  return (
    <div className="form_wrapper hmax100 fGrow" style={{justifyContent: "flex-start"}}>
      <div className="fc jcfe aibl">
        <TextInput label={t("BM")} width="17em" name="brandManager" disabled />
      </div>
      <Divider />
      <SampleFilter storageKey={TRANSFER_SAMPLE_TO_KIT_STORAGE_KEY} />
      <div className="fcCol fGrow gap">
        <ReactTable
          defaultData={kitTableData}
          columns={ktTableColumns}
          onSelect={onKitSelect}
          current={currentKitId || null}
          className={"scrollX fGrow"}
          style={{height: "12em"}}
          tableClassName="tableCenterText"
        />
        <Divider />
        <ReactTable
          defaultData={sampleTableData}
          columns={sampleTableColumns}
          selectable={false}
          className={"scrollX fGrow fShrink"}
          tableClassName="tableCenterText"
          current={isSampleTransfered ? chosenSampleId : null}
          style={{
            marginBottom: "10px",
            maxHeight: "unset",
            height: "200px",
          }}
          onPaginate={onPaginate}
          paginatable={true}
        />
      </div>
      <div className="fc jcc mb gap2x">
        <ToolTip
          title={
            !currentKitId ? "Select kit first" : !isKitOwn ? "Chosen kit must be own by current brand manager" : ""
          }
        >
          <FunctionButton
            name="Transfer sample"
            onClick={onTransferSample}
            disabled={!isKitOwn || isSampleTransfered || !currentKitId}
          />
        </ToolTip>

        <FunctionButton name="Cancel" onClick={onCancel} disabled={!isKitOwn || !isSampleTransfered} />
      </div>
    </div>
  );
};

const TransferSampleToKitTab = ({setTabs, setTabValue}) => {
  const {initialValues} = useTransferSampleToKitTab({setTabs, setTabValue});

  return (
    <Formik initialValues={initialValues} validateOnChange={false} validateOnBlur={false} enableReinitialize>
      <TransferSampleToKitTabBase setTabs={setTabs} setTabValue={setTabValue} />
    </Formik>
  );
};

export default TransferSampleToKitTab;

import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2";

import {addNewSample, addSampleToExistingKit, selectKit, updateKit} from "../../../slices/SampleFunctionalSlice";
import {setCurrentTableSample} from "../../../slices/SampleSlice/SampleSlice";
import {getCurrentUser, getInfo} from "../../../slices/UserSlice";
import useToaster from "../../../utilities/hooks/useToaster";
import {withSampleMod} from "../constants/SampleHOC";

const KitSampleUpdateModal = ({typeForm}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const Toaster = useToaster();

  const kit = useSelector(selectKit);
  const currUser = useSelector(getCurrentUser);

  const onSetClick = data => {
    if (!data?.skuNumber) {
      Toaster.error("A kit must have a kitSKU number");
      return;
    }
    console.log("data :", data);
    Swal.fire({
      title: "",
      text: t("if upd kit"),
      icon: "info",
      confirmButtonText: t("Continue"),
      showCancelButton: true,
      cancelButtonText: t("Cancel"),

      customClass: {
        popup: "zindex",
        container: "zindex",
        htmlContainer: "zindex",
      },
    }).then(answ => {
      if (answ.isConfirmed) {
        dispatch(updateKit({kitId: kit.kitId, data})).then(res => {
          if (res.meta.requestStatus === "fulfilled") {
            getInfo(t("ins updated"));
          }
        });
      }
    });
  };

  const onInstanceClick = (data, setValues) => {
    dispatch(addNewSample(data)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        getInfo(t("ins added"));
        dispatch(addSampleToExistingKit(res.payload));
        dispatch(setCurrentTableSample({typeForm, sample: res.payload}));
      }
    });
  };

  const onSetCancel = (data, setValues, resetForm) => {
    Swal.fire({
      title: "",
      text: t("go back"),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("No"),

      customClass: {
        popup: "zindex",
        container: "zindex",
        htmlContainer: "zindex",
      },
    }).then(answ => {
      if (answ.isConfirmed) {
        setValues({
          kitSku: data.skuNumber,
          setDescArea: data.name,
        });
      }
    });
  };
  const onInstanceCancel = (data, setValues, resetForm) => {
    Swal.fire({
      title: "",
      text: t("del data"),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("No"),

      customClass: {
        popup: "zindex",
        container: "zindex",
        htmlContainer: "zindex",
      },
    }).then(answ => {
      if (answ.isConfirmed) {
        setValues({
          // brandManager: currUser?.lastName + " " + currUser?.firstName,
          factoryId: "",
          kitSku: kit.skuNumber,
          setDescArea: kit.name,
          skuNumber: "",
          groupId: "",
          subgroupId: "",
          materialId: "",
          categoryId: "",
          textureId: "",
          collection: "",
          color: "",
          name: "",
          state: "",
        });
      }
    });
  };

  const SampleForm = withSampleMod(
    t("renew"),
    t("Save"),
    onSetClick,
    onInstanceClick,
    onSetCancel,
    onInstanceCancel,
    true,
  );
  return <SampleForm typeForm={typeForm} />;
};

export default KitSampleUpdateModal;

export const KitsTransferListUpperTableColumns = [
  {
    header: "№",
    accessorKey: "number",
    size: 60,
  },
  {
    header: "date kit",
    accessorKey: "createdAt",
    size: 130,
    isSortable: true,
    sortType: "datetime",
  },
  // {
  //   header: "Factory",
  //   accessorKey: "factory",
  //   // size: 15,
  //   isSortable: true,
  // },
  {
    header: "kitSKU №",
    accessorKey: "skuNumber",
    // size: 100,
    isSortable: true,
  },
  {
    header: "Photo",
    accessorKey: "kitPhoto",
    size: 60,
  },

  {
    header: "Nomenclature",
    accessorKey: "name",
    // size: 100,
    isSortable: true,
  },
  {
    header: "BM",
    accessorKey: "brandManager",
    // size: 100,
    isSortable: true,
  },
  {
    header: "acc m",
    accessorKey: "manager",
    // size: 100,
    isSortable: true,
  },
  {
    header: "Date from",
    accessorKey: "dateFrom",
    size: 100,
    isSortable: true,
  },
  {
    header: "Date to",
    accessorKey: "dateTo",
    size: 100,
    isSortable: true,
  },

  {
    header: "Status*",
    accessorKey: "status",
    // size: 100,
    isSortable: true,
  },
];

export const KitsTransferListLowerTableColumns = [
  {
    header: "№",
    accessorKey: "number",
    size: 60,
  },
  {
    header: "kitSKU №",
    accessorKey: "kit",
    // size: 100,
    isSortable: true,
  },
  {
    header: "SKU №",
    accessorKey: "skuNumber",
    // size: 100,
    isSortable: true,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    // size: 15,
  },
  {
    header: "col",
    accessorKey: "collection",
    // size: 100,
    isSortable: true,
  },
  {
    header: "Color",
    accessorKey: "color",
    // size: 100,
    isSortable: true,
  },
  {
    header: "texture",
    accessorKey: "texture",
    // size: 100,
    isSortable: true,
  },
  {
    header: "Nomenclature",
    accessorKey: "name",
    // size: 100,
    isSortable: true,
  },
  {
    header: "Smp g",
    accessorKey: "group",
    // size: 100,
    isSortable: true,
  },
  {
    header: "sgr",
    accessorKey: "subgroup",
    size: 100,
    isSortable: true,
  },
  {
    header: "material",
    accessorKey: "material",
    // size: 100,
    isSortable: true,
  },
  {
    header: "categ",
    accessorKey: "category",
    // size: 100,
    isSortable: true,
  },

  {
    header: "Photo",
    accessorKey: "samplePhoto",
    size: 70,
  },

  {
    header: "BM",
    accessorKey: "brandManager",
    // size: 100,
    isSortable: true,
  },
  {
    header: "acc m",
    accessorKey: "manager",
    // size: 100,
    isSortable: true,
  },
  {
    header: "Note",
    accessorKey: "state",
    // size: 100,
  },

  {
    header: "Date from",
    accessorKey: "dateFrom",
    size: 100,
    isSortable: true,
  },
  {
    header: "Date to",
    accessorKey: "dateTo",
    size: 100,
    isSortable: true,
  },
  {
    header: "Status*",
    accessorKey: "status",

    isSortable: true,
  },

  {
    header: "ds",
    accessorKey: "skuDate",
    size: 170,
    isSortable: true,
  },
];

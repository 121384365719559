import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useFormikContext} from "formik";
import {isEqual} from "lodash";

import {selectKit, selectNewKit} from "../../../slices/SampleFunctionalSlice";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import {TextAreaInput, TextInput} from "../../Inputs";
import {TYPE_FORMS} from "../../SamplesList/SamplesList";

const KitTabPart = ({typeForm}) => {
  const {t} = useTranslation();

  const TYPES = Object.freeze({
    CREATE: "create",
    EDIT: "edit",
    SAMPLE: "sample",
  });
  const type =
    typeForm === TYPE_FORMS.CREATE_KIT ? TYPES.CREATE : typeForm === TYPE_FORMS.UPDATE_KIT ? TYPES.EDIT : TYPES.SAMPLE;

  const {values, resetForm, setValues, setFieldValue} = useFormikContext();
  const {setDescArea, kitSku} = values;
  const areAllRequiredFieldsFilled = setDescArea && kitSku;

  const kit = useSelector(selectKit);
  const newKit = useSelector(selectNewKit);

  const [formChanges, setFormChanges] = useState(false);

  useEffect(() => {
    const isChanged = !isEqual(
      {name: values.setDescArea, skuNumber: values.kitSku},
      {name: kit?.name, skuNumber: kit?.skuNumber},
    );

    setFormChanges(isChanged);
  }, [values]);

  function onAddNewKit() {}
  function onUpdateKit() {}
  function onCancelCreate() {}
  function onCancelUpdate() {}

  return (
    <div className="part_width flex_form jcsb">
      <div className="fdr">
        <TextInput label="kitSKU № *" name="kitSku" labelStyles={{width: "4em"}} disabled={type === TYPES.SAMPLE} />
      </div>
      <div>
        <TextAreaInput
          label=""
          name="setDescArea"
          width="101%"
          placeholder={t("samp nom") + "*" + ": " + t("1000")}
          disabled={type === TYPES.SAMPLE}
        />
        {type !== TYPES.SAMPLE && (
          <div className="fdr g1 jcfe mt">
            <FunctionButton
              name={type === TYPES.CREATE ? "Save" : "renew"}
              onClick={type === TYPES.CREATE ? onAddNewKit : onUpdateKit}
              disabled={!areAllRequiredFieldsFilled}
            />
            <FunctionButton
              name="Cancel"
              onClick={type === TYPES.CREATE ? onCancelCreate : onCancelUpdate}
              disabled={!areAllRequiredFieldsFilled || !formChanges || newKit}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default KitTabPart;

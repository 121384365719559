import {t} from "i18next";

import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import {TextInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";

const ActionWithChosen = ({onExecuteActionBtnClick = () => {}}) => {
  const actionOptions = [];

  return (
    <div className="row gap mt">
      <Select label={t("Action with chosen")} name="actionId" options={actionOptions ?? []} width="15em" optionsAbove />
      <TextInput name="returnReason" label="" autolabel width="8em" />
      <FunctionButton name={t("Execute")} onClick={onExecuteActionBtnClick} autoWidth />
    </div>
  );
};

export default ActionWithChosen;

import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

import {apiUrl} from "../../../../api/baseURL";
import ToolTip from "../../../../utilities/ToolTip";

const PdfPreviewItem = ({url, delFunc}) => {
  const {t} = useTranslation();
  const format = url.split(".").pop();

  return (
    <div className="previewBoxItem">
      <div>
        {format === "pdf" ? (
          <p style={{width: "10em", wordWrap: "break-word"}}>{url.split("/").pop()}</p>
        ) : (
          <img src={url ? `${apiUrl}/${url}` : ""} alt="" width="200px" height="200px" draggable="false" />
        )}
      </div>
      <div
        className="previewBoxItem__del"
        onClick={() => {
          delFunc(url);
        }}
      >
        <ToolTip title={t("Delete document")}>
          <span>&#215;</span>
        </ToolTip>
      </div>
    </div>
  );
};

PdfPreviewItem.propTypes = {
  url: PropTypes.string,
  delFunc: PropTypes.func,
};

export default PdfPreviewItem;

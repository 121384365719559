import {useTranslation} from "react-i18next";
import * as Yup from "yup";

import {TYPE_FORMS} from "../../../SamplesList/SamplesList";

const useCreateKitTab = () => {
  const typeForm = TYPE_FORMS.CREATE_KIT;
  const {t} = useTranslation();

  const validationSchema = Yup.object().shape({
    kitSku: Yup.string(),
    setDescArea: Yup.string().required(t("enter name")),
  });
  const initialValues = {};

  return {typeForm, validationSchema, initialValues};
};

export default useCreateKitTab;

import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {
  fetchCountriesCatalog,
  fetchFactoriesCatalog,
  fetchOfficesCatalog,
  fetchRolesCatalog,
  getCountriesCatalog,
  getFactoriesCatalog,
  getOfficesCatalog,
  getRolesCatalog,
} from "../slices/GeneralCatalogSlice";
import {fetchAllGroups, fetchAllMaterials, fetchAllTextures} from "../slices/SampleSlice/operations";
import {
  selectCurrentGroupSubGroups,
  selectCurrentMaterialCategories,
  selectGroups,
  selectMaterials,
  selectTextures,
} from "../slices/SampleSlice/selectors";

const useSelectOptions = () => {
  // const {t, i18n} = useTranslation();
  const dispatch = useDispatch();

  const offices = useSelector(getOfficesCatalog),
    roles = useSelector(getRolesCatalog),
    countries = useSelector(getCountriesCatalog),
    factories = useSelector(getFactoriesCatalog),
    groups = useSelector(selectGroups),
    materials = useSelector(selectMaterials),
    textures = useSelector(selectTextures),
    subGroups = useSelector(selectCurrentGroupSubGroups),
    categories = useSelector(selectCurrentMaterialCategories);

  // useEffect(() => {
  //   if (!offices?.length) {
  //     dispatch(fetchOfficesCatalog());
  //   }
  //   if (!roles?.length) {
  //     dispatch(fetchRolesCatalog());
  //   }
  //   if (!factories?.length) {
  //     dispatch(fetchFactoriesCatalog());
  //   }
  //   if (!countries?.length) {
  //     dispatch(fetchCountriesCatalog());
  //   }
  //   if (!groups?.length) {
  //     dispatch(fetchAllGroups());
  //   }
  //   if (!materials?.length) {
  //     dispatch(fetchAllMaterials());
  //   }
  //   if (!textures?.length) {
  //     dispatch(fetchAllTextures());
  //   }
  // }, []);

  const materialsOptions = useMemo(() => {
    return (
      materials?.map(item => {
        return {title: item.materialName, value: item.materialId};
      }) || []
    );
  }, [materials]);

  const texturesOptions = useMemo(() => {
    return (
      textures?.map(item => {
        return {title: item.textureName, value: item.textureId};
      }) || []
    );
  }, [textures]);

  const categoriesOptions = useMemo(() => {
    return (
      categories?.map(item => {
        return {title: item.categoryName, value: item.categoryId};
      }) || []
    );
  }, [categories]);

  const subGroupsOptions = useMemo(() => {
    return (
      subGroups?.map(item => {
        return {title: item.subgroupName, value: item.subgroupId};
      }) || []
    );
  }, [subGroups]);

  const groupsOptions = useMemo(() => {
    return (
      groups?.map(item => {
        return {title: item.groupName, value: item.groupId};
      }) || []
    );
  }, [groups]);

  const officesOptions = useMemo(() => {
    return (
      offices?.map(item => {
        return {title: item.officeName, value: item.officeId};
      }) || []
    );
  }, [offices]);

  const rolesOptions = useMemo(() => {
    return (
      roles?.map(item => {
        return {title: item.roleName, value: item.roleId};
      }) || []
    );
  }, [roles]);

  const countriesOptions = useMemo(() => {
    return (
      countries?.map(item => {
        return {title: item.shortName, value: item.countryId};
      }) || []
    );
  }, [countries]);

  const factoriesOptions = useMemo(() => {
    return (
      factories?.map(item => {
        return {title: item.factoryName, value: item.factoryId};
      }) || []
    );
  }, [factories]);

  return {
    officesOptions,
    rolesOptions,
    countriesOptions,
    factoriesOptions,
    groupsOptions,
    subGroupsOptions,
    materialsOptions,
    texturesOptions,
    categoriesOptions,
  };
};

export default useSelectOptions;

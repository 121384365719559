import React from "react";

const AddIcon = ({color = "#567db8", style, disabled, ...props}) => (
  <div
    style={{
      boxSizing: "content-box",
      cursor: disabled ? "not-allowed" : "pointer",
      pointerEvents: disabled ? "none" : "all",
      opacity: disabled ? "0.7" : "1",
    }}
  >
    <svg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: "2em",
        height: "2em",
        borderRadius: "2em",
        boxSizing: "content-box !important",

        ...style,
      }}
      {...props}
    >
      <g data-name="Layer 2">
        <path
          d="M16 29a13 13 0 1 1 13-13 13 13 0 0 1-13 13Zm0-24a11 11 0 1 0 11 11A11 11 0 0 0 16 5Z"
          fill={color}
          className="fill-000000"
        ></path>
        <path
          d="M16 23a1 1 0 0 1-1-1V10a1 1 0 0 1 2 0v12a1 1 0 0 1-1 1Z"
          fill={color}
          className="fill-000000"
          width="50"
          height="50"
        ></path>
        <path d="M22 17H10a1 1 0 0 1 0-2h12a1 1 0 0 1 0 2Z" fill={color} className="fill-000000"></path>
      </g>
      <path d="M0 0h32v32H0z" fill="none"></path>
    </svg>
  </div>
);

export default AddIcon;

import {dateFormat} from "../../../utilities/dateFormat";

const samplesForKitModalDataService = samples =>
  samples?.map(
    (
      {
        createdAt,
        skuNumber,
        factory,
        kit,
        group,
        subgroup,
        material,
        category,
        texture,
        collection,
        color,
        name,
        sampleId,
      },
      i,
    ) => {
      return {
        number: i + 1,
        id: sampleId,
        dateSku: dateFormat(createdAt),
        factory: factory?.factoryName,

        skuN: skuNumber || "-",
        sampleGroup: group?.groupName || "-",
        subGroup: subgroup?.subgroupName || "-",
        material: material?.materialName || "-",
        category: category?.categoryName || "-",
        texture: texture?.textureName || "-",
        collection: collection || "-",
        color: color || "-",
        nomen: name || "-",
      };
    },
  ) || [];

export default samplesForKitModalDataService;

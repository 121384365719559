import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
const useContextMenu = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({x: 0, y: 0});
  const [targetElement, setTargetElement] = useState(null);

  const handleContextMenu = event => {
    event.preventDefault();
    setTargetElement(event.target.id);

    const clickY = event.pageY;
    const windowHeight = window.innerHeight;
    const menuHeight = document.getElementById("context-menu-ul").offsetHeight;

    if (clickY + menuHeight > windowHeight) {
      setMenuPosition({x: event.pageX, y: clickY - menuHeight});
    } else {
      setMenuPosition({x: event.pageX, y: clickY});
    }
    setMenuVisible(true);
  };

  const handleClick = () => {
    setMenuVisible(false);
  };

  useEffect(() => {
    const container = document.getElementById("context-menu-container");
    container.addEventListener("click", handleClick);

    return () => {
      container.removeEventListener("click", handleClick);
    };
  }, []);

  return {menuVisible, handleContextMenu, menuPosition, targetElement};
};

export const ContextMenu = ({menuVisible, menuPosition, children}) => {
  return (
    <ul
      id={"context-menu-ul"}
      style={{
        opacity: menuVisible ? 1 : 0,
        pointerEvents: menuVisible ? "all" : "none",
        position: "fixed",
        top: `${menuPosition.y}px`,
        left: `${menuPosition.x}px`,
        backgroundColor: "white",
        boxShadow: "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
        listStyle: "none",
        padding: "10px 0",
        zIndex: menuVisible ? 1000 : -10,
      }}
    >
      {children}
    </ul>
  );
};

export const ContextMenuOption = ({icon, text, onClick, disabled}) => {
  const {t} = useTranslation();
  return (
    <li
      onClick={onClick}
      style={{padding: " 0.5em 1em", fontWeight: "300", pointerEvents: disabled ? "none" : "all"}}
      onMouseEnter={e => {
        if (!disabled) e.currentTarget.style.backgroundColor = "#f0f0f0";
      }}
      onMouseLeave={e => {
        e.currentTarget.style.backgroundColor = "white";
      }}
    >
      <div className="fc nowrap" style={{cursor: "pointer", opacity: disabled ? "0.5" : "1"}}>
        {icon && (
          <img
            src={icon}
            style={{
              width: `1.5em`,
              height: `1.5em`,
            }}
          />
        )}
        <span style={{marginLeft: "10px"}}>{t(text)}</span>
      </div>
    </li>
  );
};

export default useContextMenu;

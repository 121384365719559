import {dateFormat, dateFullFormat} from "../../../utilities/dateFormat";

const samplesTableDataService = samples =>
  samples?.map(
    (
      {
        createdAt,
        skuNumber,
        factory,
        kit,
        group,
        subgroup,
        material,
        category,
        texture,
        collection,
        color,
        name,
        brandManager,
        manager,
        dateFrom,
        dateTo,
        status,
        sampleId,
        state,
        images,
      },
      i,
    ) => {
      const nameServ = !brandManager.firstName
        ? brandManager.lastName
        : brandManager.lastName + " " + brandManager.firstName;
      const nameMngServ = !manager?.firstName ? manager?.lastName : manager?.lastName + " " + manager?.firstName;
      return {
        number: i + 1,
        id: sampleId,
        skuDate: dateFullFormat(createdAt),
        factory: factory?.factoryName,
        kit: kit?.skuNumber || "-",
        skuNumber: skuNumber || "-",
        group: group?.groupName || "-",
        subgroup: subgroup?.subgroupName || "-",
        material: material?.materialName || "-",
        category: category?.categoryName || "-",
        texture: texture?.textureName || "-",
        collection: collection || "-",
        color: color || "-",
        name: name || "-",
        brandManager: nameServ,
        manager: nameMngServ || "-",
        dateFrom: dateFrom ? dateFormat(dateFrom) : "-",
        dateTo: dateTo ? dateFormat(dateTo) : "-",
        status: status?.name,
        state: state || "-",
        samplePhoto: images?.length > 0 ? "✔" : "✖",
      };
    },
  ) || [];

export default samplesTableDataService;

import {Formik} from "formik";

import useUpdateTab from "../../../SamplesListNew/tabs/useUpdateTab";

import useCreateKitTab from "./useCreateKitTab";

const CreateKitTabBase = () => {
  const {typeForm} = useCreateKitTab();

  useUpdateTab({typeForm});

  return <div>CreateKitTab</div>;
};

const CreateKitTab = () => {
  const {validationSchema, initialValues} = useCreateKitTab();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
    >
      <CreateKitTabBase />
    </Formik>
  );
};

export default CreateKitTab;

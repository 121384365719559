import {useDispatch, useSelector} from "react-redux";
import {useFormikContext} from "formik";

import useColumns from "../../../../hook/useColumns";
import {
  selectAllKits,
  selectAllSamples,
  selectKit,
  selectNewKit,
  selectNewSample,
} from "../../../../slices/SampleFunctionalSlice";
import {fetchCurrentTableSample} from "../../../../slices/SampleSlice/operations";
import {clearCurrentTableSample} from "../../../../slices/SampleSlice/SampleSlice";
import {selectCurrentKitId} from "../../../../slices/SampleSlice/selectors";
import ReactTable from "../../../conteiners/ReactTable";
import {formSampleValues} from "../../../KitsList/constants";
import {SampleUniModTableColumns} from "../../constants/columns";
import samplesForKitModalDataService from "../../constants/samplesForKitModalDataService";
import {TYPE_FORMS} from "../../SamplesList";

const KitSampleTableModalPart = ({
  complectChecked,
  typeForm,
  selectable = true,
  currentSampleId = null,
  setCurrentSampleId = () => {},
}) => {
  const dispatch = useDispatch();
  const {setValues} = useFormikContext();

  const existingKit = useSelector(selectKit);
  const newKit = useSelector(selectNewKit);
  const currentKitId = useSelector(selectCurrentKitId);
  const allKits = useSelector(selectAllKits);
  const allSamples = useSelector(selectAllSamples);
  const sample = useSelector(state => state.samples.modalData[typeForm].sample);
  const newSample = useSelector(selectNewSample);

  const isCurrentKit = Boolean(currentKitId);
  const currentKit = allKits?.find(kit => kit.kitId === currentKitId);
  const kit = typeForm.endsWith("KIT_SAMPLES") ? currentKit : typeForm.startsWith("CREATE") ? newKit : existingKit;
  const uniTableColumns = useColumns(SampleUniModTableColumns);
  const instanceListForKit = samplesForKitModalDataService(
    isCurrentKit && typeForm !== TYPE_FORMS.CREATE_KIT && typeForm !== TYPE_FORMS.UPDATE_KIT
      ? allSamples
      : kit?.samples,
  );

  const onInstanceKitSelect = id => {
    if (sample && id === sample?.sampleId) {
      setTimeout(() => {
        setValues(prev => ({
          ...prev,
          ...formSampleValues(),
        }));
      }, []);

      dispatch(clearCurrentTableSample({typeForm}));
      setCurrentSampleId(null);
      return;
    } else {
      setCurrentSampleId(id);
      const currKitSample = kit?.samples?.find(item => item.sampleId === id);
      if (!currKitSample) return;
      dispatch(fetchCurrentTableSample({sampleId: currKitSample?.sampleId, typeForm})).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          setValues(prev => ({
            ...prev,
            ...formSampleValues(currKitSample),
            kitSku: kit?.skuNumber,
            setDescArea: kit?.name,
          }));
        }
      });
    }
  };

  return (
    <ReactTable
      defaultData={complectChecked ? instanceListForKit : []}
      columns={uniTableColumns}
      selectable={selectable}
      onSelect={onInstanceKitSelect}
      current={
        currentSampleId || (typeForm === TYPE_FORMS.CREATE_KIT_SAMPLES ? newSample?.sampleId : sample?.sampleId) || null
      }
      className={`mb scrollX overflowAuto fGrow ${complectChecked ? "" : "block_opacity"}`}
      style={{height: "15em", maxHeight: "unset"}}
    />
  );
};

export default KitSampleTableModalPart;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tab {
  cursor: default;
  position: absolute;
  top: -1.5em;
  border: 0;
  border-radius: 1px 25px 1px 0px;
  line-height: 1.5em;
  white-space: nowrap;
  font-size: 1.1em;
  font-weight: 400;
  background-color: #a3b9d9;
  padding: 0 0.9em 0 1.1em;
  justify-content: space-between;
  width: 200px;
  box-sizing: border-box;
}
.tab .container {
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.tab:focus {
  outline: none;
}
.tab.selected {
  background-color: #f5f5f5;
  opacity: 1;
  color: #567db8;
  border: 1px solid #a3b9d9;
  border-bottom: 0;
}
.tab .tab-close-btn {
  margin-left: 1em;
  width: 1.5em;
  text-align: center;
  cursor: pointer;
  position: absolute;
  right: 0;
  z-index: 10;
}
.tab .tab-close-btn:hover {
  opacity: 0.9;
  border-radius: 8px;
  background-color: rgba(128, 128, 128, 0.175);
  box-shadow: rgba(0, 0, 0, 0.118) 0px 19px 38px, rgba(0, 0, 0, 0.09) 0px 15px 12px;
}`, "",{"version":3,"sources":["webpack://./src/Components/NEW-tabs/tabs.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;EACA,WAAA;EAEA,SAAA;EACA,+BAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,yBAAA;EACA,wBAAA;EACA,8BAAA;EAEA,YAAA;EACA,sBAAA;AADF;AAKE;EACE,kBAAA;EACA,uBAAA;AAHJ;AAOE;EACE,aAAA;AALJ;AAOE;EACE,yBAAA;EACA,UAAA;EACA,cAAA;EACA,yBAAA;EACA,gBAAA;AALJ;AAQE;EACE,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,QAAA;EACA,WAAA;AANJ;AAOI;EACE,YAAA;EACA,kBAAA;EACA,4CAAA;EACA,iFAAA;AALN","sourcesContent":[".tab {\n  cursor: default;\n  position: absolute;\n  top: -1.5em;\n\n  border: 0;\n  border-radius: 1px 25px 1px 0px;\n  line-height: 1.5em;\n  white-space: nowrap;\n  font-size: 1.1em;\n  font-weight: 400;\n  background-color: #a3b9d9;\n  padding: 0 0.9em 0 1.1em;\n  justify-content: space-between;\n\n  width: 200px;\n  box-sizing: border-box;\n\n  //   max-width: 20%;\n  //   min-width: fit-content;\n  .container {\n    overflow-x: hidden;\n    text-overflow: ellipsis;\n  }\n  &:first-of-type {\n  }\n  &:focus {\n    outline: none;\n  }\n  &.selected {\n    background-color: #f5f5f5;\n    opacity: 1;\n    color: #567db8;\n    border: 1px solid #a3b9d9;\n    border-bottom: 0;\n    // overflow-x: visible;\n  }\n  .tab-close-btn {\n    margin-left: 1em;\n    width: 1.5em;\n    text-align: center;\n    cursor: pointer;\n    position: absolute;\n    right: 0;\n    z-index: 10;\n    &:hover {\n      opacity: 0.9;\n      border-radius: 8px;\n      background-color: rgba(128, 128, 128, 0.175);\n      box-shadow: rgba(0, 0, 0, 0.118) 0px 19px 38px, rgba(0, 0, 0, 0.09) 0px 15px 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

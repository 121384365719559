import {Formik} from "formik";

import useUpdateTab from "../../../SamplesListNew/tabs/useUpdateTab";

import useEditKitTab from "./useEditKitTab";

const EditKitTabBase = () => {
  const {typeForm} = useEditKitTab();

  useUpdateTab({typeForm});

  return <div>EditKitTab</div>;
};

const EditKitTab = () => {
  const {validationSchema, initialValues} = useEditKitTab();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
    >
      <EditKitTabBase />
    </Formik>
  );
};

export default EditKitTab;

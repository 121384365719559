import {Field, useField} from "formik";
import PropTypes from "prop-types";

import "./utils/fieldWithSample.scss";

const FieldWithSample = ({label, children, labelWrap, ...props}) => {
  const [field] = useField(props);
  return (
    <div className={`fieldWithSample ${labelWrap ? "" : "autolabel"}`} style={props.style}>
      <label className="label " style={{display: "flex", whiteSpace: "nowrap"}}>
        <Field {...props} type="checkbox" style={{marginBottom: "4px"}} />
        {label && <span>{label}</span>}
      </label>
      <div className={field.value ? "" : "fieldWithSample__disabled"} style={{width: "100%"}}>
        {children}
      </div>
    </div>
  );
};

FieldWithSample.propTypes = {
  children: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  props: PropTypes.array,
};

export default FieldWithSample;

import {Form, Formik} from "formik";
import {t} from "i18next";

import Divider from "../../../utilities/Divider/Divider";
import useSampleFilter from "../../Filters/useSampleFilter";
import {DateInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";
import FilterButton from "../../SamplesList/SampleParts/FilterButton";

const TransferListFilters = ({storageKey}) => {
  const initialValues = {dateFrom: "", dateTo: "", status: "", term: ""};

  function fetchData() {}
  function removeFilter({setValues}) {
    setValues(initialValues);
  }

  const {statusOptions, areFiltersDefined, onFilter, onDelFilters} = useSampleFilter(
    fetchData,
    removeFilter,
    storageKey,
  );

  return (
    <Formik initialValues={initialValues} validateOnChange={true} validateOnBlur={true} enableReinitialize>
      {({values, setValues}) => (
        <Form className="" style={{width: "100%"}}>
          <div
            style={{
              gap: "1em",
              //   alignItems: "flex-start",
              width: "100%",
            }}
            className="fc jcfs autolabel nowrap aic"
          >
            <div className="flex_container" style={{flexDirection: "column"}}>
              <FilterButton
                onFilter={() => onFilter(values)}
                delFilter={() => onDelFilters(values, setValues)}
                questionTitle={t("Filter")}
                areFiltersDefined={areFiltersDefined}
              />
              {/* {children} */}
            </div>
            <div className="row jcsb w100 gap">
              <div className="row gap">
                <span>{t("Execution term")}</span>
                <DateInput name="dateFrom" label="From" />
                <DateInput name="dateTo" label="To" />
              </div>

              <Select name="status" label={t("Status ")} options={statusOptions ?? []} />
              <DateInput name="term" label="Term" />
            </div>
          </div>
          <Divider />
        </Form>
      )}
    </Formik>
  );
};

export default TransferListFilters;

export const SAMPLE_TABS_INITIAL_VALUES = Object.freeze({
  factoryId: "",
  skuNumber: "",
  groupId: "",
  subgroupId: "",
  materialId: "",
  categoryId: "",
  textureId: "",
  collection: "",
  color: "",
  name: "",
  state: "",
  sampleImages: [],
  sampleQrCodeUrl: "",
});

export const NO_KIT_SAMPLE_TABLE_COLUMNS = [
  {
    header: "№",
    accessorKey: "number",
    size: 60,
  },
  {
    header: "SKU №",
    accessorKey: "skuNumber",
    // size: 100,
    isSortable: true,
  },
  {
    header: "Factory",
    accessorKey: "factory",
    // size: 15,
  },
  {
    header: "col",
    accessorKey: "collection",
    // size: 100,
    isSortable: true,
  },
  {
    header: "Color",
    accessorKey: "color",
    // size: 100,
    isSortable: true,
  },
  {
    header: "texture",
    accessorKey: "texture",
    // size: 100,
    isSortable: true,
  },
  {
    header: "Nomenclature",
    accessorKey: "name",
    // size: 100,
    isSortable: true,
  },
  {
    header: "Smp g",
    accessorKey: "group",
    // size: 100,
    isSortable: true,
  },
  {
    header: "sgr",
    accessorKey: "subgroup",
    size: 100,
    isSortable: true,
  },
  {
    header: "material",
    accessorKey: "material",
    // size: 100,
    isSortable: true,
  },
  {
    header: "categ",
    accessorKey: "category",
    // size: 100,
    isSortable: true,
  },

  {
    header: "Photo",
    accessorKey: "samplePhoto",
    size: 70,
  },

  {
    header: "BM",
    accessorKey: "brandManager",
    // size: 100,
    isSortable: true,
  },
  {
    header: "acc m",
    accessorKey: "manager",
    // size: 100,
    isSortable: true,
  },
  {
    header: "Note",
    accessorKey: "state",
    // size: 100,
  },

  {
    header: "Date from",
    accessorKey: "dateFrom",
    size: 100,
    isSortable: true,
  },
  {
    header: "Date to",
    accessorKey: "dateTo",
    size: 100,
    isSortable: true,
  },
  {
    header: "Status*",
    accessorKey: "status",

    isSortable: true,
  },

  {
    header: "ds",
    accessorKey: "skuDate",
    size: 170,
    isSortable: true,
  },
];

import React from "react";

const PencilIcon = ({color1 = "#567db8", color2 = "none", style, disabled, ...props}) => (
  <div
    style={{
      boxSizing: "content-box",
      cursor: disabled ? "not-allowed" : "pointer",
      pointerEvents: disabled ? "none" : "all",
      opacity: disabled ? "0.5" : "1",
    }}
  >
    <svg
      viewBox="0 0 256 256"
      xmlns="http://www.w3.org/2000/svg"
      style={{width: "2em", height: "2em", ...style}}
      {...props}
    >
      <path fill={color2} d="M0 0h256v256H0z"></path>
      <path
        d="M216 120v88a8 8 0 0 1-8 8H48a8 8 0 0 1-8-8V48a8 8 0 0 1 8-8h88"
        fill={color2}
        stroke={color1}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
        className="stroke-000000"
      ></path>
      <path
        fill={color2}
        stroke={color1}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
        d="M128 160H96v-32l96-96 32 32-96 96zM168 56l32 32"
        className="stroke-000000"
      ></path>
    </svg>
  </div>
);

export default PencilIcon;

import {useTranslation} from "react-i18next";

import i18n from "../../i18n";
import FunctionButton from "../../utilities/Buttons/FunctionButton";
// import { useState } from "react";
// import { CreateSKU } from "../SKU/VED/CreateSKU";

const TranslationButton = () => {
  const {t} = useTranslation();

  const changeLanguage = language => {
    i18n.changeLanguage(language, err => {
      if (err) return;
      // i18n.t
    });
  };

  // const [modal, setModal] = useState(false);

  return (
    <div className="flex_container jcc fd" style={{margin: "15px"}}>
      {t("Translate into")}
      <div style={{margin: "15px"}}>
        <FunctionButton style={{margin: "15px"}} name={t("English")} onClick={() => changeLanguage("en")} />

        <FunctionButton name={t("Ukrainian")} onClick={() => changeLanguage("uk")} />
      </div>
    </div>
  );
};

export default TranslationButton;

export const KIT_TYPE_FORMS = Object.freeze({
  CREATE_INSTANCE: "CREATE_INSTANCE",
  UPDATE_INSTANCE: "UPDATE_INSTANCE",
  CREATE_KIT: "CREATE_KIT",
  UPDATE_KIT: "UPDATE_KIT",
  VIEW_KIT: "VIEW_KIT",
  VIEW_INSTANCE: "VIEW_INSTANCE",
  CREATE_SAMPLE: "CREATE_SAMPLE",
  EDIT_SAMPLE: "EDIT_SAMPLE",
  VIEW_SAMPLE: "VIEW_SAMPLE",
  TRANSFER_SAMPLE_TO_KIT: "TRANSFER_SAMPLE_TO_KIT",
});

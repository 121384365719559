import {createAsyncThunk} from "@reduxjs/toolkit";

import {fetchData, fetchList} from "../../api/axios.hook";
import {checkEmptyFields} from "../../utilities/checkEmptyFields";

//PATHs
const GROUPS_PATH = "sampleGroups";
const SUBGROUPS_PATH = "sampleSubGroups";
const CATEGORIES_PATH = "categories";
const MATERIALS_PATH = "materials";
const TEXTURES_PATH = "textures";

//*** Sample Groups ***
//get
export const fetchAllGroups = createAsyncThunk("samples/fetchAllGroups", async () =>
  fetchList(`${GROUPS_PATH}/getAll`, "get"),
);
//post
export const addGroup = createAsyncThunk("samples/addGroup", async data =>
  fetchData(`${GROUPS_PATH}/add`, "post", data),
);
//patch
export const updateGroup = createAsyncThunk("samples/updateGroup", async ({groupId, data}) =>
  fetchData(`${GROUPS_PATH}/update/${groupId}`, "patch", data),
);
//delete
export const deleteGroup = createAsyncThunk("samples/deleteGroup", async groupId =>
  fetchData(`${GROUPS_PATH}/remove/${groupId}`, "delete"),
);

//* Sample Subgroups *
//get
export const fetchAllSubGroups = createAsyncThunk("samples/fetchAllSubGroups", async groupId =>
  fetchList(`${SUBGROUPS_PATH}/getAll`, "get", {params: {groupId}}),
);
//post
export const addSubGroup = createAsyncThunk("samples/addSubGroup", async ({groupId, data}) =>
  fetchData(`${SUBGROUPS_PATH}/add/${groupId}`, "post", data),
);
//patch
export const updateSubGroup = createAsyncThunk("samples/updateSubGroup", async ({subgroupId, data}) =>
  fetchData(`${SUBGROUPS_PATH}/update/${subgroupId}`, "patch", data),
);
//delete
export const deleteSubGroup = createAsyncThunk("samples/deleteSubGroup", async subgroupId =>
  fetchData(`${SUBGROUPS_PATH}/remove/${subgroupId}`, "delete"),
);

//*** Materials ***
//get
export const fetchAllMaterials = createAsyncThunk("samples/fetchAllMaterials", async () =>
  fetchList(`${MATERIALS_PATH}/getAll`, "get"),
);
//post
export const addMaterial = createAsyncThunk("samples/addMaterial", async data =>
  fetchData(`${MATERIALS_PATH}/add`, "post", data),
);
//patch
export const updateMaterial = createAsyncThunk("samples/updateMaterial", async ({materialId, data}) =>
  fetchData(`${MATERIALS_PATH}/update/${materialId}`, "patch", data),
);
//delete
export const deleteMaterial = createAsyncThunk("samples/deleteMaterial", async materialId =>
  fetchData(`${MATERIALS_PATH}/remove/${materialId}`, "delete"),
);

//* Categories *
//get
export const fetchAllCategories = createAsyncThunk("samples/fetchAllCategories", async materialId =>
  fetchList(`${CATEGORIES_PATH}/getAll`, "get", {params: {materialId}}),
);
//post
export const addCategory = createAsyncThunk("samples/addCategory", async ({materialId, data}) =>
  fetchData(`${CATEGORIES_PATH}/add/${materialId}`, "post", data),
);
//patch
export const updateCategory = createAsyncThunk("samples/updateCategory", async ({categoryId, data}) =>
  fetchData(`${CATEGORIES_PATH}/update/${categoryId}`, "patch", data),
);
//delete
export const deleteCategory = createAsyncThunk("samples/deleteCategory", async categoryId =>
  fetchData(`${CATEGORIES_PATH}/remove/${categoryId}`, "delete"),
);

//*** Textures ***
//get
export const fetchAllTextures = createAsyncThunk("samples/fetchAllTextures", async () =>
  fetchList(`${TEXTURES_PATH}/getAll`, "get"),
);
//post
export const addTexture = createAsyncThunk("samples/addTexture", async data =>
  fetchData(`${TEXTURES_PATH}/add`, "post", data),
);
//patch
export const updateTexture = createAsyncThunk("samples/updateTexture", async ({textureId, data}) =>
  fetchData(`${TEXTURES_PATH}/update/${textureId}`, "patch", data),
);
//delete
export const deleteTexture = createAsyncThunk("samples/deleteTexture", async textureId =>
  fetchData(`${TEXTURES_PATH}/remove/${textureId}`, "delete"),
);

export const fetchCurrentTableSample = createAsyncThunk("samples/fetchCurrentTableSample", async ({sampleId}) =>
  fetchData(`/samples/get/${sampleId}`, "get"),
);

//sample transfer
export const transferSampleToKit = createAsyncThunk("samples/transferSampleToKit", async ({kitId, sampleIds}) =>
  fetchData(`/samples/addToKit/${kitId}`, "patch", {sampleIds}),
);

export const removeSampleFromKit = createAsyncThunk("samples/removeSampleFromKit", async ({sampleIds}) =>
  fetchData(`/samples/removeFromKit`, "patch", {sampleIds}),
);

import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik} from "formik";
import Swal from "sweetalert2";

import useColumns from "../../../hook/useColumns";
import {
  clearSamples,
  deleteSample,
  getAllSamples,
  getSample,
  removeSampleFromKit,
  selectAllKits,
  selectAllSamples,
  selectSamplesLoading,
  selectSamplesPage,
  selectSamplesTotalPageas,
} from "../../../slices/SampleFunctionalSlice";
import {selectCurrentKitId} from "../../../slices/SampleSlice/selectors";
import {
  addPageTab,
  getCurrentUser,
  getError,
  getInfo,
  getSuccess,
  selectPageTabs,
  setCurentTabIndex,
  setPageTabs,
  updatePageTabProps,
} from "../../../slices/UserSlice";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import ControlButtons from "../../common/controlButtons/ControlButtons";
import ReactTable from "../../conteiners/ReactTable";
import ControlledTextInput from "../../Inputs/ControlledTextInput";
import ActionWithChosen from "../../KitsList/ActionWithChosen/ActionWithChosen";
import ModalForm from "../../Modal/FormModal";
import {SampleListLowerColumns} from "../../SamplesList/constants/columns";
import samplesTableDataService from "../../SamplesList/constants/samplesTableDataService";
import SamplePreview from "../../SamplesList/previewModal/SamplePreview";
import {TYPE_FORMS} from "../../SamplesList/SamplesList";
import ContextMenuButtons from "../ContextMenuButtons";

const KitSamplesTable = ({
  currentSample,
  setCurrentSample,
  menuVisible,
  menuPosition,
  handleContextMenu,
  contextMenuType,
}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const allSamples = useSelector(selectAllSamples),
    totalPages = useSelector(selectSamplesTotalPageas),
    currPage = useSelector(selectSamplesPage);

  const currUser = useSelector(getCurrentUser);
  const currentKitId = useSelector(selectCurrentKitId);

  const tabs = useSelector(selectPageTabs);
  const allKits = useSelector(selectAllKits);
  const sampleLoading = useSelector(selectSamplesLoading);

  const lowerTableColumns = useColumns(SampleListLowerColumns);

  const [currentKit, setCurrentKit] = useState(null);
  const [samplePrewiewModalShow, setSamplePrewiewModalShow] = useState(false);

  const instanceTableData = samplesTableDataService(allSamples);

  const initialValues = useMemo(() => {
    if (currentSample) {
      const sample = allSamples?.find(s => s.sampleId === currentSample.sampleId);
      return {
        factoryId: sample?.factoryId || null,
        skuNumber: sample?.skuNumber || "",
        groupId: sample?.groupId || null,
        subgroupId: sample?.subgroupId || null,
        materialId: sample?.materialId || null,
        categoryId: sample?.categoryId || null,
        textureId: sample?.textureId || null,
        collection: sample?.collection || "",
        color: sample?.color || "",
        name: sample?.name || "",
        state: sample?.state || "",
        kitSku: sample?.kit?.skuNumber || "",
        setDescArea: sample?.kit?.name || "",
        brandManager: currentKit?.brandManager
          ? currentKit.brandManager.firstName + " " + currentKit.brandManager.lastName
          : "",
      };
    }
    return null;
  }, [currentSample, allSamples, currentKit]);

  const emptyInitialValues = useMemo(
    () => ({
      factoryId: null,
      skuNumber: "",
      groupId: null,
      subgroupId: null,
      materialId: null,
      categoryId: null,
      textureId: null,
      collection: "",
      color: "",
      name: "",
      state: "",
      kitSku: currentKit?.skuNumber || "",
      setDescArea: currentKit?.name || "",
      brandManager: currentKit?.brandManager
        ? currentKit.brandManager.firstName + " " + currentKit.brandManager.lastName
        : "",
    }),
    [currentKit],
  );

  useEffect(() => {
    if (currentKitId) {
      let kit = allKits.find(kit => kit.kitId === currentKitId);
      setCurrentKit(kit);
      dispatch(getAllSamples({kitId: currentKitId, page: 1}));
    } else {
      dispatch(clearSamples());
      setCurrentKit(null);
    }
  }, [currentKitId, allKits]);

  const isOwn = currentSample?.brandManager?.userId === currUser?.userId || currUser?.role?.roleId === 3;

  const closeSampleTabs = sampleId => {
    const filteredtabs =
      tabs?.filter(tab => {
        return tab.props?.sample?.sampleId !== sampleId && tab.props?.sampleId !== sampleId;
      }) || tabs;
    dispatch(setPageTabs(filteredtabs));
  };

  const onCreateSample = async () => {
    setCurrentSample(null);

    const existIndex = tabs.findIndex(tab => tab.formType === TYPE_FORMS.CREATE_KIT_SAMPLE);
    if (existIndex === -1) {
      setCurrentSample(null);
      dispatch(
        addPageTab({
          label: t("create k s"),
          askOnClose: true,
          controlled: true,
          formType: TYPE_FORMS.CREATE_KIT_SAMPLE,
          props: {
            kitId: currentKit.kitId,
            formInitialValues: emptyInitialValues,
          },
        }),
      );
    } else if (existIndex !== -1 && tabs[existIndex].props.kit.kitId === currentKit.kitId) {
      dispatch(setCurentTabIndex(existIndex + 1));
    } else {
      getInfo(t("The sample creation tab in the context of another Kit is already open"));
    }
  };

  function onEditSampleFunc({isSampleEditOpen}) {
    if (currentSample?.statusId !== 1) {
      Swal.fire({
        title: "",
        text: t("forbidd edit"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
      return;
    } else if (currentSample?.statusId === 1 && (isOwn || currUser?.role?.roleId === 3)) {
      if (!isSampleEditOpen) {
        dispatch(
          addPageTab({
            label: `${t("edit s")} №${currentSample?.skuNumber ?? ""}`,
            controlled: true,
            askOnClose: true,
            formType: TYPE_FORMS.UPDATE_KIT_SAMPLE,
            props: {
              sample: {...currentSample},
              kitId: currentKit.kitId,
              formInitialValues: initialValues,
            },
          }),
        );
      } else {
        dispatch(
          updatePageTabProps({
            pageType: TYPE_FORMS.UPDATE_KIT_SAMPLE,
            props: {
              sample: {...currentSample},
              kitId: currentKit.kitId,
              formInitialValues: initialValues,
            },
          }),
        );
      }
      dispatch(getSample(currentSample.sampleId)).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          setCurrentSample(res.payload);
        }
      });
    } else {
      Swal.fire({
        title: "",
        text: t("forbid edit not own"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
    }
  }

  const onEditSample = () => {
    const exist = tabs.find(tab => tab.formType === TYPE_FORMS.UPDATE_KIT_SAMPLE);
    if (exist && exist.props.sample.sampleId === currentSample?.sampleId) {
      getError("The sample has already been opened for editing");
      return;
    } else if (exist) {
      Swal.confirm({
        title: "You can open only one sample form editting",
        confirmFunc: () => {
          onEditSampleFunc({isSampleEditOpen: true});
        },
      });
    } else {
      onEditSampleFunc({isSampleEditOpen: false});
    }
  };

  const onInstanceDelete = () => {
    if (currentSample?.statusId !== 1) {
      Swal.fire({
        title: "",
        text: t("forbidd"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
      return;
    } else if (currentSample?.statusId === 1 && isOwn) {
      Swal.fire({
        title: "",
        text: t("del sample"),
        icon: "info",
        confirmButtonText: t("Continue"),
        showCancelButton: true,
        cancelButtonText: t("Cancel"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      }).then(answ => {
        if (answ.isConfirmed) {
          dispatch(deleteSample(currentSample.sampleId)).then(resp => {
            if (resp.meta.requestStatus === "fulfilled") {
              getSuccess("Sample deleted");
              closeSampleTabs(currentSample.sampleId);
            }
          });
          setCurrentSample(null);
        }
      });
    } else {
      Swal.fire({
        title: "",
        text: t("forbid not own"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
    }
  };

  const onDublicateSample = () => {
    if (!isOwn) {
      Swal.fire({
        title: "",
        text: t("forbit to dubl"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
    } else {
      Swal.fire({
        title: "",
        text: "Внесіть зміни до зразка та збережіть",
        icon: "info",
        confirmButtonText: t("Ok"),
        showCancelButton: false,
      });
      dispatch(
        addPageTab({
          label: `${t("dubl sample btn")} №${currentSample?.skuNumber || ""}`,
          formType: TYPE_FORMS.DUBLICATE_KIT_SAMPLE,
          controlled: true,
          askOnClose: true,
          props: {
            kitId: currentKit.kitId,
            baseSample: currentSample,
            formInitialValues: initialValues,
          },
        }),
      );
    }
  };

  const onInstanceSelect = id => {
    if (currentSample && id === currentSample.sampleId) {
      setCurrentSample(null);
      return;
    }

    const currSamp = allSamples?.find(item => item.sampleId === id);
    setCurrentSample(currSamp);
  };

  const onUnlinkSample = () => {
    if (currentSample?.statusId !== 1) {
      Swal.fire({
        title: "",
        text: t("forbidd"),
        icon: "info",
        confirmButtonText: t("Ok"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      });
      return;
    } else if (currentSample?.statusId === 1 && isOwn) {
      Swal.fire({
        title: "",
        text: t("Unlink sample from kit?"),
        icon: "info",
        confirmButtonText: t("Yes"),
        showCancelButton: true,
        cancelButtonText: t("Cancel"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      }).then(answ => {
        if (answ.isConfirmed) {
          // const filteredtabs =
          //   tabs?.filter(tab => {
          //     return (
          //       tab.props?.sample?.sampleId !== currentSample.sampleId && tab.props?.sampleId !== currentSample.sampleId
          //     );
          //   }) || tabs;
          dispatch(removeSampleFromKit({sampleIds: [currentSample.sampleId]})).then(resp => {
            if (resp.meta.requestStatus === "fulfilled") {
              getSuccess("Sample deleted from kit");
              closeSampleTabs(currentSample.sampleId);
              // dispatch(setPageTabs(filteredtabs));
            }
          });
          setCurrentSample(null);
        }
      });
    }
  };

  function onViewSample() {
    const viewTabIndex = tabs.findIndex(tab => tab.formType === TYPE_FORMS.VIEW_KIT_SAMPLE);

    if (viewTabIndex > -1) {
      dispatch(
        updatePageTabProps({
          pageType: TYPE_FORMS.VIEW_KIT_SAMPLE,
          label: `${t("view s")} №${currentSample?.skuNumber ?? ""}`,
          props: {kitId: currentKit.kitId, sampleId: currentSample.sampleId},
        }),
      );
    } else {
      dispatch(
        addPageTab({
          label: `${t("view s")} №${currentSample?.skuNumber ?? ""}`,
          // controlled: true,
          formType: TYPE_FORMS.VIEW_KIT_SAMPLE,
          props: {kitId: currentKit.kitId, sampleId: currentSample.sampleId},
        }),
      );
    }
  }

  const onRowDoubleClick = row => {
    const currSamp = allSamples?.find(item => item.sampleId === row.id);
    setCurrentSample(currSamp);
    setSamplePrewiewModalShow(true);
  };

  const pagination = async () => {
    if (totalPages === currPage || totalPages === 1) return;
    dispatch(getAllSamples({kitId: currentKitId, page: currPage + 1}));
  };

  const buttonsSettings = {
    add: {
      onClick: onCreateSample,
      tolltip: t("add sample btn"),
      disabled: !currentKitId || currentKit?.brandManagerId !== currUser?.userId,
    },
    edit: {
      onClick: onEditSample,
      tolltip: "edit sample btn",
      disabled: !currentSample || !isOwn,
    },
    view: {onClick: onViewSample, tolltip: t("view sample btn"), disabled: !currentSample},
    dublicate: {
      onClick: onDublicateSample,
      tolltip: t("dubl sample btn"),
      disabled: !currentSample || !isOwn,
    },
    unlink: {
      onClick: onUnlinkSample,
      tolltip: t("unlink sample btn"),
      disabled: !currentSample || !isOwn,
    },
    del: {
      onClick: onInstanceDelete,
      tolltip: t("del sample btn"),
      disabled: !currentSample || !isOwn,
    },
  };

  return (
    <div className="fGrow fShrink fcCol jcsb overflowAuto">
      <ReactTable
        defaultData={instanceTableData}
        columns={lowerTableColumns}
        onSelect={onInstanceSelect}
        current={currentSample?.sampleId || null}
        className={"scrollX fGrow fShrink"}
        tableClassName="tableCenterText"
        style={{
          marginBottom: "10px",
          maxHeight: "unset",
          height: "79%",
        }}
        onRowDoubleClick={onRowDoubleClick}
        onPaginate={pagination}
        paginatable={true}
        loading={sampleLoading}
        handleContextMenu={handleContextMenu}
        selectOnRightBtn={true}
      />

      <div className="fdr jcsb mt aic mb">
        {/* <div className="inv">
          <ControlledTextInput label={t("inv date")} name="invDate" readOnly labelStyles={{width: "12em"}} />
          <FunctionButton name="inv" />
        </div> */}
        <Formik initialValues={{}} validateOnChange={true} validateOnBlur={true} enableReinitialize>
          <Form>
            <ActionWithChosen />
          </Form>
        </Formik>

        {contextMenuType === "SAMPLE" && (
          <ContextMenuButtons settings={buttonsSettings} menuVisible={menuVisible} menuPosition={menuPosition} />
        )}
        <div className="fc gap aic">
          {/* <div className="tac">{t("Sample")}</div> */}
          <ControlButtons settings={buttonsSettings} className={"middle_buttons"} />
        </div>
      </div>
      <ModalForm
        closable={true}
        active={samplePrewiewModalShow}
        Component={SamplePreview}
        setModalActive={setSamplePrewiewModalShow}
        label={"SKU №" + currentSample?.skuNumber || ""}
        sample={currentSample}
        // typeForm={formType}
        // className="uni_modal"
      />
    </div>
  );
};

export default KitSamplesTable;

import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2";

import {addNewKit, addNewSample, addSampleToNewKit, selectKit} from "../../../slices/SampleFunctionalSlice";
import {setCurrentTableSample, setNoNewSample} from "../../../slices/SampleSlice/SampleSlice";
import {getCurrentUser, getInfo} from "../../../slices/UserSlice";
import {makeFilterManager} from "../../../utilities/makeFilterManager/makeFilterManager";
import {withSampleMod} from "../constants/SampleHOC";
import {SAMPLES_FILTERS_STORAGE_KEY} from "../SampleParts/SampleFilter/utils/constants";
import {SAMPLE_UNI_MOD_INITIAL_INSTANCE_VALUES} from "../SampleUniModal/SampleUniMod";

const KitSampleCreateModal = ({typeForm}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const kit = useSelector(selectKit);
  const currUser = useSelector(getCurrentUser);
  const SampleFilterManager = makeFilterManager(SAMPLES_FILTERS_STORAGE_KEY);

  const onSetClick = (data, setFieldValue) => {
    dispatch(addNewKit(data)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        getInfo(t("kit added"));
        setFieldValue("kitSku", res.payload.skuNumber);
        SampleFilterManager.setFilters({kitId: res.payload.kitId});
      }
    });
  };

  const onInstanceClick = (data, setFieldValue) => {
    dispatch(addNewSample(data)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        getInfo(t("ins added"));
        setFieldValue("skuNumber", res.payload.skuNumber);
        dispatch(addSampleToNewKit(res.payload));
        dispatch(setCurrentTableSample({typeForm, sample: res.payload}));
      }
    });
  };

  const onSetCancel = (data, setValues, resetForm) => {
    resetForm();
  };

  const onInstanceCancel = (data, setValues, resetForm) => {
    Swal.fire({
      title: "",
      text: t("del data"),
      icon: "question",
      confirmButtonText: t("Yes"),
      showCancelButton: true,
      cancelButtonText: t("No"),

      customClass: {
        popup: "zindex",
        container: "zindex",
        htmlContainer: "zindex",
      },
    }).then(answ => {
      if (answ.isConfirmed) {
        setValues(prev => ({...prev, ...SAMPLE_UNI_MOD_INITIAL_INSTANCE_VALUES}));
        dispatch(setNoNewSample({typeForm}));
      }
    });
  };

  const SampleForm = withSampleMod(
    t("Save"),
    t("Save"),
    onSetClick,
    onInstanceClick,
    onSetCancel,
    onInstanceCancel,
    true,
  );
  return <SampleForm typeForm={typeForm} />;
};

export default KitSampleCreateModal;

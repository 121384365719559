import {withFormik} from "formik";
import {t} from "i18next";

import PageContainer from "../../conteiners/PageContainer";
import ReactTable from "../../conteiners/ReactTable";
import ActionWithChosen from "../ActionWithChosen/ActionWithChosen";
import TransferListFilters from "../TransferListFilters/TransferListFilters";

import useSamplesTransferList from "./useSamplesTransferList";

const SamplesTransferList = () => {
  const {tableColumns, tableData, onExecuteActionBtnClick} = useSamplesTransferList();

  return (
    <PageContainer title={t("Samples transfer register")} className="fcCol pb">
      <TransferListFilters storageKey="SamplesTransferList" />
      <ReactTable columns={tableColumns} defaultData={tableData} current={null} />
      <ActionWithChosen onExecuteActionBtnClick={onExecuteActionBtnClick} />
    </PageContainer>
  );
};

export default withFormik({enableReinitialize: true, mapPropsToValues: () => ({})})(SamplesTransferList);

import {withFormik} from "formik";
import {t} from "i18next";

import Divider from "../../../utilities/Divider/Divider";
import PageContainer from "../../conteiners/PageContainer";
import ReactTable from "../../conteiners/ReactTable";
import ActionWithChosen from "../ActionWithChosen/ActionWithChosen";
import TransferListFilters from "../TransferListFilters/TransferListFilters";

import useKitsTransferList from "./useKitsTransferList";

const KitsTransferList = () => {
  const {upperTableColumns, upperTableData, lowerTableColumns, lowerTableData, onExecuteActionBtnClick} =
    useKitsTransferList();

  return (
    <PageContainer title={t("Kits transfer register")} className="fcCol pb">
      <TransferListFilters storageKey="KitsTransferList" />
      <ReactTable columns={upperTableColumns} defaultData={upperTableData} current={null} className={"scrollX"} />
      <Divider />
      <ReactTable columns={lowerTableColumns} defaultData={lowerTableData} current={null} className={"scrollX"} />
      <ActionWithChosen onExecuteActionBtnClick={onExecuteActionBtnClick} />
    </PageContainer>
  );
};

export default withFormik({enableReinitialize: true, mapPropsToValues: () => ({})})(KitsTransferList);

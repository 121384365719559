import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useFormikContext} from "formik";

import {updateModalData} from "../../../slices/SampleSlice/SampleSlice";

const useUpdateTab = ({typeForm, sampleId = null, dataToSave = {}}) => {
  const dispatch = useDispatch();
  const {setValues} = useFormikContext();

  useEffect(() => {
    return () => {
      setValues(prev => {
        dispatch(
          updateModalData({
            typeForm,
            formData: {...prev, sampleId, ...dataToSave},
          }),
        );
        return prev;
      });
    };
  }, [sampleId]);

  return {};
};

export default useUpdateTab;

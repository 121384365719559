import {useSelector} from "react-redux";

import useColumns from "../../../../hook/useColumns";
import {selectAllKits, selectAllSamples} from "../../../../slices/SampleFunctionalSlice";
import {selectCurrentKitId, selectCurrentSampleId} from "../../../../slices/SampleSlice/selectors";
import {SampleUniModTableColumns} from "../../../SamplesList/constants/columns";
import samplesForKitModalDataService from "../../../SamplesList/constants/samplesForKitModalDataService";
import {TYPE_FORMS} from "../../../SamplesList/SamplesList";
import {formSampleValues, KIT_SAMPLE_TAB_INITIAL_VALUES} from "../../constants";

const useViewSamplesTab = () => {
  const typeForm = TYPE_FORMS.VIEW_KIT_SAMPLES;

  const allKits = useSelector(selectAllKits);
  const currentKitId = useSelector(selectCurrentKitId);
  const currentKit = allKits?.find(kit => kit.kitId === currentKitId);
  const kitImages = currentKit?.images ?? [];
  const allSamples = useSelector(selectAllSamples);
  const currentSampleId = useSelector(selectCurrentSampleId);
  const currentSample = allSamples?.find(sample => sample.sampleId === currentSampleId) ?? null;
  const sampleImgs = currentSample?.images ?? [];

  const initialValues = {
    ...KIT_SAMPLE_TAB_INITIAL_VALUES,
    kitSku: currentKit?.skuNumber ?? "",
    setDescArea: currentKit?.name ?? "",
    ...formSampleValues(currentSample),
    groupId: currentSample?.group?.groupName || "",
    subgroupId: currentSample?.subgroup?.subgroupName || "",
    materialId: currentSample?.material?.materialName || "",
    categoryId: currentSample?.category?.categoryName || "",
    textureId: currentSample?.texture?.textureName || "",
    factoryId: currentSample?.factory?.factoryName || "",
    brandManager: `${currentKit?.brandManager?.lastName ?? ""} ${currentKit?.brandManager?.firstName ?? ""}`,
  };

  const kitSamplesTableColumns = useColumns(SampleUniModTableColumns);
  const kitSamplesTableData = samplesForKitModalDataService(currentKit.samples) ?? [];

  return {
    typeForm,
    initialValues,
    kitImages,
    currentSample,
    sampleImgs,
    currentKit,
    kitSamplesTableColumns,
    kitSamplesTableData,
  };
};

export default useViewSamplesTab;

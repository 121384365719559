import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {fetchList} from "../../../../hook/axios.hook";
import useColumns from "../../../../hook/useColumns";
import {
  getAllSamples,
  selectAllKits,
  selectAllSamples,
  selectSamplesPage,
  selectSamplesTotalPageas,
} from "../../../../slices/SampleFunctionalSlice";
import {removeSampleFromKit, transferSampleToKit} from "../../../../slices/SampleSlice/operations";
import {clearCurrentTableSample, setCurrentSampleId, updateModalData} from "../../../../slices/SampleSlice/SampleSlice";
import {selectCurrentSampleId} from "../../../../slices/SampleSlice/selectors";
import useSwal from "../../../../utilities/hooks/useSwal";
import useToaster from "../../../../utilities/hooks/useToaster";
import {makeFilterManager} from "../../../../utilities/makeFilterManager/makeFilterManager";
import {SampleListLowerColumns, SampleListUpperColumns} from "../../../SamplesList/constants/columns";
import kitTableDataService from "../../../SamplesList/constants/kitTableDataService";
import samplesTableDataService from "../../../SamplesList/constants/samplesTableDataService";
import makeSamplesFilters from "../../../SamplesList/SampleParts/SampleFilter/utils/makeSamplesFilters";
import {TYPE_FORMS} from "../../../SamplesList/SamplesList";

export const TRANSFER_SAMPLE_TO_KIT_STORAGE_KEY = "transferSampleFilters";

const useTransferSampleToKitTab = ({setTabs = () => {}, setTabValue = () => {}}) => {
  const typeForm = TYPE_FORMS.TRANSFER_SAMPLE_TO_KIT;
  const dispatch = useDispatch();
  const Toaster = useToaster();
  const SWAL = useSwal();

  const TransferSampleFilterManager = makeFilterManager(TRANSFER_SAMPLE_TO_KIT_STORAGE_KEY);
  const filters = TransferSampleFilterManager.getFilters() ?? {};
  const transferSamplesFilters = makeSamplesFilters(filters);

  const allKits = useSelector(selectAllKits);
  const totalPages = useSelector(selectSamplesTotalPageas);
  const currPage = useSelector(selectSamplesPage);
  const allSamples = useSelector(selectAllSamples);
  const chosenSampleId = useSelector(selectCurrentSampleId);

  const chosenSample = allSamples?.find(sample => sample.sampleId === chosenSampleId);

  const initialValues = {
    brandManager: `${chosenSample?.brandManager?.lastName ?? ""} ${chosenSample?.brandManager?.firstName ?? ""}`,
  };

  const [allCurrentKitSamples, setAllCurrentKitSamples] = useState([]);
  const [currentKitId, setCurrentKitId] = useState(null);
  const currentKit = allKits.find(kit => kit.kitId === currentKitId);
  console.log("currentKit :", currentKit);
  const isKitOwn = currentKit?.brandManagerId === chosenSample?.brandManagerId;

  const [filteredKits, setFilteredKits] = useState(null);
  const isSampleTransfered = useSelector(state => state.samples.modalData[typeForm].isSampleTransfered);
  const transferSampleId = useSelector(state => state.samples.modalData[typeForm].transferSampleId);

  useEffect(() => {
    return () => {
      if (isSampleTransfered) {
        dispatch(setCurrentSampleId(null));
      }
    };
  }, []);

  const filterKitId = filters.kitId;
  useEffect(() => {
    if (filterKitId) {
      setFilteredKits(
        [...allKits].sort((a, b) => {
          if (a.kitId === filterKitId) return -1;
          if (b.kitId === filterKitId) return 1;
          return 0;
        }),
      );
    }
  }, [allKits.length]);

  //kit table
  const ktTableColumns = useColumns(SampleListUpperColumns);
  const kitTableData = kitTableDataService(filteredKits ?? allKits);
  function onKitSelect(kitId) {
    if (kitId === currentKitId) {
      setCurrentKitId(null);
      setAllCurrentKitSamples([]);
      return;
    }

    setCurrentKitId(kitId);
    fetchList(`samples/getAll`, "get", {params: {kitId, page: 1}}).then(resp => {
      setAllCurrentKitSamples(resp.samples);
    });
  }

  //sample table
  const [sampleTableData, setSampleTableData] = useState([]);

  useEffect(() => {
    setSampleTableData(samplesTableDataService(allCurrentKitSamples));
  }, [currentKitId, allCurrentKitSamples.length]);

  const sampleTableColumns = useColumns(SampleListLowerColumns);
  async function onPaginate() {
    if (totalPages === currPage || totalPages === 1) return;
    dispatch(getAllSamples({...transferSamplesFilters, page: currPage + 1}));
  }

  function onTransferSample() {
    if (!currentKitId || !currentKit) return;

    SWAL.confirm({
      title: "Sample will be transfered",
      confirmFunc: () => {
        dispatch(transferSampleToKit({kitId: currentKitId, sampleIds: [chosenSampleId ?? transferSampleId]})).then(
          resp => {
            if (resp.meta.requestStatus === "fulfilled") {
              Toaster.success("Sample transfered");
              dispatch(updateModalData({typeForm, formData: {isSampleTransfered: true}}));
              dispatch(updateModalData({typeForm, formData: {transferSampleId: chosenSampleId}}));
              dispatch(setCurrentSampleId(null));

              setSampleTableData(prev =>
                [...samplesTableDataService([{...chosenSample, kit: {skuNumber: currentKit?.skuNumber}}]), ...prev].map(
                  (sample, index) => {
                    sample.number = ++index;
                    return sample;
                  },
                ),
              );
            }
          },
        );
      },
    });
  }

  function onCancel() {
    console.log("chosenSampleId :", chosenSampleId);
    if (!chosenSampleId && !transferSampleId) return;

    SWAL.confirm({
      title: "Transformation will be canceled",
      confirmFunc: () => {
        dispatch(removeSampleFromKit({sampleIds: [chosenSampleId ?? transferSampleId]})).then(resp => {
          if (resp.meta.requestStatus === "fulfilled") {
            Toaster.success("Sample transformation canceled");
            dispatch(updateModalData({typeForm, formData: {isSampleTransfered: false}}));
            if (!chosenSampleId) {
              dispatch(setCurrentSampleId(transferSampleId));
            }
            setSampleTableData(prev => prev.filter(sample => sample.id !== chosenSampleId ?? transferSampleId));
            setTabValue(0);

            setTabs(prev => prev.filter(tab => tab.props.typeForm !== typeForm));
            dispatch(clearCurrentTableSample({typeForm}));
          }
        });
      },
    });
  }

  return {
    onCancel,
    onTransferSample,
    sampleTableData,
    sampleTableColumns,
    onPaginate,
    kitTableData,
    ktTableColumns,
    onKitSelect,
    currentKitId,
    filters,
    initialValues,
    isSampleTransfered,
    chosenSampleId,
    isKitOwn,
  };
};

export default useTransferSampleToKitTab;

export default function makeKitsFilters(filters) {
  if (!filters) return {};

  return {
    brandManagerId: filters.brandManagerId ?? "",
    managerId: filters.managerId ?? "",
    status: filters.status?.trim() ?? "",
    dateStart: filters.dateStart?.trim() ?? "",
    dateEnd: filters.dateEnd?.trim() ?? "",
    kitSkuNumber: filters.kitSkuNumber?.trim() ?? "",
  };
}

import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import Swal from "sweetalert2";

import useSelectOptions from "../../../../hook/useSelectOptions";
import {getStatusCatalog} from "../../../../slices/GeneralCatalogSlice";
import {getAllKits, getAllSamples} from "../../../../slices/SampleFunctionalSlice";
import {fetchAllCategories, fetchAllSubGroups} from "../../../../slices/SampleSlice/operations";
import {setCurrentKitId} from "../../../../slices/SampleSlice/SampleSlice";
import {selectCurrentKitId} from "../../../../slices/SampleSlice/selectors";
import {selectBrandManagers, selectManagers} from "../../../../slices/UserSlice";
import {checkEmptyFields} from "../../../../utilities/checkEmptyFields";
import {checkEmptyFilterFields} from "../../../../utilities/checkEmptyFilterFields";
import useToaster from "../../../../utilities/hooks/useToaster";
import {makeFilterManager} from "../../../../utilities/makeFilterManager/makeFilterManager";
import {trimFilters} from "../../../../utilities/trimFilters";

import {SAMPLES_FILTERS_STORAGE_KEY} from "./utils/constants";
import makeKitsFilters from "./utils/makeKitsFilters";
import makeSamplesFilters from "./utils/makeSamplesFilters";

export const STATUSES = Object.freeze({
  ON_STOCK: "On Stock",
  UNDER_REPORT: "Under Report",
});

const useSampleFilter = ({storageKey = SAMPLES_FILTERS_STORAGE_KEY, isFree}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const Toaster = useToaster();

  const brandManagers = useSelector(selectBrandManagers);
  const managers = useSelector(selectManagers),
    statuses = useSelector(getStatusCatalog);

  const currentKitId = useSelector(selectCurrentKitId);

  const SampleFilterManager = makeFilterManager(storageKey);

  const [areFiltersDefined, setFiltersDefined] = useState(false);
  const [savedFilters, setSavedFilters] = useState(null);

  useEffect(() => {
    let savedFilters_ = SampleFilterManager.getFilters();
    if (savedFilters_) {
      setFiltersDefined(savedFilters_ ? Boolean(Object.values(checkEmptyFields(savedFilters_)).length) : false);
      setSavedFilters(trimFilters(savedFilters_));
    }
  }, [areFiltersDefined, currentKitId]);

  useEffect(() => {
    if (!SampleFilterManager.getFilters()) setFiltersDefined(false);
  }, [(SampleFilterManager.getFilters() ?? []).length]);

  function calcSubGroupDisabled(values) {
    const selectedGroupId = values.groupId;

    return selectedGroupId === "";
  }

  function calcIsCategoryDisabled(values) {
    const selectedMaterial = values.materialId;

    return selectedMaterial === "";
  }

  function onGroupSelect(selectedGroupId) {
    if (selectedGroupId !== "") {
      dispatch(fetchAllSubGroups(selectedGroupId));
    }
  }

  function onMaterialSelect(selectedMaterialId) {
    if (selectedMaterialId !== "") {
      dispatch(fetchAllCategories(selectedMaterialId));
    }
  }

  function onFilter(values) {
    Toaster.success("Filters applied");

    if (values.dateEnd !== "" && values.dateStart !== "") {
      const formattedFromDate = moment(values.dateStart, "YYYY-MM-DD");
      const formattedEndDate = moment(values.dateEnd, "YYYY-MM-DD");
      if (formattedEndDate.isBefore(formattedFromDate)) {
        Swal.fire({
          title: "",
          text: t("date filters warn"),
          icon: "error",
        });
        return;
      }
    }
    SampleFilterManager.setFilters(checkEmptyFilterFields(values));
    setFiltersDefined(true);

    const samplesFilters = makeSamplesFilters(values);
    const filters = checkEmptyFields(samplesFilters);
    dispatch(getAllSamples({...filters, page: 1, isFree}));
    setFiltersDefined(true);
    const kitFilters = makeKitsFilters(values);
    if (Object.values(checkEmptyFields(kitFilters)).length !== 0 && !isFree) {
      dispatch(getAllKits(kitFilters));
    }
  }

  function onDelFilters(values) {
    Toaster.success("Filters deleted");

    SampleFilterManager.clearFilters();
    dispatch(setCurrentKitId(null));
    dispatch(getAllSamples({isFree, page: 1}));

    const kitFilters = makeKitsFilters(values);
    if (Object.values(checkEmptyFields(kitFilters)).length !== 0 && !isFree) {
      dispatch(getAllKits());
    }
    setSavedFilters(null);
    setFiltersDefined(false);
  }

  const initialValues = savedFilters ?? {
    factoryId: "",
    kitId: "",
    skuNumber: "",
    status: "",
    officeId: "",
    groupId: "",
    subgroupId: "",
    materialId: "",
    categoryId: "",
    textureId: "",
    collection: "",
    color: "",
    name: "",
    brandManagerId: "",
    managerId: "",
    dateStart: "",
    dateEnd: "",
  };

  const statusOptions =
    statuses?.map(status => ({
      title: status?.name,
      value: status?.statusId,
    })) || [];
  const brandManagerOptions =
    brandManagers?.map(brandManager => ({
      title: `${brandManager.lastName} ${brandManager.firstName}`,
      value: brandManager.userId,
    })) ?? [];

  const accManagerOptions =
    brandManagers
      ?.concat(managers)
      ?.map(manager => ({title: `${manager.lastName} ${manager.firstName}`, value: manager.userId})) ?? [];

  const {
    officesOptions,
    factoriesOptions,
    groupsOptions,
    subGroupsOptions,
    materialsOptions,
    texturesOptions,
    categoriesOptions,
  } = useSelectOptions();

  return {
    initialValues,
    factoriesOptions,
    statusOptions,
    officesOptions,
    groupsOptions,
    subGroupsOptions,
    materialsOptions,
    categoriesOptions,
    texturesOptions,
    brandManagerOptions,
    accManagerOptions,
    areFiltersDefined,
    onFilter,
    onDelFilters,
    onGroupSelect,
    onMaterialSelect,
    calcSubGroupDisabled,
    calcIsCategoryDisabled,
  };
};

export default useSampleFilter;

import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";

import Spinner from "../Components/spinner/Spinner";
import {
  fetchCountriesCatalog,
  fetchFactoriesCatalog,
  fetchOfficesCatalog,
  fetchRolesCatalog,
  fetchStatusCatalog,
} from "../slices/GeneralCatalogSlice";
import {fetchAllGroups, fetchAllMaterials, fetchAllTextures} from "../slices/SampleSlice/operations";
import {getAllUsers, getCurrentUser, getUser} from "../slices/UserSlice";

const IndexPage = () => {
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const token = useSelector(state => state.user.token);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getUser()).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        setLoading(false);
      }
    });
  }, [token]);

  useEffect(() => {
    if (token && user) {
      dispatch(fetchOfficesCatalog());
      dispatch(fetchRolesCatalog());
      dispatch(fetchCountriesCatalog());
      dispatch(fetchFactoriesCatalog());
      dispatch(getAllUsers());
      dispatch(fetchAllGroups());
      dispatch(fetchAllMaterials());
      dispatch(fetchAllTextures());
      dispatch(fetchStatusCatalog());
    }
  }, [token, user]);

  if (loading) return <Spinner />;

  if (!user?.role || user?.role?.roleId === 2) {
    return <Navigate to="/accessdenied" replace />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default IndexPage;

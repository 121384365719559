import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import {DateInput} from "../../Inputs";
import {Select} from "../../Inputs/SelectField";

const ActionWithChosen = ({onOkBtnClick = () => {}}) => {
  const actionOptions = [];
  const reportManagersOptions = [];

  return (
    <div className="row gap aic">
      <Select label="Action with chosen" name="actionId" options={actionOptions ?? []} optionsAbove />
      <Select label="report manager" name="reportManagerId" options={reportManagersOptions ?? []} optionsAbove />
      <DateInput name="actionDate" noLabel />
      <FunctionButton name="Ok" onClick={onOkBtnClick} autoWidth />
    </div>
  );
};

export default ActionWithChosen;

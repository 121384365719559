import {useTranslation} from "react-i18next";
import {Form, Formik, useFormikContext} from "formik";

import {
  addNewKitImages,
  addNewSampleImages,
  deleteKitImage,
  deleteSampleImage,
} from "../../../../slices/SampleFunctionalSlice";
import Divider from "../../../../utilities/Divider/Divider";
import {TextInput} from "../../../Inputs";
import KitSampleTableModalPart from "../../../SamplesList/SampleUniModal/SampleUniModalParts/KitSampleTableModalPart";
import QRSampleModalPart from "../../../SamplesList/SampleUniModal/SampleUniModalParts/QRSampleModalPart";
import SampleTabPart from "../../../SamplesListNew/SampleTabPart/SampleTabPart";
import useUpdateTab from "../../../SamplesListNew/tabs/useUpdateTab";
import KitTabPart from "../../KitTabPart/KitTabPart";

import useEditKitSamplesTab from "./useEditKitSamplesTab";

const EditKitSamplesTabBase = () => {
  const {t} = useTranslation();
  const {values} = useFormikContext();

  const {typeForm, currentSample, sampleImgs, currentKit, kitImages} = useEditKitSamplesTab({values});

  useUpdateTab({
    typeForm,
    dataToSave: {
      sampleImages: sampleImgs,
      sampleQrCodeUrl: currentKit.qrCodeUrl,
      currentSampleId: currentSample?.sampleId,
    },
  });

  return (
    <div className="h100">
      <div className="h100" style={{paddingTop: "1em"}}>
        <Form className="fcCol h100">
          <div className="fc jcfe aibl">
            <TextInput label={t("BM")} width="17em" name="brandManager" disabled />
          </div>
          <Divider />
          <div className={`w ${"block_opacity"}`}>
            <KitTabPart typeForm={typeForm} />
            <QRSampleModalPart
              isCreate={false}
              typeForm={typeForm}
              kit={currentKit}
              images={kitImages}
              addMethod={addNewKitImages}
              deleteMethod={deleteKitImage}
            />
          </div>
          <div className="border border_gap"></div>
          <div className="w">
            <SampleTabPart typeForm={typeForm} />
            <QRSampleModalPart
              typeForm={typeForm}
              isCreate={false}
              sample={currentSample}
              images={values.sampleImages?.length > 0 ? values.sampleImages : sampleImgs}
              addMethod={addNewSampleImages}
              deleteMethod={deleteSampleImage}
            />
          </div>
          <div className="border border_gap"></div>
          <KitSampleTableModalPart
            typeForm={typeForm}
            complectChecked={true}
            selectable={false}
            currentSampleId={values?.currentSampleId || currentSample?.sampleId || null}
          />
        </Form>
      </div>
    </div>
  );
};

const EditKitSamplesTab = () => {
  const {validationSchema, initialValues} = useEditKitSamplesTab({});

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
    >
      <EditKitSamplesTabBase />
    </Formik>
  );
};

export default EditKitSamplesTab;

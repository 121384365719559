import React from "react";

const TrashIcon = ({style, disabled, ...props}) => (
  <div
    style={{
      boxSizing: "content-box",
      cursor: disabled ? "not-allowed" : "pointer",
      pointerEvents: disabled ? "none" : "all",
      opacity: disabled ? "0.5" : "1",
    }}
  >
    <svg
      className="feather feather-trash-2"
      fill="none"
      stroke="#567db8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      style={{width: "1.8em", height: "1.8em", ...style}}
      {...props}
    >
      <path d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2M10 11v6M14 11v6"></path>
    </svg>
  </div>
);

export default TrashIcon;

import React from "react";

import UnlinkIcon from "../../../images/icons/unlink.png";
import ImageButton from "../../../utilities/Buttons/ImageBtn";

import AddIcon from "./icons/8201370_add_plus_button_ui_new_icon.png";
import EditIcon from "./icons/8725775_edit_icon.png";
import DeleteIcon from "./icons/8726424_trash_alt_icon.png";
import DublicateIcon from "./icons/11030095_copy_icon.png";
import ViewIcon from "./icons/openEye.png";

const ControlButtons = ({settings, className}) => {
  const {add, edit, view, dublicate, del} = settings;
  return (
    <div className={className}>
      <ImageButton
        src={AddIcon}
        tooltipMessage={add.tolltip}
        className="upper_btn_item"
        onClick={add.onClick}
        disabled={add.disabled}
        size={2}
      />
      <ImageButton
        src={EditIcon}
        tooltipMessage={edit.tolltip}
        className="upper_btn_item"
        onClick={edit.onClick}
        disabled={edit.disabled}
        size={2}
      />
      <ImageButton
        src={ViewIcon}
        tooltipMessage={view.tolltip}
        tooltipPlacement="left"
        className="upper_btn_item"
        onClick={view.onClick}
        disabled={view.disabled}
        size={2}
      />
      <ImageButton
        src={DublicateIcon}
        tooltipMessage={dublicate.tolltip}
        tooltipPlacement="bottom"
        className="upper_btn_item"
        onClick={dublicate.onClick}
        disabled={dublicate.disabled}
        size={2}
      />
      {settings?.unlink && (
        <ImageButton
          src={UnlinkIcon}
          tooltipMessage={settings?.unlink.tolltip}
          tooltipPlacement="bottom"
          className="upper_btn_item"
          onClick={settings.unlink.onClick}
          disabled={settings.unlink.disabled}
          size={2}
        />
      )}
      <ImageButton
        src={DeleteIcon}
        tooltipMessage={del.tolltip}
        tooltipPlacement="bottom"
        className="upper_btn_item"
        onClick={del.onClick}
        disabled={del.disabled}
        size={2}
      />
    </div>
  );
};

export default ControlButtons;

import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Form, Formik, useFormikContext, withFormik} from "formik";
import Swal from "sweetalert2";

import {selectPageTabIndex, selectPageTabs, setCurentTabIndex, setPageTabs} from "../../slices/UserSlice";

import {componentFactory} from "./tabsComponentFactory";

import "./tabs.scss";

const TabsContainer = ({closableTabs = true, label, children}) => {
  const openedTabIndex = useSelector(selectPageTabIndex);

  const dispatch = useDispatch();
  const tabs = useSelector(selectPageTabs);
  const {t} = useTranslation();

  const onTabClose = index => {
    const initialTabs = [...tabs];
    initialTabs.splice(index - 1, 1);
    dispatch(setPageTabs(initialTabs));
    if (openedTabIndex !== 0) {
      dispatch(setCurentTabIndex(openedTabIndex - 1));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setPageTabs([]));
      dispatch(setCurentTabIndex(0));
    };
  }, []);

  const tabsRender = useMemo(
    () =>
      tabs.map((tab, index) => {
        const tabComponent = componentFactory[tab.formType] || null;

        if (tab.controlled) {
          return (
            <ControlledTab
              key={index + 1}
              Component={tabComponent}
              tabIndex={index + 1}
              onTabClose={closableTabs ? onTabClose : null}
              name={tab.label}
              formType={tab.formType}
              askOnClose={!!tab?.askOnClose}
              {...tab.props}
            />
          );
        }
        return (
          <Tab
            key={index + 1}
            Component={tabComponent}
            tabIndex={index + 1}
            onTabClose={closableTabs ? onTabClose : null}
            name={tab.label}
            {...tab.props}
          />
        );
      }),
    [tabs],
  );

  return (
    <div className="limitedSize">
      <span
        title={label}
        style={{
          width: "220px",
          left: "-6px",
          borderLeft: "6px solid #a3b9d9",
        }}
        className={"tab " + (openedTabIndex === 0 ? " selected" : "")}
        onClick={() => {
          if (openedTabIndex !== 0) {
            dispatch(setCurentTabIndex(0));
          }
        }}
      >
        {t(label)}
      </span>
      {openedTabIndex === 0 && (
        <div className="page_container fcCol">
          <div className="overflow_form_modal hmax100 fGrow">{children}</div>
        </div>
      )}
      {tabsRender}
    </div>
  );
};

export default TabsContainer;

const Tab = ({name, Component, tabIndex, onTabClose, additionalCloseCondition, ...props}) => {
  const dispatch = useDispatch();
  const openedTabIndex = useSelector(selectPageTabIndex);

  return (
    <>
      <div
        style={{
          left: tabIndex * 200 - 6 + 20 + "px",
          borderLeft: "1px solid #a3b9d9",
        }}
        className={"tab " + (tabIndex === openedTabIndex ? " selected" : "")}
      >
        <div
          title={name}
          className="container"
          onClick={e => {
            if (tabIndex !== openedTabIndex) {
              dispatch(setCurentTabIndex(tabIndex));
            }
          }}
          style={{position: "relative", paddingRight: "1em"}}
        >
          {name}
          {!!onTabClose && tabIndex !== 0 && (
            <span
              style={{}}
              className="tab-close-btn"
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                if (props?.handleAskedClose) {
                  props
                    .handleAskedClose()
                    .then(() => {
                      onTabClose(tabIndex);
                    })
                    .catch(() => {
                      console.log("dont close :>> ");
                    });
                } else onTabClose(tabIndex);
              }}
            >
              &#215;
            </span>
          )}
        </div>
      </div>
      {tabIndex === openedTabIndex && (
        <div className="page_container fcCol">
          <div className="overflow_form_modal hmax100 fGrow">
            <Component {...props} />
          </div>
        </div>
      )}
    </>
  );
};

// if (additionalCloseCondition) {
//   additionalCloseCondition()
//     .then(() => onTabClose(tabIndex))
//     .catch(() => {
//       console.log("dont close :>> ");
//     });
//   return;
// }

const ControlledTab = props => {
  const [initialValues, setInitialFormValues] = useState(props?.formInitialValues || null);
  const openedTabIndex = useSelector(selectPageTabIndex);
  useEffect(() => {
    setInitialFormValues(prev => ({...prev, ...props?.formInitialValues}));
  }, [props?.formInitialValues]);

  return (
    <Formik initialValues={initialValues} enableReinitialize>
      <Form style={{height: props.tabIndex === openedTabIndex ? "100%" : "0px"}}>
        <Controlled {...props} setInitialFormValues={setInitialFormValues} />
      </Form>
    </Formik>
  );
};

const Controlled = props => {
  const {dirty} = useFormikContext();
  const {t} = useTranslation();

  const handleAskedClose = () => {
    return new Promise((resolve, reject) => {
      if (!dirty) {
        resolve();
        return;
      }

      Swal.fire({
        title: t("Close tab?"),
        text: t("You have unsaved issues"),
        icon: "info",
        confirmButtonText: t("Yes"),
        showCancelButton: true,
        cancelButtonText: t("Cancel"),

        customClass: {
          popup: "zindex",
          container: "zindex",
          htmlContainer: "zindex",
        },
      }).then(answ => {
        if (answ.isConfirmed) {
          resolve();
        } else {
          reject();
        }
      });
    });
  };
  return <Tab {...props} handleAskedClose={props?.askOnClose ? handleAskedClose : null} />;
};

// const ControlledTab = withFormik({
//   mapPropsToValues: props => {
//     if (props?.formInitialValues) return {...props?.formInitialValues};
//     else return {};
//   },
//   handleSubmit: (values, {setSubmitting, props}) => {
//     alert(JSON.stringify(values, null, 2));
//     props.mySubmitFunction(values);
//   },
//   enableReinitialize: true,
// })(FormikTab);

import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";

import Divider from "../../../../utilities/Divider/Divider";
import ReadOnlyFields from "../../../../utilities/ReadOnlyFields";
import ReactTable from "../../../conteiners/ReactTable";
import {TextAreaInput, TextInput} from "../../../Inputs";
import QRSampleModalPart from "../../../SamplesList/SampleUniModal/SampleUniModalParts/QRSampleModalPart";
import useUpdateTab from "../../../SamplesListNew/tabs/useUpdateTab";

import useViewSamplesTab from "./useViewSamplesTab";

const ViewKitSamplesTabBase = () => {
  const {t} = useTranslation();
  const {typeForm, kitImages, currentSample, sampleImgs, currentKit, kitSamplesTableColumns, kitSamplesTableData} =
    useViewSamplesTab();

  useUpdateTab({typeForm});

  return (
    <Form className="fcCol h100" style={{paddingTop: "0.5em", height: "100%", width: "100%", overflow: "auto"}}>
      <div className="fc jcfe aibl">
        <TextInput label={t("BM")} width="17em" name="brandManager" disabled />
      </div>
      <Divider />
      <div className="fdr mt mb">
        <ReadOnlyFields>
          <div className="flex_form part_width jcsb ">
            <TextInput label="kitSKU № *" name="kitSku" labelStyles={{width: "4em"}} />

            <TextAreaInput
              label=""
              name="setDescArea"
              width="101%"
              placeholder={t("samp nom") + "*" + ": " + t("1000")}
            />
          </div>
        </ReadOnlyFields>
        <QRSampleModalPart readOnly={true} kit={currentKit} images={kitImages} typeForm={typeForm} />
      </div>
      <Divider />
      <div className="w mb">
        <ReadOnlyFields>
          <div className="part_width">
            <div className="lower_selects">
              <TextInput label={t("Smp g")} name="groupId" generalClassName="lower_selects_item" />
              <TextInput label={t("sgr")} name="subgroupId" generalClassName="lower_selects_item" />
              <TextInput label={t("material")} name="materialId" generalClassName="lower_selects_item" />
              <TextInput label={t("categ")} name="categoryId" generalClassName="lower_selects_item" />
              <TextInput label={t("texture")} name="textureId" generalClassName="lower_selects_item" />
              <TextInput label={t("Factory")} name="factoryId" generalClassName="lower_selects_item" />
            </div>
            <div className="fdr jcsb" style={{width: "100.5%"}}>
              <TextAreaInput name="collection" placeholder={t("col") + ": "} width="50%" height="3em" />
              <TextAreaInput name="color" placeholder={t("Color") + ": "} width="50%" height="3em" />
            </div>
            <TextAreaInput name="name" placeholder={t("samp nom") + ": " + t("1000")} width="101%" height="3em" />
            <TextAreaInput name="state" placeholder={t("samp note") + ": " + t("1000")} width="101%" height="3em" />
          </div>
        </ReadOnlyFields>
        <QRSampleModalPart readOnly={true} sample={currentSample} images={sampleImgs} typeForm={typeForm} />
      </div>

      <ReactTable
        defaultData={kitSamplesTableData ?? []}
        columns={kitSamplesTableColumns}
        selectable={false}
        current={currentSample?.sampleId}
        className={`mb scrollX overflowAuto fGrow "block_opacity`}
        style={{height: "15em", maxHeight: "unset"}}
      />
    </Form>
  );
};

const ViewKitSamplesTab = () => {
  const {validationSchema, initialValues} = useViewSamplesTab();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
    >
      <ViewKitSamplesTabBase />
    </Formik>
  );
};

export default ViewKitSamplesTab;

import React from "react";
import {useField} from "formik";
import PropTypes from "prop-types";

import saveImg from "../../images/icons/save.png";

import "./utils/Inputs.scss";

const NoteInput = ({action, label, width, autolabel, labelStyles, generalClassName = "", labelWrap, ...props}) => {
  const [field] = useField(props);

  return (
    <div
      style={{marginTop: "5px", marginBottom: "10px", display: "flex", color: "#426BA6"}}
      className={`project_input autolabel${generalClassName} ${labelWrap ? "" : "autolabel"}`}
    >
      {label && (
        <label htmlFor="notes" style={autolabel ? {width: "auto"} : labelStyles}>
          {/* { typeof label === "string" ? t(label) : label }  */}
          {label}
        </label>
      )}
      <div className="project_input " style={{width: width}}>
        <input {...props} {...field} style={{width: "100%"}} autoComplete="off" value={field.value || ""} />
      </div>
      <img
        src={saveImg}
        alt="save"
        style={{height: "18px", width: "28px", marginTop: "5px", cursor: "pointer"}}
        onClick={() => action(field.value)}
        title="зберегти примітку"
        draggable="false"
      />
    </div>
  );
};
NoteInput.propTypes = {
  action: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  width: PropTypes.string,
  generalClassName: PropTypes.string,
  props: PropTypes.array,
};

export default NoteInput;

/* eslint-disable react/display-name */
import {useTranslation} from "react-i18next";

import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import SampleUniMod from "../SampleUniModal/SampleUniMod";

export const withSampleMod = (
  labelSetPart,
  labelInstancePart,
  onClickSetPart,
  onClickInstancePart,
  onCancelSetPart,
  onCancelInstancePart,
  complectChecked,
) => {
  return props => {
    const {typeForm} = props;

    const {t} = useTranslation();

    return (
      <SampleUniMod complectChecked={complectChecked} typeForm={typeForm}>
        <FunctionButton name={labelSetPart} onClick={onClickSetPart} key="setSubmit" />
        <FunctionButton name={labelInstancePart} onClick={onClickInstancePart} key="instanceSubmit" />

        <FunctionButton
          name={t("Cancel")}
          onClick={onCancelSetPart}
          style={{marginLeft: "2em", position: "initial", width: "11em"}}
          key="setCancel"
        />
        <FunctionButton
          name={t("Cancel")}
          onClick={onCancelInstancePart}
          style={{marginLeft: "2em", position: "initial", width: "11em"}}
          key="instanceCancel"
        />
      </SampleUniMod>
    );
  };
};

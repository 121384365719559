import {withFormik} from "formik";
import {t} from "i18next";

import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import Divider from "../../../utilities/Divider/Divider";
import PageContainer from "../../conteiners/PageContainer";

import TermsActionItem from "./TermsActionItem";

export const deadlinesGridTemplate = "1fr / 5fr 1fr 2fr 12fr";

const Deadlines = () => {
  const termsActionItems = [
    {id: "inventory", termName: "Inventory reminder", placeholder: "inventoryActionPlaceholder"},
    {id: "overdue", termName: "Reminder of an overdue report", placeholder: "overdueActionPlaceholder"},
    {id: "unaccepted", termName: "Reminder of unaccepted report", placeholder: "unacceptedActionPlaceholder"},
    {id: "recovery", termName: "Sample recovery reminder", placeholder: "recoveryActionPlaceholder"},
    {id: "disposal", termName: "Sample disposal reminder", placeholder: "disposalActionPlaceholder"},
    {id: "unacceptedBM", termName: "Reminder of unaccepted BM sample", placeholder: "unacceptedBMActionPlaceholder"},
  ];

  function onSaveBtnClick() {}
  function onCancelBtnClick() {}

  return (
    <PageContainer title="Terms and control settings" className="fcCol pb pt2x">
      <div className="dg w100 gap" style={{gridTemplate: deadlinesGridTemplate, textDecoration: "underline"}}>
        <div className="bold">{t("Action term name")}</div>
        <div className="">{t("number")}</div>
        <div className="">{t("term")}</div>
        <div className="">{t("event action description (1st number of the term)")}</div>
      </div>
      <Divider />
      <div className="fcCol gap">
        {termsActionItems.map(({id, termName, placeholder}) => (
          <TermsActionItem key={id} id={id} termName={termName} placeholder={placeholder} />
        ))}
      </div>
      <div className="row w100 jcfe gap mta">
        <FunctionButton name="Save" onClick={onSaveBtnClick} />
        <FunctionButton name="Cancel" onClick={onCancelBtnClick} />
      </div>
    </PageContainer>
  );
};

export default withFormik({enableReinitialize: true, mapPropsToValues: () => ({})})(Deadlines);

import {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useFormikContext, withFormik} from "formik";
import {t} from "i18next";
import {isEqual} from "lodash";

import {fetchAllTimeLimits, fetchAllUnits, selectAllTimeLimits, updateTimeLimits} from "../../../slices/SettingsSlice";
import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import Divider from "../../../utilities/Divider/Divider";
import {swalCustom} from "../../../utilities/swalCustom";
import ToolTip from "../../../utilities/ToolTip";
import PageContainer from "../../conteiners/PageContainer";

import TermsActionItem from "./TermsActionItem";

export const deadlinesGridTemplate = "1fr / 5fr 1fr 2fr 12fr";

const Deadlines = () => {
  const dispatch = useDispatch();
  const {values, setValues} = useFormikContext();

  const timeLimits = useSelector(selectAllTimeLimits);
  const initialValues = useMemo(() => formValuesFromTimeLimitsData(timeLimits), [timeLimits]);

  const termsActionItems =
    timeLimits?.map(({typeId, name, description}) => ({
      id: typeId,
      termName: name,
      placeholder: description ?? "-",
    })) ?? [];

  const [valuesChanged, setValuesChanged] = useState(false);

  useEffect(() => {
    if (!isEqual(initialValues, values)) {
      setValuesChanged(true);
    } else {
      setValuesChanged(false);
    }
  }, [values]);

  useEffect(() => {
    dispatch(fetchAllUnits());
    dispatch(fetchAllTimeLimits());
  }, []);

  useEffect(() => {
    if (timeLimits?.length > 0) {
      setValues(prev => ({...prev, ...formValuesFromTimeLimitsData(timeLimits)}));
    }
  }, [timeLimits?.length]);

  function onSaveBtnClick() {
    dispatch(updateTimeLimits(formTimeLimitsDataFromValus(values))).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        // toaster.success("Time limits updated");
        dispatch(fetchAllTimeLimits());
        setValuesChanged(false);
      }
    });
  }

  function onCancelBtnClick() {
    swalCustom.confirm({
      confirmFunc: () => {
        setValues(initialValues);
      },
    });
  }

  function formValuesFromTimeLimitsData(timeLimits) {
    return timeLimits?.reduce(
      (acc, {typeId, limit}) => ({
        ...acc,
        [`${typeId}ActionNumber`]: limit?.value ?? "",
        [`${typeId}ActionTerm`]: limit?.unitId ?? "",
      }),
      {},
    );
  }

  function formTimeLimitsDataFromValus(values) {
    return Object.keys(values).reduce((acc, key) => {
      const typeId = Number.parseInt(key);
      const index = acc.findIndex(newLimit => newLimit.typeId === typeId);
      const result = [...acc];

      if (index !== -1) {
        if (key.includes("ActionNumber")) {
          result[index] = {value: values[key], unitId: acc[index]?.limit?.unitId};
        }
        if (key.includes("ActionTerm")) {
          result[index] = {typeId, limit: {unitId: values[key], value: acc[index]?.limit?.value}};
        }
        return result;
      } else {
        if (key.includes("ActionNumber")) {
          return [...acc, {typeId, limit: {value: values[key], unitId: acc[index]?.limit?.unitId}}];
        }
        if (key.includes("ActionTerm")) {
          return [...acc, {typeId, limit: {value: acc[index]?.limit?.value, unitId: values[key]}}];
        }
      }
    }, []);
  }

  function checkIfContainesEmptyValues(values) {
    const valueArr = Object.values(values);
    for (let i = 0; i < valueArr.length; ++i) {
      if (!valueArr[i]) return true;
    }
    return false;
  }

  const hasEmptyValues = checkIfContainesEmptyValues(values);

  return (
    <PageContainer title="Terms and control settings" className="fcCol pb pt2x">
      <div className="dg w100 gap" style={{gridTemplate: deadlinesGridTemplate, textDecoration: "underline"}}>
        <div className="bold">{t("Action term name")}</div>
        <div className="">{t("number")}</div>
        <div className="">{t("term")}</div>
        <div className="">{t("event action description (1st number of the term)")}</div>
      </div>
      <Divider />
      <div className="fcCol gap">
        {termsActionItems.map(({id, termName, placeholder}) => (
          <TermsActionItem key={id} id={id} termName={termName} placeholder={placeholder} />
        ))}
      </div>
      <div className="row w100 jcfe gap mta">
        <ToolTip title={hasEmptyValues ? "Fill in all the fields" : ""}>
          <FunctionButton name="Save" onClick={onSaveBtnClick} disabled={!valuesChanged || hasEmptyValues} />
        </ToolTip>
        <FunctionButton name="Cancel" onClick={onCancelBtnClick} disabled={!valuesChanged} />
      </div>
    </PageContainer>
  );
};

export default withFormik({enableReinitialize: true, mapPropsToValues: () => ({})})(Deadlines);

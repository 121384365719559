import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import {TYPE_FORMS} from "../Components/SamplesList/SamplesList";
import {fetchData, fetchList} from "../hook/axios.hook";
import {checkEmptyFields} from "../utilities/checkEmptyFields";

const initialState = {
  sample: null,
  newSample: null,
  samplesPage: 1,
  samplesTotalPages: 0,

  allSamples: [],
  sampleImages: [],
  kit: null,
  newKit: null,
  allKits: [],
  kitImages: [],

  samplesLoading: false,
};

// export const getAllSamples = createAsyncThunk("samples/getAllSamples", async () => {
//   const responce = fetchData("/samples/getAll", "get");
//   return responce;
// });

//kit

export const getAllKits = createAsyncThunk("funcSamples/getAllKits", async params =>
  fetchList(`kits/getAll`, "get", {params: checkEmptyFields(params)}),
);

export const getKit = createAsyncThunk("funcSamples/getKit", async ({kitId}) => {
  const responce = fetchData(`/kits/get/${kitId}`, "get");
  return responce;
});

export const addNewKit = createAsyncThunk("funcSamples/addNewKit", async data => {
  const responce = fetchData(`/kits/add`, "post", data);
  return responce;
});

export const updateKit = createAsyncThunk("funcSamples/updateKits", async ({kitId, data}) => {
  const responce = fetchData(`/kits/update/${kitId}`, "patch", data);
  return responce;
});

export const dublicateKit = createAsyncThunk("funcSamples/dublicateKit", async kitId => {
  const responce = fetchData(`/kits/duplicate/${kitId}`, "post");
  return responce;
});

export const deleteKit = createAsyncThunk("funcSamples/deleteKits", async kitId => {
  const response = fetchData(`kits/remove/${kitId}`, "delete");
  return response;
});

export const addNewKitImages = createAsyncThunk("funcSamples/addNewKitImages", async ({kitId, formdata}) => {
  const response = fetchData(`/kits/images/add/${kitId}`, "post", formdata);
  return response;
});

export const deleteKitImage = createAsyncThunk("funcSamples/deleteKitImage", async ({imageId}) => {
  const response = fetchData(`kits/images/remove/${imageId}`, "delete");
  return response;
});

//sample

export const getAllSamples = createAsyncThunk("funcSamples/getAllSamples", async params =>
  fetchList(`samples/getAll`, "get", {params: checkEmptyFields(params)}),
);

export const getSample = createAsyncThunk("funcSamples/getSample", async sampleId => {
  const responce = fetchData(`/samples/get/${sampleId}`, "get");
  return responce;
});

export const addNewSample = createAsyncThunk("funcSamples/addNewSample", async data => {
  const responce = fetchData(`/samples/add`, "post", data);
  return responce;
});

export const updateSample = createAsyncThunk("funcSamples/updateSample", async ({sampleId, data}) => {
  const responce = fetchData(`/samples/update/${sampleId}`, "patch", data);
  return responce;
});

export const dublicateSample = createAsyncThunk("funcSamples/dublicateSample", async sampleId => {
  const responce = fetchData(`/samples/duplicate/${sampleId}`, "post");
  return responce;
});

export const deleteSample = createAsyncThunk("funcSamples/deleteSample", async sampleId => {
  const response = fetchData(`samples/remove/${sampleId}`, "delete");
  return response;
});

export const addNewSampleImages = createAsyncThunk("funcSamples/addNewSampleImages", async ({sampleId, formdata}) => {
  const response = fetchData(`/samples/images/add/${sampleId}`, "post", formdata);
  return response;
});

export const deleteSampleImage = createAsyncThunk("funcSamples/deleteSampleImage", async ({imageId}) => {
  const response = fetchData(`samples/images/remove/${imageId}`, "delete");
  return response;
});

export const removeSampleFromKit = createAsyncThunk("funcSamples/removeSampleFromKit", async data => {
  return fetchData(`/samples/removeFromKit`, "patch", data);
});

const funcSamplesSlice = createSlice({
  name: "funcSamples",
  initialState,
  reducers: {
    deselectSample: state => {
      state.sample = null;
    },
    clearNewKit: state => {
      state.newKit = null;
    },
    clearAllSamples: state => {
      state.allSamples = [];
    },
    clearNewSample: state => {
      state.newSample = null;
    },
    clearSamples: state => {
      state.allSamples = [];
    },
    deselectSampleImages: state => {
      state.sampleImages = [];
    },
    deselectKit: state => {
      state.kit = null;
    },
    deselectKitImages: state => {
      state.kitImages = [];
    },
    changeSamplePage: (state, action) => {
      state.samplesPage = action.payload;
    },
    addSampleToExistingKit: (state, {payload}) => {
      state.kit.samples = [...state.kit.samples, payload];
    },
    addSampleToNewKit: (state, {payload}) => {
      state.newKit.samples = [...(state.newKit.samples ?? []), payload];
    },
    setSample: (state, {payload}) => {
      state.sample = payload;
    },
  },
  extraReducers: builder => {
    builder

      //kit

      .addCase(getAllKits.fulfilled, (state, {payload}) => {
        state.allKits = payload;
      })

      .addCase(getKit.fulfilled, (state, {payload}) => {
        state.kit = payload;
        state.kitImages = payload.images;
      })

      .addCase(addNewKit.fulfilled, (state, {payload}) => {
        // state.kit = payload;
        state.newKit = payload;
        state.allKits = [payload, ...state.allKits];
        state.allSamples = [];
      })

      .addCase(updateKit.fulfilled, (state, {payload}) => {
        state.kit = payload;
        state.allKits = state.allKits.map(kit => {
          if (kit.kitId === payload.kitId) {
            return {...kit, ...payload};
          }
          return kit;
        });
      })

      .addCase(dublicateKit.fulfilled, (state, {payload}) => {
        // state.kit = payload;
        state.allKits = [payload, ...state.allKits];
        // state.kit = payload;
      })
      .addCase(deleteKit.fulfilled, (state, action) => {
        state.allKits = state.allKits.filter(req => req.kitId !== action.meta.arg);
      })
      .addCase(addNewKitImages.fulfilled, (state, {payload, meta}) => {
        if (meta.arg.typeForm === TYPE_FORMS.UPDATE_KIT) {
          state.kitImages = payload;
        }
        state.allKits = state.allKits.map(kit => {
          if (kit.kitId === meta.arg.kitId) {
            return {...kit, images: payload};
          }
          return kit;
        });
      })
      .addCase(deleteKitImage.fulfilled, (state, {payload, meta}) => {
        if (meta.arg.typeForm === TYPE_FORMS.UPDATE_KIT) {
          state.kitImages = payload;
        }
        state.allKits = state.allKits.map(kit => {
          if (kit.kitId === meta.arg.kitId) {
            return {...kit, images: payload};
          }
          return kit;
        });
      })

      //sample

      .addCase(getAllSamples.pending, (state, {payload}) => {
        state.samplesLoading = true;
      })
      .addCase(getAllSamples.fulfilled, (state, {payload}) => {
        const {currentPage, samples, totalPages} = payload;
        // state.allSamples = samples;
        state.samplesLoading = false;
        if (currentPage !== 1 && currentPage === state.samplesPage) {
          return;
        }
        state.allSamples = +currentPage === 1 ? samples : [...state.allSamples, ...samples];
        state.samplesTotalPages = totalPages;
        state.samplesPage = +currentPage;
      })
      .addCase(getAllSamples.rejected, (state, {payload}) => {
        state.samplesLoading = false;
      })
      .addCase(addNewSample.fulfilled, (state, action) => {
        state.newSample = action.payload;
        state.allSamples = [action.payload, ...state.allSamples];

        // if (action.meta.arg.kitId === null) {
        //   return;
        // }

        // state.kit =
        //   state.kit?.samples?.length > 0
        //     ? {
        //         ...state.kit,
        //         samples: [...state.kit.samples, action.payload],
        //       }
        //     : {
        //         ...state.kit,
        //         samples: [action.payload],
        //       };
      })

      .addCase(getSample.fulfilled, (state, {payload}) => {
        state.sample = payload;

        state.sampleImages = payload.images;
      })

      .addCase(updateSample.fulfilled, (state, {payload}) => {
        state.sample = payload;
        state.allSamples = state.allSamples.map(sampl => {
          if (sampl.sampleId === payload.sampleId) {
            return payload;
          }
          return sampl;
        });
      })

      .addCase(dublicateSample.fulfilled, (state, {payload}) => {
        state.sample = payload;
        state.allSamples = [payload, ...state.allSamples];
      })
      .addCase(deleteSample.fulfilled, (state, action) => {
        state.allSamples = state.allSamples.filter(req => req.sampleId !== action.meta.arg);
      })
      .addCase(addNewSampleImages.fulfilled, (state, {payload, meta}) => {
        if (meta.arg.typeForm === TYPE_FORMS.UPDATE_INSTANCE) {
          state.sampleImages = payload;
        }
        if (meta.arg.typeForm === TYPE_FORMS.EDIT_SAMPLE || meta.arg.typeForm === TYPE_FORMS.DUBLICATE_SAMPLE) {
          state.sample.images = payload;
        }
      })
      .addCase(deleteSampleImage.fulfilled, (state, {payload, meta}) => {
        if (meta.arg.typeForm === TYPE_FORMS.UPDATE_INSTANCE) {
          state.sampleImages = payload;
        }
        if (meta.arg.typeForm === TYPE_FORMS.EDIT_SAMPLE) {
          state.sample.images = payload;
        }
      })
      .addCase(removeSampleFromKit.fulfilled, (state, {payload, meta}) => {
        let filtered = state.allSamples.filter(samp => !meta.arg.sampleIds.includes(samp.sampleId));
        state.allSamples = filtered;
      })

      .addDefaultCase(() => {});
  },
});

const {actions, reducer} = funcSamplesSlice;
export const {
  deselectSample,
  deselectSampleImages,
  deselectKit,
  deselectKitImages,
  changeSamplePage,
  clearNewSample,
  addSampleToExistingKit,
  addSampleToNewKit,
  clearNewKit,
  clearSamples,
  setSample,
  clearAllSamples,
} = actions;

export default reducer;

export const selectSample = state => state.funcSamples.sample;
export const selectNewSample = state => state.funcSamples.newSample;
export const selectAllSamples = state => state.funcSamples.allSamples;
export const selectSampleImages = state => state.funcSamples.sampleImages;
export const selectSamplesLoading = state => state.funcSamples.samplesLoading;

export const selectSamplesPage = state => state.funcSamples.samplesPage;
export const selectSamplesTotalPageas = state => state.funcSamples.samplesTotalPages;

export const selectKit = state => state.funcSamples.kit;
export const selectNewKit = state => state.funcSamples.newKit;
export const selectAllKits = state => state.funcSamples.allKits;
export const selectKitImages = state => state.funcSamples.kitImages;
